import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Confirm from 'components/Board/Confirm';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';
import { edit, list, remove } from 'redux/actions/Module';
import { connect, useDispatch, useSelector } from 'react-redux';
import { countries } from 'data';

const initialValueForm = {
  country: '',
  regionId: '',
  status: '',
};
const handleSubmit = (payload, ctx) => {
  const { country, regionId, status, id, code } = payload;
  ctx.props.edit({
    name: 'countrycms',
    id: id,
    data: {
      code,
      name: country,
      status,
      regionId,
    },
    noLink: true,
    onSuccess: (res) => {
      ctx.props.handleOpen(false);
    },
  });
};
const Edit = ({ open, handleOpen, dataEdit, ...props }) => {
  const dispatch = useDispatch();
  const { region } = useSelector((state) => state.module.list);
  const [draftModal, setDraftModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const {
    values,
    touched,
    errors,
    // handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    // data,
  } = props;

  useEffect(() => {
    dispatch(
      list({
        name: 'region/getlist',
        customName: 'region',
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setFieldValue('id', dataEdit?.id);
    setFieldValue('code', dataEdit?.code);

    setFieldValue('country', dataEdit?.name);
    setFieldValue('regionId', dataEdit?.region_id);
    setFieldValue('status', dataEdit?.status);
    setFieldValue('isUsed', dataEdit?.isUsed);

    // eslint-disable-next-line
  }, [dataEdit]);
  const handleChangeStatus = (event) => {
    setFieldValue('status', event.target.value);
  };
  const removeData = () => {
    dispatch(
      remove({
        name: 'countrycms',
        id: values.id,
        noLink: true,
        onSuccess: (res) => handleOpen(res),
      })
    );
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Edit Country
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Country</InputLabel>
                <Select
                  value={values.country}
                  onChange={(event, val) => {
                    setFieldValue('country', event.target.value);
                    setFieldValue(
                      'code',
                      countries
                        .find((val) => val.label === event.target.value)
                        .code.toLowerCase()
                    );
                  }}
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                  onBlur={handleBlur}
                  name="country"
                  label="Country"
                >
                  {countries.map((val, id) => (
                    <MenuItem value={val.label}>{val.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={12} item>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Region</InputLabel>
                <Select
                  value={values.regionId}
                  onChange={(event) =>
                    setFieldValue('regionId', event.target.value)
                  }
                  error={Boolean(touched.regionId && errors.regionId)}
                  helperText={touched.regionId && errors.regionId}
                  onBlur={handleBlur}
                  name="regionId"
                  label="Region"
                >
                  {region?.data?.map((val, id) => (
                    <MenuItem value={val.id}>{val.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  onChange={handleChangeStatus}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                  onBlur={handleBlur}
                  name="status"
                  label="Status"
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setDeleteModal(true)}
          style={{
            background: '#E14040',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() =>
            values.status === 'inactive' && values.isUsed
              ? setDraftModal(true)
              : handleSubmit()
          }
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Save
        </Button>
      </DialogActions>
      <Confirm
        open={draftModal}
        handleClose={() => setDraftModal(false)}
        handleAction={(e) => {}}
        handleContent={{
          button: {
            title: 'Save',
            style: {
              background: '#1AB759',
              border: '1px solid #1AB759',
              color: '#fff',
              width: 100,
              display: 'none',
            },
          },
          title: 'Draft Country',
          message:
            'Country can not be drafted when there are companies associated under it. Please remove all companies from this country before drafting it. Note: We don’t recommend drafting country due to the sheer amount of companies that might be affected.',
        }}
      />
      <Confirm
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleAction={
          !values.isUsed
            ? (e) => {
                setDeleteModal(false);
                removeData();
              }
            : null
        }
        handleContent={{
          button: {
            title: 'Delete',
            style: {
              background: !values.isUsed ? '#E45858' : '#BFBFBF',
              color: '#fff',
              border: !values.isUsed
                ? '1px solid #E45858'
                : '1px solid #BFBFBF',
              cursor: !values.isUsed ? 'pointer' : 'default',
            },
          },
          title: 'Delete Country',
          message: !values.isUsed
            ? 'This action can not be undone. Are you sure you want to delete the country?'
            : 'Country can not be deleted when there are companies associated under it. Please remove all companies from this country before deleting it. Note: We don’t recommend deleting country due to the sheer amount of companies that might be affected.',
        }}
      />
    </Dialog>
  );
};

const EditFormik = withFormik(
  Edit,
  schemeValidations,
  initialValueForm,
  handleSubmit
);

export default connect(null, { edit })(EditFormik);
