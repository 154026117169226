import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from 'redux/actions/Module';
import TextEditor from 'components/Form/TextEditor';
import Gallery from 'components/Gallery';

const ViewSpeakers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = useParams().id;
  const { speaker, events } = useSelector((state) => state.module.view);
  useEffect(() => {
    dispatch(
      load({
        name: 'speaker',
        id: id,
      })
    );
    dispatch(
      load({
        name: 'events',
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const view = speaker?.data;
  const removeData = () => {
    dispatch(
      remove({
        name: 'speaker',
        id: id,
        noLink: true,
        onSuccess: (res) => {
          history.push(`/speakers`);
        },
      })
    );
  };
  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="View Speakers"
        breadcrumb={[
          { link: '/speakers', label: ' Speakers' },
          { link: `/speakers/${id}`, label: 'View Speakers' },
        ]}
      />

      <Board
        actions={[
          {
            label: 'Edit Speakers',

            style: {
              background: '#ED901A',
              color: '#fff',
              border: '1px solid #ED901A',
              padding: '8px 17px',
            },
            onClick: () => history.push(`/speakers/${id}/edit`),
          },
          {
            label: 'Delete Speaker',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Delete',
              style: {
                background: '#E14040',
                color: '#fff',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Speaker',
            confirmMessage:
              'This action can not be undone. Are you sure you want to delete the speaker?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => removeData(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={{ value: view?.status, title: view?.status }}
                disabled
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Speaker Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="speakerName"
                label="FullName"
                margin="normal"
                value={view?.fullName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                label="Title"
                variant="outlined"
                name="title"
                required
                fullWidth
                value={view?.title}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="profile"
                label="Profile"
                value={view?.profile || ''}
                readOnly
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Gallery
                title="Profile Image"
                images={[{ url: view?.profileImage }]}
              />
            </Grid>
          </Grid>
        </div>
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography className="title" style={{ marginBottom: 16 }}>
                Event
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="medium"
                margin="normal"
              >
                <InputLabel>Event Name</InputLabel>
                <Select
                  value={view?.event?.id || ''}
                  name="category"
                  label="Category"
                  disabled
                >
                  {events?.data?.map((value, index) => (
                    <MenuItem value={value.id}>{value.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

export default ViewSpeakers;
