import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import to_qs from 'utils/queryString';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import {
  // Chip,
  // Grid,
  Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // Typography,
} from '@material-ui/core';
import ExportModal from 'components/ExportModal';
// import AutoComplete from 'components/Form/AutoComplete';
import { getUser } from 'utils/localStorage';

// const FilterModal = ({ open, handleOpen, onFilter }) => {
//   const [form, setForm] = useState({
//     sector: '',
//     region: '',
//     exhibitor: '',
//   });
//   const [filters, setFilters] = useState([]);

//   const handleApplyFilter = () => {
//     let query = {
//       sector: [],
//       region: [],
//       exhibitor: [],
//     };
//     filters.forEach((n) => {
//       if (n.name === 'sector') {
//         query.sector.push(n.value);
//       } else if (n.name === 'region') {
//         query.region.push(n.value);
//       } else if (n.name === 'exhibitor') {
//         query.exhibitor.push(n.value);
//       }
//     });
//     onFilter(query);
//   };

//   const addFilter = (name) => {
//     let value = {
//       name,
//       label: form[name].label,
//       value: form[name].value,
//     };
//     if (form[name]) {
//       const isAny =
//         filters.filter((n) => n.name === name && n.value === form[name].value)
//           .length > 0;
//       if (!isAny) {
//         setFilters([...filters, value]);
//       }
//     }
//     setForm({
//       sector: '',
//       region: '',
//       exhibitor: '',
//     });
//   };

//   const handleDelete = (idx) => {
//     let data = filters.filter((n, index) => index !== idx);
//     setFilters(data);
//   };

//   return (
//     <Dialog open={open} fullWidth maxWidth="md">
//       <DialogContent style={{ padding: 40 }}>
//         <div>
//           <Typography
//             style={{
//               marginBottom: 12,
//               fontWeight: 500,
//               fontSize: 16,
//               color: '#282828',
//             }}
//           >
//             Current filter
//           </Typography>
//           <div>
//             {filters.map((n, i) => (
//               <Chip
//                 key={i}
//                 label={n.label}
//                 onDelete={() => handleDelete(i)}
//                 style={{ marginRight: 10 }}
//               />
//             ))}
//           </div>
//         </div>
//         <div>
//           <Typography
//             style={{
//               marginBottom: 20,
//               marginTop: 85,
//               fontWeight: 500,
//               fontSize: 16,
//               color: '#282828',
//             }}
//           >
//             Filter Data
//           </Typography>
//           <Grid container spacing={3} alignItems="center">
//             <Grid item sm={6}>
//               <AutoComplete
//                 shrink
//                 label="Business Sector"
//                 name="sector"
//                 onChange={(val) =>
//                   setForm({
//                     ...form,
//                     sector: {
//                       label: val.label,
//                       value: val.value,
//                     },
//                   })
//                 }
//                 variant="outlined"
//                 size="medium"
//                 value={form.sector}
//                 url="/sector/getlist"
//                 urlName="keyword"
//                 addQuery={{ category: 'product' }}
//                 dataStructure={(n) => ({
//                   ...n,
//                   label: n.name,
//                   value: n.name,
//                 })}
//               />
//             </Grid>
//             <Grid item sm={6}>
//               <Button
//                 onClick={() => addFilter('sector')}
//                 style={{
//                   background: '#ED901A',
//                   borderRadius: 8,
//                   fontSize: 14,
//                   color: '#ffffff',
//                   textTransform: 'capitalize',
//                   padding: '11px 26px',
//                 }}
//               >
//                 Add
//               </Button>
//             </Grid>
//           </Grid>
//           <Grid container spacing={3} alignItems="center">
//             <Grid item sm={6}>
//               <AutoComplete
//                 shrink
//                 label="Regions"
//                 name="region"
//                 onChange={(val) =>
//                   setForm({
//                     ...form,
//                     region: {
//                       label: val.label,
//                       value: val.value,
//                     },
//                   })
//                 }
//                 variant="outlined"
//                 size="medium"
//                 value={form.region}
//                 url="/region/getlist"
//                 urlName="keyword"
//                 dataStructure={(n) => ({
//                   ...n,
//                   label: n.title,
//                   value: n.title,
//                 })}
//               />
//             </Grid>
//             <Grid item sm={6}>
//               <Button
//                 onClick={() => addFilter('region')}
//                 style={{
//                   background: '#ED901A',
//                   borderRadius: 8,
//                   fontSize: 14,
//                   color: '#ffffff',
//                   textTransform: 'capitalize',
//                   padding: '11px 26px',
//                 }}
//               >
//                 Add
//               </Button>
//             </Grid>
//           </Grid>
//           <Grid container spacing={3} alignItems="center">
//             <Grid item sm={6}>
//               <AutoComplete
//                 shrink
//                 label="Exhibitor"
//                 name="exhibitor"
//                 onChange={(val) =>
//                   setForm({
//                     ...form,
//                     exhibitor: {
//                       label: val.label,
//                       value: val.value,
//                     },
//                   })
//                 }
//                 variant="outlined"
//                 size="medium"
//                 value={form.exhibitor}
//                 url="/exhibitor/getlist"
//                 urlName="keyword"
//                 dataStructure={(n) => ({
//                   ...n,
//                   label: n.companyName,
//                   value: n.id,
//                 })}
//               />
//             </Grid>
//             <Grid item sm={6}>
//               <Button
//                 onClick={() => addFilter('exhibitor')}
//                 style={{
//                   background: '#ED901A',
//                   borderRadius: 8,
//                   fontSize: 14,
//                   color: '#ffffff',
//                   textTransform: 'capitalize',
//                   padding: '11px 26px',
//                 }}
//               >
//                 Add
//               </Button>
//             </Grid>
//           </Grid>
//         </div>
//       </DialogContent>
//       <DialogActions style={{ padding: 40 }}>
//         <Button
//           onClick={() => handleOpen(false)}
//           style={{
//             border: '1px solid #E45858',
//             borderRadius: 8,
//             fontSize: 14,
//             color: '#E45858',
//             textTransform: 'capitalize',
//             padding: '11px 26px',
//           }}
//         >
//           Cancel
//         </Button>
//         <Button
//           onClick={() => {
//             handleApplyFilter();
//             handleOpen(false);
//           }}
//           style={{
//             background: '#ED901A',
//             borderRadius: 8,
//             fontSize: 14,
//             color: '#ffffff',
//             textTransform: 'capitalize',
//             padding: '11px 26px',
//           }}
//         >
//           Apply Filter
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

const Index = () => {
  const history = useHistory();

  // const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [keyword, setKeyword] = useState('')
  // const [query, setQuery] = useState({
  //   sectors: [],
  //   regions: [],
  // });

  let exhibitorId = getUser()?.user?.exhibitor?.slug;

  return (
    <div className="app-wrapper product-page">
      <ContainerHeader
        title="My Products"
        breadcrumb={[{ link: '/products', label: 'All Products' }]}
      />

      <div className="action-btn">
        <Button
          style={{
            border: '1px solid #ED901A',
            borderRadius: 8,
            color: '#ED901A',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => setIsOpenExport(true)}
        >
          Export
        </Button>
        <Button
          style={{
            marginLeft: 20,
            border: '1px solid #ED901A',
            backgroundColor: '#ED901A',
            borderRadius: 8,
            color: '#ffffff',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => history.push('/my-company/add-product')}
        >
          Add Product
        </Button>
      </div>

      <Tables
        name={`exhibitor/${exhibitorId}/products`}
        // addQueryString={to_qs(query)}
        keyword={keyword}
        setKeyword={setKeyword}
        // withFilterModal={(val) => setIsOpenFilter(val)}
        withSearch={{ placeholder: 'Search for a product...' }}
        columnHead={[
          { id: 'id', label: 'No', disableSort: true },
          { id: 'name', label: 'Product Name' },
          { id: 'category', label: 'Category' },
          { id: 'exhibitor', label: 'Exhibitor' },
          { id: 'country', label: 'Country' },
          { id: 'status', label: 'Status' },
          { id: '', label: 'Action', disableSort: true },
        ]}
        columnBody={[
          { parent: 'id' },
          { parent: 'name' },
          { parent: (n) => n?.sector?.name },
          { parent: (n) => n?.exhibitor?.user?.companyName },
          { parent: (n) => n?.exhibitor?.companyCountry },

          { parent: 'status' },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/products/${n.slug}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
              >
                View
              </Button>
            ),
          },
        ]}
      />

      {/* <FilterModal
        open={isOpenFilter}
        handleOpen={(val) => setIsOpenFilter(val)}
        onFilter={(val) => setQuery({ ...val })}
        filter={query}
      /> */}

      {isOpenExport && (
        <ExportModal
          name="products"
          // filter={query}
          keyword={keyword}
          open={isOpenExport}
          handleOpen={(val) => setIsOpenExport(val)}
        />
      )}
    </div>
  );
};

export default Index;
