import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';

const Header = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  columnHead,
  onRequestSort,
  isBulkDelete,
}) => (
  <TableHead className="table-head-custom">
    <TableRow>
      {isBulkDelete && (
        <TableCell padding="none" className="cellCheckbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected >= rowCount}
            onChange={onSelectAllClick}
            className="checkBoxIcon"
          />
        </TableCell>
      )}

      {columnHead.map((column) => (
        <TableCell
          key={column.id}
          numeric={column.numeric}
          padding={column.disablePadding ? 'none' : 'default'}
          className="tableCellHead"
          sortDirection={orderBy === column.id ? order : false}
        >
          {column.disableSort ? (
            column.label
          ) : (
            <Tooltip
              title="Sort"
              placement={column.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order.toLowerCase()}
                onClick={(event) => onRequestSort(event, column.id)}
                className="cellHead"
              >
                {column.label}
              </TableSortLabel>
            </Tooltip>
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default Header;
