import React, { useState } from "react";
import to_qs from "utils/queryString";
import ContainerHeader from "components/BreadCrumbTitle";
import Tables from "components/Tables";
import {
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Chip,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import AutoComplete from "components/Form/AutoComplete";
import ExportModal from "components/ExportModal";

const FilterModal = ({ open, handleOpen, onFilter }) => {
	const [form, setForm] = useState({
		sector: "",
		region: "",
		country: "",
	});
	const [filters, setFilters] = useState([]);

	const handleApplyFilter = () => {
		let query = {
			sector: [],
			region: [],
			country: [],
		};
		filters.forEach((n) => {
			if (n.name === "sector") {
				query.sector.push(n.value);
			} else if (n.name === "region") {
				query.region.push(n.value);
			} else if (n.name === "country") {
				query.country.push(n.value);
			}
		});
		onFilter(query);
	};

	const addFilter = (name) => {
		let value = {
			name,
			label: form[name].label,
			value: form[name].value,
		};
		if (form[name]) {
			const isAny =
				filters.filter((n) => n.name === name && n.value === form[name].value)
					.length > 0;
			if (!isAny) {
				setFilters([...filters, value]);
			}
		}
		setForm({
			sector: "",
			region: "",
			country: "",
		});
	};

	const handleDelete = (idx) => {
		let data = filters.filter((n, index) => index !== idx);
		setFilters(data);
	};

	return (
		<Dialog open={open} fullWidth maxWidth="md">
			<DialogContent style={{ padding: 40 }}>
				<div>
					<Typography
						style={{
							marginBottom: 12,
							fontWeight: 500,
							fontSize: 16,
							color: "#282828",
						}}
					>
						Current filter
					</Typography>
					<div>
						{filters.map((n, i) => (
							<Chip
								key={i}
								label={n.label}
								onDelete={() => handleDelete(i)}
								style={{ marginRight: 10 }}
							/>
						))}
					</div>
				</div>
				<div>
					<Typography
						style={{
							marginBottom: 20,
							marginTop: 85,
							fontWeight: 500,
							fontSize: 16,
							color: "#282828",
						}}
					>
						Filter Data
					</Typography>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Business Sector"
								name="sector"
								onChange={(val) =>
									setForm({
										...form,
										sector: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.sector}
								url="/sector/getlist"
								urlName="keyword"
								addQuery={{ category: "company" }}
								dataStructure={(n) => ({
									...n,
									label: n.name,
									value: n.name,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("sector")}
								style={{
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Regions"
								name="region"
								onChange={(val) =>
									setForm({
										...form,
										region: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.region}
								url="/region/getlist"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.title,
									value: n.title,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("region")}
								style={{
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Country"
								name="country"
								onChange={(val) =>
									setForm({
										...form,
										country: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.country}
								url="/country"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.name,
									value: n.code,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("country")}
								style={{
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions style={{ padding: 40 }}>
				<Button
					onClick={() => handleOpen(false)}
					style={{
						border: "1px solid #E45858",
						borderRadius: 8,
						fontSize: 14,
						color: "#E45858",
						textTransform: "capitalize",
						padding: "11px 26px",
					}}
				>
					Cancel
				</Button>
				<Button
					onClick={() => {
						handleApplyFilter();
						handleOpen(false);
					}}
					style={{
						background: "#ED901A",
						borderRadius: 8,
						fontSize: 14,
						color: "#ffffff",
						textTransform: "capitalize",
						padding: "11px 26px",
					}}
				>
					Apply Filter
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const Index = () => {
	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const [isOpenExport, setIsOpenExport] = useState(false);

	const history = useHistory();
	const [keyword, setKeyword] = useState("");
	const [query, setQuery] = useState({
		sectors: [],
		regions: [],
		country: [],
	});
	return (
		<div className="app-wrapper exhibitor-page">
			<ContainerHeader
				title="Visitors"
				breadcrumb={[{ link: "/visitors", label: "Visitors" }]}
			/>
			<div className="action-btn">
				<Button
					style={{
						border: "1px solid #ED901A",
						borderRadius: 8,
						color: "#ED901A",
						fontSize: 14,
						padding: "8px 24px",
						textTransform: "capitalize",
					}}
					onClick={() => setIsOpenExport(true)}
				>
					Export
				</Button>
			</div>
			<Tables
				title="Visitors"
				name="visitor"
				keyword={keyword}
				setKeyword={setKeyword}
				addQueryString={to_qs(query)}
				withFilterModal={(val) => setIsOpenFilter(val)}
				withSearch={{ placeholder: "Search for a visitor..." }}
				columnHead={[
					{ id: "id", disableSort: false, label: "No" },
					{ id: "name", label: "Full Name" },
					{ id: "company_name", label: "Company Name" },
					{ id: "country", label: "Country" },
					{ id: "created_at", label: "Join At" },
					{ id: "action", label: "Action" },
				]}
				columnBody={[
					{ parent: "number" },
					{ parent: "name" },
					{ parent: "companyName" },
					{ parent: (n) => (n.country ? n.country : "") },
					{
						parent: (n) =>
							`${n.createdAt && moment(n.createdAt).format("DD MMM YYYY")}`,
					},
					{
						parent: (n) => (
							<Button
								onClick={() => history.push(`/visitors/${n.id}`)}
								style={{
									background: "#3C91D6",
									borderRadius: 6,
									color: "#fff",
									textTransform: "capitalize",
									fontSize: 12,
								}}
							>
								View
							</Button>
						),
					},
				]}
			/>
			{isOpenExport && (
				<ExportModal
					name="visitor"
					customName="visitors"
					open={isOpenExport}
					filter={query}
					handleOpen={(val) => setIsOpenExport(val)}
				/>
			)}
			<FilterModal
				open={isOpenFilter}
				handleOpen={(val) => setIsOpenFilter(val)}
				onFilter={(val) => setQuery({ ...val })}
				filter={query}
			/>
		</div>
	);
};

export default Index;
