import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { asMutable } from 'seamless-immutable';
import moment from 'moment';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  countries,
  applying,
  sector,
  province as provinceStatic,
  district as districtStatic,
  status,
} from 'data';
import FormikPhoneInput from 'components/FormikPhoneInput';
import UploadDocument from 'components/Upload/UploadDocument';

import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { load, edit, list as listRedux } from 'redux/actions';
import { useSelector, useDispatch, connect } from 'react-redux';
import { editSchemeValidations } from './schemeValidation';
import UploadOthersDocument from 'components/Upload/UploadOthersDocument';
import withFormik from 'utils/withFormik';
import { getUser } from 'utils/localStorage';

const initialValues = {
  companyName: '',
  country: '',
  countryValues: '',
  businessSector: [],
  companyPhoneNumber: '',
  companyEmail: '',
  companyWebsite: '',
  companyAddress: '',
  province: '',
  district: '',
  postalCode: '',
  picName: '',
  picPhone: '',
  picEmail: '',
  picPosition: '',
  applyingAs: '',
  nib: '',
  siup: '',
  npwp: '',
  npwpFile: '',
  companyCountry: '',
  companyCountryValues: '',
  provinceValues: '',
  districtValues: '',
  izinEkspor: '',
  others: [],
  statusExhibitor: '',
  created: '',
  modifed: '',
};

const mapPropsToValue = ({ data }) => {
  const { exhibitor } = data;

  if (exhibitor) {
    let mutableData = asMutable(exhibitor, { deep: true });
    return {
      companyName: exhibitor.data.companyName,
      country: exhibitor.data.country,
      countryValues: {
        code: exhibitor.data.country,
        createdAt: exhibitor.data.country,
        deletedAt: exhibitor.data.country,
        flag: exhibitor.data.country,
        id: exhibitor.data.country,
        modifiedAt: exhibitor.data.country,
        name: exhibitor.data.country,
        uuid: exhibitor.data.country,
      },
      businessSector:
        mutableData &&
        mutableData.data.sector.map((val) => ({
          id: val,
          name: val,
        })),
      businessSectorValues:
        mutableData &&
        mutableData.data.sector.map((val) => ({
          id: val,
          name: val,
        })),
      created: exhibitor.data.createdAt,
      modifed: exhibitor.data.modifiedAt,
      companyPhoneNumber: exhibitor.data.companyPhoneNumber,
      companyEmail: exhibitor.data?.user?.companyEmail,
      companyWebsite: exhibitor.data.companyWebsite,
      companyAddress: exhibitor.data.companyAddress,
      province: exhibitor.data.companyAddressProvince,
      provinceValues: {
        id: exhibitor.data.companyAddressProvince,
        name: exhibitor.data.companyAddressProvince,
      },
      district: exhibitor.data.companyAddressDistrict,
      districtValues: {
        id: exhibitor.data.companyAddressDistrict,
        name: exhibitor.data.companyAddressDistrict,
      },
      statusExhibitor: exhibitor.data.status,
      postalCode: exhibitor.data.companyAddressPostalCode,
      picName: exhibitor.data.user.name,
      picPhone: exhibitor.data.user.phoneNumber,
      picEmail: exhibitor.data.email,
      picPosition: exhibitor.data.user.position,
      applyingAs: exhibitor.data.as,
      nib: exhibitor.data.nib || '',
      siup: exhibitor.data.siup || '',
      npwp: exhibitor.data.noNpwp || '',
      npwpFile: exhibitor.data.npwp || '',
      izinEkspor: exhibitor.data.suratIzinEkspor || '',
      companyCountry:
        exhibitor.data.companyCountry !== null
          ? exhibitor.data.companyCountry
          : '',
      companyCountryValues: {
        code: exhibitor.data.companyCountry,
        createdAt: exhibitor.data.companyCountry,
        deletedAt: exhibitor.data.companyCountry,
        flag: exhibitor.data.companyCountry,
        id: exhibitor.data.companyCountry,
        modifiedAt: exhibitor.data.companyCountry,
        name: exhibitor.data.companyCountry,
        uuid: exhibitor.data.companyCountry,
      },
      others: mutableData.data.others ? mutableData.data.others : [],
    };
  }
  return initialValues;
};

const handleSubmit = (payload, ctx) => {
  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : ctx.props.match.params.exhibitorId;

  const {
    companyName,
    country,
    businessSector,
    companyPhoneNumber,
    companyWebsite,
    companyEmail,
    companyAddress,
    province,
    district,
    postalCode,
    picName,
    picPhone,
    picEmail,
    picPosition,
    nib,
    npwp,
    siup,
    applyingAs,
    npwpFile,
    companyCountry,
    izinEkspor,
    others,
    statusExhibitor,
  } = payload;
  let business = [];

  businessSector.map((value) => {
    business.push(value.name);
    return value;
  });
  let newOthers = [];
  others.map((value) => {
    newOthers.push(value.file);
    return value;
  });

  ctx.props.edit({
    name: `exhibitor/${exhibitorId}`,
    data: {
      status: statusExhibitor,
      companyName,
      country,
      companyCountry,
      businessSector: business,
      companyPhoneNumber,
      companyWebsite,
      companyEmail,
      companyAddress,
      companyAddressProvince: province,
      companyAddressDistrict: district,
      companyAddressPostalCode: postalCode,
      name: picName,
      phoneNumber: picPhone,
      email: picEmail,
      picPosition,
      nib,
      npwp: npwpFile.toString(),
      noNpwp: npwp.toString(),
      siup: siup.toString(),
      suratIzinUsaha: nib,
      type: 'exhibitor',
      applyAs: applyingAs,
      suratIzinEkspor: izinEkspor,
      others: newOthers,
    },
    linkSuccess: `exhibitors/${exhibitorId}`,
  });
};

const CompanyDetailEdit = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    setFieldError,
  } = props;
  const history = useHistory();
  const { province, district, businessSectorList, countriesApi } = useSelector(
    (state) => state.module.list
  );
  const [mutableCountries, setMutableCountries] = useState([]);

  const slug = useParams().exhibitorId;

  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : slug;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      listRedux({
        name: 'country',
        customName: 'countriesApi',
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (countriesApi) {
      let countriesApiMutable = asMutable(countriesApi, { deep: true });

      setMutableCountries(countriesApiMutable);
    }

    // eslint-disable-next-line
  }, [countriesApi]);
  useEffect(() => {
    setFieldValue('slug', exhibitorId);
    dispatch(load({ name: 'exhibitor', id: exhibitorId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      listRedux({
        name: 'province',
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      listRedux({
        name: 'sector/getlist',
        customName: 'businessSectorList',
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      listRedux({
        name: `city/${values.provinceValues.id}`,
        customName: 'district',
      })
    );
    // eslint-disable-next-line
  }, [values.provinceValues]);

  useEffect(() => {
    const findProvince =
      province &&
      province.data.find((val) => val.name === values.provinceValues.id);
    dispatch(
      listRedux({
        name: `city/${findProvince && findProvince.id}`,
        customName: 'district',
      })
    );
    // eslint-disable-next-line
  }, [province]);

  const handlePhoneChange = (value, country) => {
    setFieldValue('companyPhoneNumber', value);
  };

  const handlePicPhoneChange = (value, country) => {
    setFieldValue('picPhone', value);
  };

  const onBusinessSector = (event, value) => {
    if (value?.length < values?.businessSector?.length) {
      setFieldValue('businessSector', value ? value : []);
    } else if (
      values?.businessSector?.some(
        (n) => n.name === value[value.length - 1]?.name
      )
    ) {
      // console.log(values?.businessSector?.some((n) => n.name === value.label));
    } else {
      setFieldValue('businessSector', value ? value : []);
    }
  };

  const onProvinceChange = (event, values) => {
    setFieldValue('provinceValues', values && values !== null ? values : {});
    setFieldValue(
      'province',
      values && values.name !== null ? values.name : ''
    );
  };

  const onDistrictChange = (event, values) => {
    setFieldValue('districtValues', values && values !== null ? values : {});

    setFieldValue(
      'district',
      values && values.name !== null ? values.name : ''
    );
  };

  const handleBlurBusinessSector = (event, values) => {
    // setLoading(true)
    dispatch(
      listRedux({
        name: 'sector/getlist',
        query: `keyword=${values}`,
        customName: 'businessSectorList',
      })
    );
  };

  const statusOnChange = (event) => {
    setFieldValue('statusExhibitor', event.target.value);
    dispatch(
      edit({
        name: `exhibitor/${exhibitorId}`,
        data: {
          status: event.target.value,
        },
        noLink: true,
        onSuccess: () => console.log('sukses'),
      })
    );
  };

  let businessSectorOptions =
    businessSectorList &&
    businessSectorList.data.map((val) => ({ id: val.name, name: val.name }));
  let businessSectorOptionsUpdate = [];

  useEffect(() => {
    if (businessSectorList?.data) {
      // eslint-disable-next-line
      businessSectorOptionsUpdate =
        // eslint-disable-next-line
        businessSectorList &&
        businessSectorList.data.map((val) => ({
          id: val.name,
          name: val.name,
        }));
    }
  }, [businessSectorList]);

  const breadcrumbAdmin = [
    { link: '/exhibitors', label: 'All Exhibitors' },
    { link: `/exhibitors/${exhibitorId}`, label: 'View Exhibitor' },
    { link: '', label: 'Edit Exhibitor' },
  ];
  const breadcrumbExhibitor = [
    { link: '/my-company', label: 'My Company' },
    { link: '', label: 'Edit My Company' },
  ];
  const breadcrumb = getUser()?.user?.roles.includes('administrator')
    ? breadcrumbAdmin
    : getUser()?.user?.roles.includes('exhibitor')
    ? breadcrumbExhibitor
    : [];

  const title = getUser()?.user?.roles.includes('administrator')
    ? 'Edit Exhibitor'
    : 'Edit My Company';
  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader title={title} breadcrumb={breadcrumb} />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save Change',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Save Changes',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div>
          <p style={{ marginTop: 40 }}>{`Updated at ${moment(
            values?.modifed
          ).format('D MMMM YYYY')}`}</p>
          <p>{`Submited at ${moment(values?.created).format(
            'D MMMM YYYY'
          )}`}</p>
        </div>

        {!getUser()?.user?.roles.includes('exhibitor') && (
          <div className="section">
            <Grid container spacing={3}>
              <Grid sm={4} item>
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="medium"
                  margin="normal"
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={values.statusExhibitor}
                    name="country"
                    label="country"
                    onChange={statusOnChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.statusExhibitor && errors.statusExhibitor
                    )}
                    helperText={
                      touched.statusExhibitor && errors.statusExhibitor
                    }
                  >
                    {status.map((value, index) => (
                      <MenuItem value={value.value}>{value.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}

        <div className="section">
          <Typography className="title">Company Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyName"
                label="Company/Institution Name"
                margin="normal"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.companyName && errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                value={values.companyName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <Autocomplete
                style={{ marginTop: 15 }}
                options={
                  mutableCountries?.data
                    ? mutableCountries?.data?.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    : countries
                }
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={(e, val) => {
                  setFieldValue('country', val.name);
                  setFieldValue('countryValues', val);
                }}
                name="country"
                value={values.countryValues}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="PIC Country"
                    name="country"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    value={values.country}
                    onBlur={handleBlur}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                multiple
                name="businessSector"
                options={
                  businessSectorOptionsUpdate.length > 0
                    ? businessSectorOptionsUpdate
                    : businessSectorList
                    ? businessSectorOptions && businessSectorOptions
                    : sector
                }
                defaultValue={values.businessSector}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                style={{ marginTop: 15 }}
                onChange={onBusinessSector}
                value={values.businessSector}
                onBlur={handleBlur}
                onInputChange={handleBlurBusinessSector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Business Sector"
                    // name="businessSector"
                    // error={Boolean(
                    //   touched.businessSector && errors.businessSector
                    // )}
                    // helperText={touched.businessSector && errors.businessSector}
                    // value={values.businessSector}
                    // onChange={handleChange}
                    // // onBlur={handleBlurBusinessSector}
                    placeholder={
                      values.businessSector.length > 0
                        ? ''
                        : 'Please select up to 3 business sectors'
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormikPhoneInput
                name="companyPhoneNumber"
                inputProps={{ name: 'companyPhoneNumber' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                errorText="Company phone numbers can not be left blank."
                error={Boolean(
                  touched.companyPhoneNumber && errors.companyPhoneNumber
                )}
                helperText={
                  touched.companyPhoneNumber && errors.companyPhoneNumber
                }
                value={values.companyPhoneNumber}
                onChange={handlePhoneChange}
                onBlur={handleBlur}
                countryCodeEditable={false}
                specialLabel="Company Phone Number"
                // country={
                //   // test.toLowerCase()
                //   values.companyCountryValues &&
                //   values.companyCountryValues.code.toLowerCase()
                // }
                // onlyCountries={
                //   // [test.toLowerCase()]
                //   values.companyCountryValues && [
                //     values.companyCountryValues.code.toLowerCase(),
                //   ]
                // }
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyEmail"
                label="Company Email"
                margin="normal"
                value={values.companyEmail}
                error={Boolean(touched.companyEmail && errors.companyEmail)}
                helperText={touched.companyEmail && errors.companyEmail}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyWebsite"
                label="Company Website"
                margin="normal"
                value={values.companyWebsite}
                error={Boolean(touched.companyWebsite && errors.companyWebsite)}
                helperText={touched.companyWebsite && errors.companyWebsite}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyAddress"
                label="Company Address"
                margin="normal"
                value={values.companyAddress}
                error={Boolean(touched.companyAddress && errors.companyAddress)}
                helperText={touched.companyAddress && errors.companyAddress}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              {values.country === 'Indonesia' ||
              values.country === 'ID' ||
              values.country === '' ? (
                <Autocomplete
                  style={{ marginTop: 15 }}
                  options={province ? province.data : provinceStatic}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={onProvinceChange}
                  value={values.provinceValues}
                  name="province"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Province"
                      name="province"
                      error={Boolean(touched.province && errors.province)}
                      helperText={touched.province && errors.province}
                      value={values.province}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="Province/State"
                  name="province"
                  style={{ marginTop: 15 }}
                  fullWidth
                  error={Boolean(touched.province && errors.province)}
                  helperText={touched.province && errors.province}
                  value={values.province}
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              {values.country === 'Indonesia' ||
              values.country === 'ID' ||
              values.country === '' ? (
                <Autocomplete
                  style={{ marginTop: 15 }}
                  disabled={values.province === ''}
                  options={district ? district.data : districtStatic}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={onDistrictChange}
                  name="district"
                  value={values.districtValues}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="District"
                      name="district"
                      error={Boolean(touched.district && errors.district)}
                      helperText={touched.district && errors.district}
                      value={values.district}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="District/City/Suburb"
                  name="district"
                  style={{ marginTop: 15 }}
                  error={Boolean(touched.district && errors.district)}
                  helperText={touched.district && errors.district}
                  value={values.district}
                  onBlur={handleBlur}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
            <Grid sm={6} item>
              <TextField
                label="Postal Code"
                margin="normal"
                type="number"
                name="postalCode"
                error={Boolean(touched.postalCode && errors.postalCode)}
                helperText={touched.postalCode && errors.postalCode}
                value={values.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                style={{ marginTop: 15 }}
                options={
                  mutableCountries?.data
                    ? mutableCountries?.data?.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    : countries
                }
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={(e, val) => {
                  setFieldValue('companyCountry', val.name);
                  setFieldValue('companyCountryValues', val);
                }}
                name="companyCountry"
                value={values.companyCountryValues}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company Country"
                    name="companyCountry"
                    error={Boolean(
                      touched.companyCountry && errors.companyCountry
                    )}
                    helperText={touched.companyCountry && errors.companyCountry}
                    value={values.companyCountry}
                    onBlur={handleBlur}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">PIC Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                label="PIC Name"
                margin="normal"
                type="text"
                fullWidth
                name="picName"
                error={Boolean(touched.picName && errors.picName)}
                helperText={touched.picName && errors.picName}
                value={values.picName}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid sm={6} item>
              <FormikPhoneInput
                name="picPhone"
                errorText="PIC phone numbers can not be left blank."
                inputProps={{ name: 'picPhone' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                error={Boolean(touched.picPhone && errors.picPhone)}
                helperText={touched.picPhone && errors.picPhone}
                value={values.picPhone}
                onChange={handlePicPhoneChange}
                countryCodeEditable={false}
                onBlur={handleBlur}
                // country={"ID"}
                // onlyCountries={["id"]}
                // country={
                //   values.companyCountryValues &&
                //   values.companyCountryValues.code.toLowerCase()
                // }
                // onlyCountries={
                //   values.companyCountryValues && [
                //     values.companyCountryValues.code.toLowerCase(),
                //   ]
                // }
                specialLabel="PIC Phone Number"
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                label="PIC Email"
                type="email"
                margin="normal"
                fullWidth
                name="picEmail"
                disabled
                error={Boolean(touched.picEmail && errors.picEmail)}
                helperText={touched.picEmail && errors.picEmail}
                value={values.picEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                label="PIC Position"
                type="text"
                margin="normal"
                name="picPosition"
                error={Boolean(touched.picPosition && errors.picPosition)}
                helperText={touched.picPosition && errors.picPosition}
                value={values.picPosition}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item></Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Documents</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormControl
                variant="outlined"
                fullWidth
                size="medium"
                margin="normal"
              >
                <InputLabel>Appling As</InputLabel>
                <Select
                  value={values.applyingAs}
                  name="applyingAs"
                  label="applyingAs"
                  onChange={(event) =>
                    setFieldValue('applyingAs', event.target.value)
                  }
                  onBlur={handleBlur}
                  error={Boolean(touched.applyingAs && errors.applyingAs)}
                  helperText={touched.applyingAs && errors.applyingAs}
                >
                  {applying.map((value, index) => (
                    <MenuItem value={value.data}>{value.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {['Indonesia', 'ID', ''].includes(values.country) ? (
              <Grid sm={6} item>
                <TextField
                  label="NPWP"
                  name="npwp"
                  margin="normal"
                  fullWidth
                  error={Boolean(touched.npwp && errors.npwp)}
                  helperText={touched.npwp && errors.npwp}
                  value={values.npwp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            ) : null}
          </Grid>
          {['Indonesia', 'ID', ''].includes(values.country) ? (
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid sm={6} item>
                  <UploadDocument
                    label="NPWP  Documents"
                    name="npwpFile"
                    value={values?.npwpFile}
                    onImageChange={setFieldValue}
                    error={errors.npwpFiles}
                    // multiple
                    max={1}
                    type="npwp"
                  />
                </Grid>
                <Grid sm={6} item>
                  <UploadDocument
                    name="nib"
                    value={values?.nib}
                    onImageChange={setFieldValue}
                    error={errors?.nib}
                    label="NIB (Nomor Induk Berusaha)"
                    type="nib"
                    setFieldError={setFieldError}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 50 }}>
                <Grid sm={6} item>
                  <UploadDocument
                    label="SIUP"
                    name="siup"
                    value={values?.siup}
                    onImageChange={setFieldValue}
                    error={errors.siup}
                    type="siup"
                  />
                </Grid>
                <Grid sm={6} item>
                  <UploadDocument
                    name="izinEkspor"
                    value={values.izinEkspor}
                    onImageChange={setFieldValue}
                    error={errors.izinEkspor}
                    label="Surat Izin Ekspor"
                    type="suratIzinEkspor"
                    setFieldError={setFieldError}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                style={{ marginTop: 50 }}
              >
                <Grid sm={6} item>
                  <UploadOthersDocument
                    name="others"
                    value={
                      values.others && values.others[0] === null
                        ? []
                        : values.others
                    }
                    onImageChange={setFieldValue}
                    error={errors.others}
                    multiple
                    isEdit
                    label="Other Documents (Optional)"
                    //  max={1}
                    type="others"
                  />
                </Grid>
                <Grid sm={6} item></Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  <UploadDocument
                    label="Tax  Documents"
                    name="npwpFile"
                    value={values?.npwpFile}
                    onImageChange={setFieldValue}
                    error={errors.npwpFiles}
                    max={1}
                    type="npwp"
                    typeFile="image/*"
                  />
                </Grid>
                <Grid sm={6} item>
                  <UploadDocument
                    name="nib"
                    value={values.nib}
                    onImageChange={setFieldValue}
                    error={touched.nib && errors.nib}
                    label="Proof of Business Ownership"
                    type="proof_business_ownership"
                    typeFile="application/pdf"
                    setFieldError={setFieldError}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                style={{ marginTop: 50 }}
              >
                <Grid sm={6} item>
                  <UploadOthersDocument
                    name="others"
                    value={
                      values.others && values.others[0] === null
                        ? []
                        : values.others
                    }
                    onImageChange={setFieldValue}
                    error={errors.others}
                    multiple
                    isEdit
                    typeFile="application/pdf"
                    label="Other Documents (Optional)"
                    type="others"
                  />
                </Grid>
                <Grid sm={6} item></Grid>
              </Grid>
            </div>
          )}
        </div>
      </Board>
    </div>
  );
};

const FormikEdit = withFormik(
  CompanyDetailEdit,
  editSchemeValidations,
  mapPropsToValue,
  handleSubmit
);

const mapStateToProps = (state) => ({
  data: state.module.view,
});

export default connect(mapStateToProps, { edit })(FormikEdit);
