import React from 'react';
import ReactQuill from 'react-quill';
import { Typography } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

const TextEditor = ({
  label,
  placeholder,
  error,
  helperText,
  toolbar,
  ...args
}) => {
  return (
    <>
      <div className={`text-editor-wrap ${error ? 'border-error' : ''}`}>
        <Typography className={`label-text ${error ? 'text-error' : ''}`}>
          {label}
        </Typography>
        <ReactQuill
          className="ql-editor"
          {...args}
          placeholder={placeholder || ''}
          modules={toolbar || modules}
          formats={formats}
        />
      </div>
      {error && <p className="helper-text">{helperText}</p>}
    </>
  );
};

export default TextEditor;
