import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <Fragment>
      <div style={{ margin: 'auto' }} className="text-center">
        <img
          src={require('assets/images/page-not-found.svg')}
          style={{ marginBottom: 36 }}
          alt="page-not-found icon"
        />
        <Typography style={{ fontSize: 26, fontWeight: 500, marginBottom: 16 }}>
          Sorry, page not found!
        </Typography>
        <p style={{ fontSize: 16, marginBottom: 36 }}>
          Sorry, we couldn’t find the page you’re looking for. This page <br />{' '}
          doesn't exist or some error has occurred.
        </p>
        <Link style={{ fontSize: 16, color: '#1890FF' }} to="/">
          Go Back
        </Link>
      </div>
    </Fragment>
  );
};

export default Error;
