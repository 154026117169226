import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { add } from 'redux/actions/Module';
import { connect } from 'react-redux';

import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';

const initialValueForm = {
  id: '',
  region: '',
  order: '',
  status: '',
};
const handleSubmit = (payload, ctx) => {
  const { id, region, status, order } = payload;
  ctx.props.add({
    name: 'region',
    data: {
      title: region,
      name: id,
      status,
      order,
    },
    notLink: true,
    onSuccess: (res) => {
      ctx.props.handleOpen(false);
    },
  });
};

const Create = ({ open, handleOpen, ...props }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    // data,
    resetForm,
  } = props;
  useEffect(() => {
    resetForm();
    setFieldValue('status', 'active');
    // eslint-disable-next-line
  }, [open]);
  const handleChangeStatus = (event) => {
    setFieldValue('status', event.target.value);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Add New Region
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="id"
                label="ID"
                margin="normal"
                value={values.id}
                error={Boolean(touched.id && errors.id)}
                helperText={touched.id && errors.id}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="region"
                label="Region"
                margin="normal"
                value={values.region}
                error={Boolean(touched.region && errors.region)}
                helperText={touched.region && errors.region}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="order"
                label="Order"
                margin="normal"
                value={values.order}
                error={Boolean(touched.order && errors.order)}
                helperText={touched.order && errors.order}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  onChange={handleChangeStatus}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                  onBlur={handleBlur}
                  name="status"
                  label="Status"
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateFormik = withFormik(
  Create,
  schemeValidations,
  initialValueForm,
  handleSubmit
);

export default connect(null, { add })(CreateFormik);
