import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector, connect } from 'react-redux';
import withFormik from 'utils/withFormik';
import TextEditor from 'components/Form/TextEditor';

import { load, edit, add } from 'redux/actions/Module';
import { editRunningText } from './schemeValidations';
// import { getUser } from 'utils/localStorage';

const initialValueForm = {
  content: '',
};

const mapPropsToValues = ({ runningText }) => {
  if (runningText?.data) {
    const data = runningText?.data;
    return {
      content: data?.content,
    };
  }
  return initialValueForm;
};

const handleSubmitForm = (payload, ctx) => {
  const { content, isCreate, id } = payload;
  if (isCreate) {
    // console.log('iscreate');
    ctx.props.add({
      name: `running-text`,

      data: {
        text: content,
        page: ctx.props.match.params.page,
        link: '',
      },
      notLink: true,
      onSuccess: (res) => {
        ctx.props.history.push(`/running-text/${ctx.props.match.params.page}`);
      },
    });
  } else {
    // console.log('isEdit');

    ctx.props.edit({
      name: `running-text/${ctx.props.match.params.page}/${id}`,

      data: {
        text: content,
        page: ctx.props.match.params.page,
        link: '',
      },
      noLink: true,
      onSuccess: (res) => {
        ctx.props.history.push(`/running-text/${ctx.props.match.params.page}`);
      },
    });
  }
};

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link'],
    ['clean'],
  ],
};

const EditRunningText = (props) => {
  const history = useHistory();
  const { page } = useParams();
  const dispatch = useDispatch();
  const {
    values,
    touched,
    errors,
    // handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;
  const { runningText } = useSelector((state) => state.module.view);

  const view = runningText?.data;

  useEffect(() => {
    dispatch(
      load({
        name: 'running-text',
        id: `${page}`,
        customName: 'runningText',
      })
    );
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (view !== null) {
      setFieldValue('content', view?.text);
      setFieldValue('id', view?.id);
    } else setFieldValue('isCreate', true);
    // eslint-disable-next-line
  }, [view]);

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Edit Running Text"
        breadcrumb={[
          { link: '/running-text', label: 'Running Text.' },
          { link: `/running-text/${page}`, label: 'View Running Text' },
          { link: `/running-text/${page}/edit`, label: 'Edit Running Text' },
        ]}
      />
      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            confirmTitle: 'Edit Running Text',
            confirmMessage: 'Do you wish to save your changes?',
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <Typography className="title" style={{ marginBottom: 16 }}>
            Running Text Details
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="content"
                label="Content"
                value={values.content}
                onChange={(val) =>
                  setFieldValue('content', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'content' } })}
                error={Boolean(touched.content && errors.content)}
                helperText={touched.content && errors.content}
                toolbar={modules}
              />
              {/* <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="content"
                label="Content"
                margin="normal"
                value={values.content}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.content && errors.content)}
                helperText={touched.content && errors.content}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};
const FormikCreate = withFormik(
  EditRunningText,
  editRunningText,
  mapPropsToValues,
  handleSubmitForm
);
export default connect(null, { load, edit, add })(FormikCreate);
