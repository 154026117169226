import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import actionTypes from 'redux/actions/Setting';
import { toggleCollapsedNav } from 'redux/actions/Setting';
import UserInfo from 'components/UserInfo';

const Index = () => {
  const dispatch = useDispatch();
  const { drawerType, navCollapsed } = useSelector(({ settings }) => settings);

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const drawerStyle = drawerType.includes(actionTypes.FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(actionTypes.COLLAPSED_DRAWER)
    ? 'd-block'
    : 'd-none';

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item"></li>
          <li
            style={{ marginRight: '5px' }}
            className="list-inline-item app-tour"
          ></li>
          <li className="list-inline-item">
            <UserInfo />
          </li>
        </ul>

        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
