import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'querystringify';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { list } from 'redux/actions/Module';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIl from 'assets/illustration/404-error.svg';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = (props) => {
  const history = useHistory();
  const { row } = props;
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.user.companyName}</TableCell>
        <TableCell>{row.companyWebsite}</TableCell>
        <TableCell>{row.user.country?.name}</TableCell>
        <TableCell>{row.user.businessSector.join(',')}</TableCell>
        <TableCell>
          <div>
            <Button
              onClick={() =>
                history.push(`/exhibitor-applications/${row.slug}`)
              }
              style={{
                backgroundColor: '#ED901A',
                color: '#ffff',
                textTransform: 'capitalize',
                borderRadius: '6px',
                padding: '5px 20px',
                fontSize: '12px',
              }}
            >
              Open Application
            </Button>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const CollapsibleTable = (props) => {
  const { exhibitor } = useSelector((state) => state.module.list);

  const dispatch = useDispatch();

  useEffect(() => {
    const param = {
      status: 'pending',
      keyword: props.search,
    };

    const query = queryString.stringify(param);

    dispatch(
      list({
        name: 'exhibitor',
        query,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search]);

  const exhibitors = exhibitor?.data;
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Company name</TableCell>
            <TableCell>Website</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Business Sector</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exhibitors && exhibitors.length <= 0 && (
            <TableRow>
              <TableCell
                colSpan={6}
                style={{
                  textAlign: 'center',
                  fontSize: '1rem',
                  padding: '14px 0px',
                }}
              >
                {props.search ? (
                  <div
                    style={{
                      padding: '40px 0',
                    }}
                  >
                    <img
                      src={ErrorIl}
                      alt="error-404"
                      style={{
                        marginBottom: 40,
                      }}
                    />
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#696969',
                      }}
                    >
                      Sorry! We couldn’t find any results based on your search
                    </div>
                  </div>
                ) : (
                  'There is no data'
                )}
              </TableCell>
            </TableRow>
          )}

          {exhibitors &&
            exhibitors.map((row) => <Row key={row.id} row={row} {...props} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
