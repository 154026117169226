import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { getUser, destroyUser } from 'utils/localStorage';

const UserInfo = () => {
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [profilePic] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (getUser()?.user) {
      setUser(getUser()?.user);
    }
  }, []);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const logout = () => {
    destroyUser();
    history.push('/login');
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar src={profilePic ? profilePic : ''} className="userAvatar" />
      <div className="user-detail userWrapper">
        <h4 className="user-name d-flex" onClick={handleClick}>
          <Typography className="text-truncate userTitle">
            {user?.name}
          </Typography>
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle userIcon" />
        </h4>
        <Typography className="userRole">{user && user.roles[0]}</Typography>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {/* {!user?.roles?.includes('exhibitor') && (
          <MenuItem
            onClick={() => {
              handleRequestClose();
              history.push('/profiles');
            }}
          >
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <Typography className="userSubMenu">Profile</Typography>
          </MenuItem>
        )} */}
        <MenuItem
          onClick={() => {
            handleRequestClose();
            logout();
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
          <Typography className="userSubMenu">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
