import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import schemeValidations from './schemeValidations'
import withFormik from 'utils/withFormik'

const initialValueForm = {
  eventName: '',
  eventStartDate: new Date(),
  eventEndDate: new Date(),
  status:''

};
const handleSubmit = () => { };
const Create = ({ open, handleOpen, ...props }) => {
  const [isOpen, setIsOpen] = useState(open);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    // handleSubmit,
    // isValid,
    // dirty,
    setFieldValue,
    // data,
  } = props;
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const handleChangeStatus = (event) => {
    setFieldValue('status',event.target.value);
  };
  const handleChangeStartDate = (date)=>{
    setFieldValue('eventStartDate',date._d)
  }
  const handleChangeEndDate = (date)=>{
    setFieldValue('eventEndDate',date._d)
  }
  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Add Event
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="eventName"
                label="Event Name"
                margin="normal"
                value={values.eventName}
                error={Boolean(touched.eventName && errors.eventName)}
                helperText={touched.eventName && errors.eventName}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={12} item>
              <DatePicker
                label="Date Start"
                name="eventStartDate"
                showTodayButton
                inputFormat="ddd, DD MMMM YYYY"
                animateYearScrolling={false}
                format="ddd, DD MMMM YYYY"
                variant="outlined"
                minDate={new Date()}
                value={values.eventStartDate}
                onBlur={handleBlur}
                onChange={handleChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText={null}

                  />
                )}
              />
            </Grid>
            <Grid sm={12} item>
              <DatePicker
                label="Date End"
                name="endDate"
                showTodayButton
                inputFormat="ddd, DD MMMM YYYY"
                animateYearScrolling={false}
                format="ddd, DD MMMM YYYY"
                variant="outlined"
                minDate={values.eventStartDate}
                value={values.eventEndDate}
                onBlur={handleBlur}
                onChange={handleChangeEndDate}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText={null}
                    
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Status</InputLabel>
                <Select 
                  value={values.status}
                  onChange={handleChangeStatus}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                  onBlur={handleBlur}
                  name="status" 
                  label="Status">
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'Inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateFormik = withFormik(
  Create,
  schemeValidations,
  initialValueForm,
  handleSubmit
)

export default CreateFormik;