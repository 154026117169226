import * as Yup from 'yup';


export const LoginSchemeValidations = Yup.object().shape({
  email: Yup.string()
  // .email('Email address uses an invalid email format.')
  .required('Email address can not be left blank.'),
  password:Yup.string().required('Password can not be left blank.')
});

// export default schemeValidations;

export const ResetSchemeValidations = Yup.object().shape({
  password:Yup.string().required('Password can not be left blank.'),
  passwordConfirmation: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required('Password can only contain letters and numbers.'),
});
export const ForgotSchemeValidations = Yup.object().shape({
  email: Yup.string()
    .email('Email address uses an invalid email format.')
    .required('Email address uses an invalid email format.'),
  userTypes: Yup.string()
    .required('Select the user types.'),
  userTypesValues: Yup.object(),
});