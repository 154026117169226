import * as Yup from 'yup';

export const createPlenarySchemeValidation = Yup.object().shape({
  title: Yup.string().required('Title can not be left blank.'),
  eventDetail: Yup.string().required('Event details can not be left blank.'),
  excerpt: Yup.string().required('Excerpt can not be left blank.'),
  contact: Yup.string().required('Contact can not be left blank.'),
  category: Yup.string().required('Please select a category'),
  meetingLink: Yup.string().required('Meeting link can not be left blank.'),
  thumbnail: Yup.string().required('Image is required.'),
  banner: Yup.string().required('Image is required.'),
  eventDate: Yup.string().required('Please select the event date.'),
  startTime: Yup.string().required('Please select the start time.'),
  endTime: Yup.string().required('Please select the end time.')
});
