import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import to_qs from "utils/queryString";
import ContainerHeader from "components/BreadCrumbTitle";
import Tables from "components/Tables";
import {
	Chip,
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from "@material-ui/core";
import ExportModal from "components/ExportModal";
import moment from "moment";
import AutoComplete from "components/Form/AutoComplete";

const FilterModal = ({ open, handleOpen, onFilter }) => {
	const [form, setForm] = useState({
		sender: "",
		recipient: "",
		event: "",
		senderCountry: "",
		recipientCountry: "",
	});
	const [filters, setFilters] = useState([]);

	const handleApplyFilter = () => {
		let query = {
			sender: [],
			recipient: [],
			event: [],
			senderCountry: [],
			recipientCountry: [],
		};
		filters.forEach((n) => {
			if (n.name === "sender") {
				query.sender.push(n.value);
			} else if (n.name === "recipient") {
				query.recipient.push(n.value);
			} else if (n.name === "event") {
				query.event.push(n.value);
			} else if (n.name === "senderCountry") {
				query.senderCountry.push(n.value);
			} else if (n.name === "recipientCountry") {
				query.recipientCountry.push(n.value);
			}
		});
		onFilter(query);
	};

	const addFilter = (name) => {
		let value = {
			name,
			label: form[name].label,
			value: form[name].value,
		};
		if (form[name]) {
			const isAny =
				filters.filter((n) => n.name === name && n.value === form[name].value)
					.length > 0;
			if (!isAny) {
				setFilters([...filters, value]);
			}
		}
		setForm({
			sender: "",
			recipient: "",
			event: "",
			senderCountry: "",
			recipientCountry: "",
		});
	};

	const handleDelete = (idx) => {
		let data = filters.filter((n, index) => index !== idx);
		setFilters(data);
	};

	return (
		<Dialog open={open} fullWidth maxWidth="md">
			<DialogContent style={{ padding: 40 }}>
				<div>
					<Typography
						style={{
							marginBottom: 12,
							fontWeight: 500,
							fontSize: 16,
							color: "#282828",
						}}
					>
						Current filter
					</Typography>
					<div>
						{filters.map((n, i) => (
							<Chip
								key={i}
								label={n.label}
								onDelete={() => handleDelete(i)}
								style={{ marginRight: 10 }}
							/>
						))}
					</div>
				</div>
				<div>
					<Typography
						style={{
							marginBottom: 20,
							marginTop: 85,
							fontWeight: 500,
							fontSize: 16,
							color: "#282828",
						}}
					>
						Filter Data
					</Typography>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Sender Company"
								name="sender"
								onChange={(val) =>
									setForm({
										...form,
										sender: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.sender}
								url="/visitor/list"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.companyName,
									value: n.id,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("sender")}
								style={{
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Recipient Exhibitor"
								name="recipient"
								onChange={(val) =>
									setForm({
										...form,
										recipient: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.recipient}
								url="/exhibitor/getlist"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.companyName,
									value: n.id,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("recipient")}
								style={{
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Events"
								name="event"
								onChange={(val) =>
									setForm({
										...form,
										event: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.event}
								url="/events/list"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.eventName,
									value: n.id,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("event")}
								style={{
									marginTop: 15,
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Sender Country"
								name="senderCountry"
								onChange={(val) =>
									setForm({
										...form,
										senderCountry: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.senderCountry}
								url="/country"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.name,
									value: n.code,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("senderCountry")}
								style={{
									marginTop: 15,
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Recipient Country"
								name="recipientCountry"
								onChange={(val) =>
									setForm({
										...form,
										recipientCountry: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.recipientCountry}
								url="/country"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.name,
									value: n.code,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("recipientCountry")}
								style={{
									marginTop: 15,
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions style={{ padding: 40 }}>
				<Button
					onClick={() => handleOpen(false)}
					style={{
						border: "1px solid #E45858",
						borderRadius: 8,
						fontSize: 14,
						color: "#E45858",
						textTransform: "capitalize",
						padding: "11px 26px",
					}}
				>
					Cancel
				</Button>
				<Button
					onClick={() => {
						handleApplyFilter();
						handleOpen(false);
					}}
					style={{
						background: "#ED901A",
						borderRadius: 8,
						fontSize: 14,
						color: "#ffffff",
						textTransform: "capitalize",
						padding: "11px 26px",
					}}
				>
					Apply Filter
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const Index = () => {
	const history = useHistory();
	const [keyword, setKeyword] = useState("");

	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const [isOpenExport, setIsOpenExport] = useState(false);
	const [query, setQuery] = useState({
		sender: [],
		recipient: [],
		event: [],
		senderCountry: [],
		recipientCountry: [],
	});

	return (
		<div className="app-wrapper appointment-page">
			<ContainerHeader
				title="Appointments"
				breadcrumb={[{ link: "/appointments", label: "Appointments" }]}
			/>

			<div className="action-btn">
				<Button
					style={{
						border: "1px solid #ED901A",
						borderRadius: 8,
						color: "#ED901A",
						fontSize: 14,
						padding: "8px 24px",
						textTransform: "capitalize",
					}}
					onClick={() => setIsOpenExport(true)}
				>
					Export
				</Button>
			</div>

			<Tables
				title="Appointments"
				name="appointment"
				addQueryString={to_qs(query)}
				setKeyword={setKeyword}
				withFilterModal={(val) => setIsOpenFilter(val)}
				withSearch={{ placeholder: "Search for a company..." }}
				columnHead={[
					{ id: "id", label: "No", disableSort: false },
					{ id: "sender", label: "Sender Company" },
					{ id: "recipient", label: "Recipient Exhibitor" },
					{ id: "event", label: "Event" },
					{ id: "created_at", label: "Created At" },
					{ id: "", label: "Action", disableSort: true },
				]}
				columnBody={[
					{ parent: "number" },
					{ parent: (n) => n.user && n.user.companyName },
					{ parent: (n) => n.exhibitor && n.exhibitor.companyName },
					{ parent: (n) => n.event && n.event.title },
					{ parent: (n) => moment(n.createdAt).format("DD MMM YYYY") },
					{
						parent: (n) => (
							<Button
								onClick={() => history.push(`/appointments/${n.id}`)}
								style={{
									background: "#3C91D6",
									borderRadius: 6,
									color: "#fff",
									textTransform: "capitalize",
								}}
							>
								View Details
							</Button>
						),
					},
				]}
			/>

			<FilterModal
				open={isOpenFilter}
				handleOpen={(val) => setIsOpenFilter(val)}
				onFilter={(val) => setQuery({ ...val })}
				filter={query}
			/>

			{isOpenExport && (
				<ExportModal
					name="appointment"
					customName="appointments"
					filter={query}
					keyword={keyword}
					open={isOpenExport}
					handleOpen={(val) => setIsOpenExport(val)}
				/>
			)}
		</div>
	);
};

export default Index;
