import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import actionTypes from 'redux/actions/Setting';
import { toggleCollapsedNav, updateWindowWidth } from 'redux/actions/Setting';
import SideBarContent from './SideBarContent';
import InaLogo from 'assets/images/INA-Access.svg';

const SideBar = () => {
  const dispatch = useDispatch();
  const { drawerType, width, navCollapsed } = useSelector(
    ({ settings }) => settings
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const onToggleCollapsedNav = (e) => {
    dispatch(toggleCollapsedNav());
  };

  let drawerStyle = drawerType.includes(actionTypes.FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(actionTypes.COLLAPSED_DRAWER)
    ? ''
    : 'd-flex';
  let type = 'permanent';
  if (
    drawerType.includes(actionTypes.COLLAPSED_DRAWER) ||
    (drawerType.includes(actionTypes.FIXED_DRAWER) && width < 1200)
  ) {
    type = 'temporary';
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
      >
        <div style={{ padding: 20 }}>
          <Link className="app-logo" to="/">
            <img style={{ height: '42px' }} src={InaLogo} alt="inalac-logo" />
          </Link>
        </div>
        <SideBarContent />
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
