const format = (k, v) => (v !== null ? `${k}=${encodeURIComponent(v)}` : '');

const to_qs = (obj) => {
  return []
    .concat(
      ...Object.entries(obj).map(([k, v]) =>
        Array.isArray(v) ? v.map((arr) => to_qs({ [k]: arr })) : format(k, v)
      )
    )
    .filter((x) => x)
    .join('&');
};

export default to_qs;
