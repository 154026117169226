import { takeEvery, put, all, call } from 'redux-saga/effects';
import history from 'utils/history';
import { request } from '../../utils/request';
import { BASE_URL } from '../../utils/url';
import { getUser, setUser, destroyUser } from '../../utils/localStorage';
import { notif, loading, response } from '../actions/Global';
import actionTypes, {
  failure,
  // signUpSuccess,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  changePasswordProfileSuccess,
} from '../actions/Oauth';

const notifError = (msg) =>
  notif({ open: true, variant: 'error', message: msg });
const notifSuccess = (msg) =>
  notif({ open: true, variant: 'success', message: msg });

function* signIn({ param }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/admin/login`;
  const body = JSON.stringify(param);
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    yield setUser({ token: res.data.token, user: { ...res.data } });
    yield put(notifSuccess(res.message || 'Sign-in Success'));
    yield put(loading(false));
    yield call(history.push, '/');
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message) {
      yield put(notifError(res.message || 'Invalid email or password'));
    }
  }
}

function* signInV2({ param }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/v2/admin/login`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${param.token}`,
      },
    });
    yield setUser({ token: res.data.token, user: { ...res.data } });
    yield put(notifSuccess(res.message || 'Sign-in Success'));
    yield put(loading(false));
    yield call(history.push, '/');
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message) {
      yield put(notifError(res.message || 'Invalid email or password'));
    }
  }
}

function* signUp({ param }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/exhibitor`;
  const body = JSON.stringify(param);
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
      body,
    });
    yield put(notifSuccess(res.message || 'Add Exhibitor Success'));
    // yield put(signUpSuccess(res));
    yield call(history.push, '/exhibitors');
    yield put(loading(false));
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message) {
      yield put(notifError(res.message || 'This email is already taken'));
    }
  }
}

function* forgotPassword({ param }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/forgot`;
  const body = JSON.stringify(param);
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    yield put(forgotPasswordSuccess(res));
    yield put(notifSuccess(res.message));
    // yield call(history.push, '/signin')
    yield put(loading(false));
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message) {
      yield put(
        notifError(res.message || 'No account with that email has been found')
      );
    }
  }
}

function* resetPassword({ param }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/forgot/reset/${param.token}`;
  const body = JSON.stringify(param.data);
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    yield put(notifSuccess('Reset Password Success'));
    yield put(resetPasswordSuccess(res));
    yield put(loading(false));
    // yield call(history.push, '/signin')
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (err.message) {
      yield put(notifError(res.message));
    }
  }
}

function* resetPasswordMobile({ param }) {
  yield put(loading(true));
  yield put(response(false));
  const requestURL = `${BASE_URL}/api/auth/reset/${param.token}`;
  const body = JSON.stringify(param.data);
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    yield put(notifSuccess('Reset Password Success'));
    yield put(resetPasswordSuccess(res));
    yield put(loading(false));
    yield put(response(true));
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (err.message) {
      yield put(notifError(res.message));
    }
  }
}

function* changePasswordProfile({ param }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/profile/changePassword?${param.query ||
    ''}`;
  const body = JSON.stringify(param.data);
  const auth = getUser();
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
      body,
    });
    yield put(notifSuccess('Reset Password Success'));
    yield put(changePasswordProfileSuccess(res));
    yield put(loading(false));
    if (param.isAfterLogin) {
      destroyUser();
      yield call(history.push, '/signin');
    }
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    yield put(changePasswordProfileSuccess(res));
    yield put(notifError(res.message));
  }
}

function* activation({ param }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/auth/activation/${param.token}`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    yield put(notifSuccess(res.message));
    // yield call(history.push, '/signin')
    yield put(loading(false));
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (err.message) {
      yield put(notifError(err.message));
    } else {
      yield put(notifError(res.message));
    }
  }
}

function* logout() {
  try {
    yield destroyUser();
    yield put(notifSuccess('Sign-out Success'));
    yield history.push('/signin');
  } catch (err) {
    if (err.message) {
      yield put(failure(err));
    }
  }
}

function* oauthSaga() {
  yield all([
    takeEvery(actionTypes.SIGN_IN, signIn),
    takeEvery(actionTypes.SIGN_IN_V2, signInV2),
    takeEvery(actionTypes.SIGN_UP, signUp),
    takeEvery(actionTypes.FORGOT_PASSWORD, forgotPassword),
    takeEvery(actionTypes.RESET_PASSWORD, resetPassword),
    takeEvery(actionTypes.RESET_PASSWORD_MOBILE, resetPasswordMobile),
    takeEvery(actionTypes.CHANGE_PASSWORD_PROFILE, changePasswordProfile),
    takeEvery(actionTypes.ACTIVATION, activation),
    takeEvery(actionTypes.LOGOUT, logout),
  ]);
}

export default oauthSaga;
