import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import _ from 'lodash';
import { statusBanner as status } from 'data';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import {
  connect,
  useDispatch,

  // useSelector
} from 'react-redux';
import { createBanner } from './schemeValidations';
import UploadImage from 'components/Upload/UploadImage';
import withFormik from 'utils/withFormik';
import { edit, list as listRedux, load, remove } from 'redux/actions/Module';
// import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';
import UploadDocument from 'components/Upload/UploadDocument';
import { uploadBase64Image } from 'utils/uploadMedia';

const initialValueForm = {
  status: { value: 'draft', title: 'Draft' },
  order: '',
  media: '',
  mediaValues: {},
  title: '',
  description: '',
  image: [],
  titleButton: '',
  link: '',
  file: '',
  page: '',
  id: '',
  mobile: [],
};

const mapPropsToValues = ({ banner }) => {
  // console.log(banner);
  if (banner?.data) {
    const data = banner?.data;
    return {
      status: data?.status,
      order: data?.sort,
      media: data?.type,
      mediaValues: { value: data?.type, title: data?.type },
      title: data?.title,
      description: data?.description,
      image: data?.type === 'image' ? [data?.image] : [],
      titleButton: data?.button_txt,
      mobile: [data?.image_mobile],
      link: data?.link,
      file: data?.type === 'video' ? data?.image : '',
    };
  }
  return initialValueForm;
};

const handleSubmitForm = (payload, ctx) => {
  const {
    status,
    order,
    media,
    title,
    description,
    image,
    titleButton,
    link,
    file,
    mobile,
  } = payload;

  const imgRegex = new RegExp(/data:image\/[^;]+;base64[^"]+/g)
  const imgSrc = payload.description.match(imgRegex) || []

  Promise.all(imgSrc.map(src => 
    new Promise( async (resolve) => {
      const res = await uploadBase64Image(src, 'others')
      resolve(res)
    })
  )).then(values => {
    for (let i = 0; i < values.length; i++) {
      if (values[i]?.status === 200) {
        payload.description = payload.description.replace(imgSrc[i], values[i].data.originalUrl)
      }
    }
    
    ctx.props.edit({
      name: `banner/${ctx.props.match.params.id}`,
      data: {
        status,
        title,
        description,
        image: media === 'video' ? file : image.toString(),
        link,
        image_mobile: media === 'image' && mobile.toString(),
        sort: order,
        type: media,
        page: ctx.props.match.params.page,
        button_txt: titleButton,
      },
      noLink: true,
      onSuccess: (res) => {
        ctx.props.history.push(`/banners/page/${ctx.props.match.params.page}`);
      },
    });
  }).catch(e => {
    console.error('Error occured:', e.message)
  });
};

const CreateBanner = (props) => {
  const history = useHistory();
  const { id, page } = useParams();
  const dispatch = useDispatch();
  // const { list } = useSelector((state) => state.module);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  useEffect(() => {
    dispatch(
      load({
        name: 'banner',
        id: `${page}/${id}`,
      })
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFieldValue('page', page);
    setFieldValue('id', id);
    // eslint-disable-next-line
  }, []);

  const deleteBanner = () => {
    dispatch(
      remove({
        name: 'banner',
        id,
        customName: `banners/page/${page}`,
      })
    );
  };

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Edit Banner"
        breadcrumb={[
          { link: '/banners', label: 'Banners' },
          { link: '/banners', label: 'All Pages' },
          { link: `/banners/page/${page}`, label: _.capitalize(page) },
          { link: `/banners/page/${page}/edit/${id}`, label: 'Edit Banner' },
        ]}
      />
      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Edit Banner',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Delete Banner',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Delete',
              style: {
                background: '#E14040',
                color: '#fff',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Banner',
            confirmMessage:
              'This action can not be undone. Are you sure you want to delete the banner?',
            style: {
              border: '1px solid #E14040',
              color: '#fff',
              background: '#E14040',
              padding: '8px 17px',
            },
            onClick: () => deleteBanner(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <FormControl
                variant="outlined"
                fullWidth
                size="medium"
                margin="normal"
              >
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  name="status"
                  label="status"
                  onChange={(event) =>
                    setFieldValue('status', event.target.value)
                  }
                  onBlur={handleBlur}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                >
                  {status.map((value, index) => (
                    <MenuItem value={value.value}>{value.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="order"
                label="Order"
                margin="normal"
                value={values.order}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.order && errors.order)}
                helperText={touched.order && errors.order}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title" style={{ marginBottom: 16 }}>
            Media Type
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="media"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'image', title: 'Image' },
                  { value: 'video', title: 'Video' },
                ]}
                value={values.mediaValues}
                onChange={(e, val) => {
                  setFieldValue(
                    'media',
                    val && val?.value !== null ? val.value : ''
                  );
                  setFieldValue('mediaValues', val !== null ? val : {});
                }}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Media"
                    variant="outlined"
                    name="media"
                    error={Boolean(touched.media && errors.media)}
                    helperText={touched.media && errors.media}
                    value={values.media}
                    onBlur={handleBlur}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        {values?.media === 'image' ? (
          <div>
            <div className="section">
              <Typography className="title" style={{ marginBottom: 16 }}>
                Banner Details
              </Typography>
              <Grid container spacing={3}>
                <Grid sm={12} item>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="title"
                    label="Title"
                    margin="normal"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={12} item>
                  <TextEditor
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={(val) =>
                      setFieldValue(
                        'description',
                        val === '<p><br></p>' ? '' : val
                      )
                    }
                    onBlur={(e) =>
                      handleBlur({ target: { name: 'description' } })
                    }
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="section">
              <Typography className="title" style={{ marginBottom: 16 }}>
                Desktop Background Image
              </Typography>

              <UploadImage
                name="image"
                value={values.image}
                onImageChange={(val) => setFieldValue('image', val)}
                error={errors.image}
                multiple
                max={1}
                type="image_banner"
              />

              <Typography
                fontStyle="italic"
                style={{ fontSize: 14, fontStyle: 'italic' }}
              >
                Max file size is 10mb
              </Typography>
              <Typography
                fontStyle="italic"
                style={{ fontSize: 14, fontStyle: 'italic' }}
              >
                Recommended size is 960px x 800px (.jpg, .jpeg, and .png)
              </Typography>
            </div>
            <div className="section">
              <Typography className="title" style={{ marginBottom: 16 }}>
                Mobile Background Image
              </Typography>

              <UploadImage
                name="mobile"
                value={values.mobile}
                onImageChange={(val) => setFieldValue('mobile', val)}
                error={errors.mobile}
                multiple
                max={1}
                type="image_banner"
              />

              <Typography
                fontStyle="italic"
                style={{ fontSize: 14, fontStyle: 'italic' }}
              >
                Max file size is 10mb
              </Typography>
              <Typography
                fontStyle="italic"
                style={{ fontSize: 14, fontStyle: 'italic' }}
              >
                Recommended size is 960px x 800px (.jpg, .jpeg, and .png)
              </Typography>
            </div>
            <div className="section">
              <Typography className="title" style={{ marginBottom: 16 }}>
                Button
              </Typography>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="titleButton"
                    label="Button Title"
                    margin="normal"
                    value={values.titleButton}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.titleButton && errors.titleButton)}
                    helperText={touched.titleButton && errors.titleButton}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="link"
                    label="Link"
                    margin="normal"
                    value={values.link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.link && errors.link)}
                    helperText={touched.link && errors.link}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        ) : null}
        {values?.media === 'video' ? (
          <div className="section">
            <UploadDocument
              label="File"
              name="file"
              value={values?.file}
              onImageChange={setFieldValue}
              error={errors.file}
              max={1}
              isBanner
              type="video_banner"
              maxSize={2000}
            />
            <Typography
              fontStyle="italic"
              style={{ fontSize: 14, fontStyle: 'italic' }}
            >
              Max file size is 30mb
            </Typography>
            <Typography
              fontStyle="italic"
              style={{ fontSize: 14, fontStyle: 'italic' }}
            >
              Max duration is 30 seconds
            </Typography>
            <Typography
              fontStyle="italic"
              style={{ fontSize: 14, fontStyle: 'italic' }}
            >
              Recommended size is 1440px x 800px (.mp4)
            </Typography>
          </div>
        ) : null}
      </Board>
    </div>
  );
};

const FormikCreate = withFormik(
  CreateBanner,
  createBanner,
  mapPropsToValues,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  banner: state.module.view.banner,
});

export default connect(mapStateToProps, { edit, listRedux })(FormikCreate);
