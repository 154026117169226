import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavCollapse from './NavCollapse';

const NavSection = (props) => {
  const { name, icon, children = [] } = props;
  const isExpandable = children && children.length > 0;

  const MenuCollapse = (
    <List component="div" className="nav-header">
      {/* Display an icon if any */}
      {!!icon && <i className={'zmdi zmdi-' + icon} />}
      {name === 'Setting' ? 'Settings' : name}
    </List>
  );

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {children.map((item, index) => (
        <React.Fragment key={index}>
          {item.type === 'section' ? (
            <NavSection {...item} />
          ) : item.type === 'collapse' ? (
            <NavCollapse {...item} />
          ) : item.type === 'item' ? (
            <NavMenuItem {...item} />
          ) : null}
        </React.Fragment>
      ))}
    </List>
  ) : null;

  return (
    <React.Fragment>
      <div className="nav-section">
        {MenuCollapse}
        {MenuItemChildren}
      </div>
    </React.Fragment>
  );
};

export default NavSection;
