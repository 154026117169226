import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import UploadImage from 'components/Upload/UploadImage';

import { connect } from 'react-redux';
import { Upload as UploadSchemeValidation } from '../schemeValidation';
import withFormik from 'utils/withFormik';
import { edit } from 'redux/actions/Module';
import { getUser } from 'utils/localStorage';

const initialValueForm = {
  slug: '',
  logo: '',
  background: '',
};

const handleSubmitForm = (payload, ctx) => {
  const { slug, logo, background } = payload;
  ctx.props.edit({
    name: `exhibitor/${slug}`,
    data: {
      companyLogo: logo.toString(),
      backgroundPhoto: background.toString(),
    },
    noLink: true,
    onSuccess: (res) => ctx.props.handleOpen(res),
  });
};

const UploadLogo = ({ open, handleOpen, companyDetail, ...props }) => {
  const slug = useParams().exhibitorId;
  const { handleSubmit, isValid, dirty, setFieldValue, values, errors } = props;

  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : slug;

  useEffect(() => {
    // eslint-disable-next-line
    setFieldValue('slug', exhibitorId);
    setFieldValue('logo', [companyDetail?.companyLogo]);
    setFieldValue('background', [companyDetail?.backgroundPhoto]);

    // eslint-disable-next-line
  }, [exhibitorId]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: '40px 40px 0 40px' }}>
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Select Logo
        </Typography>
        <UploadImage
          name="logo"
          value={values.logo}
          onImageChange={(val) => setFieldValue('logo', val)}
          error={errors.logo}
          max={1}
          // multiple
        />
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Max file size is 10mb
        </Typography>
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Recommended size for logo is 180px x 180px
        </Typography>
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Accepted file format: .jpg, .jpeg, and .png
        </Typography>
        <Typography
          style={{
            marginTop: 20,
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Select Banner
        </Typography>
        <UploadImage
          name="background"
          value={values.background}
          onImageChange={(val) => setFieldValue('background', val)}
          error={errors.background}
          max={1}
          // multiple
        />
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Max file size is 10mb
        </Typography>
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Recommended size for logo is 1440px x 500px
        </Typography>
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Accepted file format: .jpg, .jpeg, and .png
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikCreate = withFormik(
  UploadLogo,
  UploadSchemeValidation,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
  userRoles: state.module.view.userRoles,
});

export default connect(mapStateToProps, { edit })(FormikCreate);
