import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { registerSchemeValidations } from './schemeValidation';
import withFormik from 'utils/withFormik';
import { applying, sector } from 'data';
// import PhoneInput from "react-phone-input-2";
import FormikPhoneInput from '../../components/FormikPhoneInput';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UploadDocument from 'components/Upload/UploadDocument';
import { asMutable } from 'seamless-immutable';

import { connect, useDispatch, useSelector } from 'react-redux';
import { signUp } from 'redux/actions/Oauth';
import { list as listRedux, load } from 'redux/actions/Module';
import UploadOthersDocument from 'components/Upload/UploadOthersDocument';

const initialValueForm = {
  companyName: '',
  country: '',
  businessSector: '',
  companyPhoneNumber: '',
  companyEmail: '',
  companyWebsite: '',
  companyAddress: '',
  province: '',
  district: '',
  postalCode: '',
  picName: '',
  picPhone: '',
  picEmail: '',
  picPosition: '',
  applyingAs: '',
  nib: '',
  siup: '',
  npwp: '',
  npwpFile: '',
  password: '',
  passwordConfirmation: '',
  companyCountry: '',
  izinEkspor: '',
  others: [],
};

const handleSubmit = (payload, ctx) => {
  const {
    companyName,
    country,
    businessSector,
    companyPhoneNumber,
    companyWebsite,
    companyEmail,
    companyAddress,
    province,
    district,
    postalCode,
    picName,
    picPhone,
    picEmail,
    picPosition,
    password,
    nib,
    npwp,
    siup,
    applyingAs,
    npwpFile,
    companyCountry,
    izinEkspor,
    others,
  } = payload;
  let business = [];
  businessSector.map((value, index) => {
    business.push(value.name);
    return value;
  });
  ctx.props.signUp({
    companyName,
    country,
    companyCountry,
    businessSector: business,
    companyPhoneNumber,
    companyWebsite,
    companyEmail,
    companyAddress,
    companyAddressProvince: province,
    companyAddressDistrict: district,
    companyAddressPostalCode: postalCode,
    name: picName,
    phoneNumber: picPhone,
    email: picEmail,
    picPosition,
    password,
    npwp: npwpFile,
    noNpwp: npwp.toString(),
    siup,
    backgroundPhoto: 'string',
    suratIzinUsaha: nib,
    nib,
    suratIzinEkspor: izinEkspor,
    companyLogo: 'string',
    companyProfile: 'string',
    type: 'exhibitor',
    applyAs: applyingAs,
    others,
  });
};

const Create = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailValid, setEmailValid] = useState(false);
  const [companyValid, setCompanyValid] = useState(false);
  const [mutableCountries, setMutableCountries] = useState([]);
  const { province, district, businessSector, countriesApi } = useSelector(
    (state) => state.module.list
  );

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    setFieldError,
  } = props;
  useEffect(() => {
    dispatch(
      listRedux({
        name: 'country',
        customName: 'countriesApi',
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (countriesApi) {
      let countriesApiMutable = asMutable(countriesApi, { deep: true });

      setMutableCountries(countriesApiMutable);
    }

    // eslint-disable-next-line
  }, [countriesApi]);
  useEffect(() => {
    dispatch(
      listRedux({
        name: 'province',
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(
      listRedux({
        name: 'sector/getlist',
        customName: 'businessSector',
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values.provinceValues !== undefined) {
      dispatch(
        listRedux({
          name: `city/${values.provinceValues.id}`,
          customName: 'district',
        })
      );
    }
    // eslint-disable-next-line
  }, [values.provinceValues]);

  const checkCompanyName = () => {
    if (values.companyName !== '') {
      dispatch(
        load({
          name: 'check',
          id: `companyPhone?field=companyName&detail=${values.companyName}&as=exhibitor`,
          onSuccess: (res) => onSuccessCheckCompanyName(res),
        })
      );
    }
  };
  const onSuccessCheckCompanyName = (res) => {
    if (res.data) {
      setFieldError(
        'companyName',
        'A company with the same name is already registered.'
      );
    } else {
      setCompanyValid(true);
    }
    // console.log(res);
  };
  const checkEmail = () => {
    if (values.picEmail !== '') {
      dispatch(
        load({
          name: 'check',
          id: `email?role=exhibitor&email=${values.picEmail}`,
          onSuccess: (res) => onSuccessCheckEmail(res),
        })
      );
    }
  };
  const onSuccessCheckEmail = (res) => {
    if (res.data) {
      setFieldError(
        'picEmail',
        'Email with the same name is already registered.'
      );
    } else {
      setEmailValid(true);
    }
    // console.log(res);
  };
  const onSubmit = () => {
    if (emailValid && companyValid) {
      handleSubmit();
    } else {
      checkCompanyName();
      checkEmail();
    }
  };

  useEffect(() => {
    if (emailValid && companyValid) {
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [emailValid, companyValid]);

  const handlePhoneChange = (value, country) => {
    setFieldValue('companyPhoneNumber', value);
  };
  const handlePicPhoneChange = (value, country) => {
    setFieldValue('picPhone', value);
  };
  // eslint-disable-next-line
  const onCountryChange = (event, values) => {
    setFieldValue(
      'companyCountry',
      values && values.label !== null ? values.label : ''
    );
    setFieldValue(
      'companyCountryValues',
      values && values !== null ? values : {}
    );
  };

  const handleBlurBusinessSector = (event, values) => {
    dispatch(
      listRedux({
        name: 'sector/getlist',
        query: `keyword=${values}`,
        customName: 'businessSector',
      })
    );
  };

  const onPicCountryChange = (event, values) => {
    setFieldValue('country', values && values.name !== null ? values.name : '');
    setFieldValue('countryValues', values && values !== null ? values : {});
    setFieldValue(
      'companyCountry',
      values && values.name !== null ? values.name : ''
    );
    setFieldValue(
      'companyCountryValues',
      values && values !== null ? values : {}
    );
  };

  const onBusinessSector = (event, values) => {
    setFieldValue('businessSector', values ? values : []);
  };

  const onProvinceChange = (event, values) => {
    setFieldValue('provinceValues', values && values !== null ? values : {});
    setFieldValue(
      'province',
      values && values.name !== null ? values.name : ''
    );
  };

  const onDistrictChange = (event, values) => {
    setFieldValue('districtValues', values && values !== null ? values : {});

    setFieldValue(
      'district',
      values && values.name !== null ? values.name : ''
    );
  };

  const onApplyingAs = (event, values) => {
    setFieldValue('applyingValues', values && values !== null ? values : {});

    setFieldValue(
      'applyingAs',
      values && values.data !== null ? values.data : ''
    );
  };

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Add New Exhibitor"
        breadcrumb={[
          { link: '/exhibitors', label: 'All Exhibitors' },
          { link: '', label: 'Add New Exhibitor' },
        ]}
      />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Add Exhibitor',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                border: '1px solid #1AB759',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Add Exhibitor',
            confirmMessage: 'Do you wish to add this?',
            onClick: onSubmit,
            style: {
              width: 148,
              color: '#fff',
              background: '#1AB759',
              border: '1px solid #1AB759',
            },
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#E14040',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Cancel Add Exhibitor',
            confirmMessage: 'Do you want to cancel add Exhibitor?',
            onClick: () => history.goBack(),
            style: {
              width: 130,
              color: '#E45858',
              border: '1px solid #E45858',
            },
          },
        ]}
      >
        <div className="section">
          <Typography className="title">Company Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyName"
                label="Company/Institution Name"
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.companyName && errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                value={values.companyName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <Autocomplete
                name="country"
                style={{ marginTop: 15 }}
                options={mutableCountries?.data?.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                value={values.countryValues}
                onChange={onPicCountryChange}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="PIC Country"
                    variant="outlined"
                    name="country"
                    // error={true}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    value={values.country}
                    onBlur={handleBlur}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                multiple
                name="businessSector"
                options={
                  businessSector
                    ? businessSector && businessSector.data
                    : sector
                }
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                style={{ marginTop: 15 }}
                onChange={onBusinessSector}
                value={values.businessSectorValues}
                onInputChange={handleBlurBusinessSector}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Business Sector"
                    name="businessSector"
                    error={Boolean(
                      touched.businessSector && errors.businessSector
                    )}
                    helperText={touched.businessSector && errors.businessSector}
                    value={values.businessSector}
                    // onBlur={handleBlurBusinessSector}
                    onChange={handleChange}
                    placeholder={
                      values.businessSector.length > 0
                        ? ''
                        : 'Please select up to 3 business sectors'
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormikPhoneInput
                name="companyPhoneNumber"
                inputProps={{ name: 'companyPhoneNumber' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                error={Boolean(
                  touched.companyPhoneNumber && errors.companyPhoneNumber
                )}
                helperText={
                  touched.companyPhoneNumber && errors.companyPhoneNumber
                }
                value={values.companyPhoneNumber}
                onChange={handlePhoneChange}
                onBlur={handleBlur}
                countryCodeEditable={false}
                specialLabel="Company Phone Number"
                errorText="Company phone numbers can not be left blank."
                country={
                  values?.companyCountryValues &&
                  values?.companyCountryValues?.code?.toLowerCase()
                }
                onlyCountries={
                  values?.companyCountryValues && [
                    values?.companyCountryValues?.code?.toLowerCase(),
                  ]
                }
                isValid={(value, country) => {
                  console.log(country);
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyEmail"
                label="Company Email"
                margin="normal"
                value={values.companyEmail}
                error={Boolean(touched.companyEmail && errors.companyEmail)}
                helperText={touched.companyEmail && errors.companyEmail}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyWebsite"
                label="Company Website"
                margin="normal"
                value={values.companyWebsite}
                error={Boolean(touched.companyWebsite && errors.companyWebsite)}
                helperText={touched.companyWebsite && errors.companyWebsite}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyAddress"
                label="Company Address"
                margin="normal"
                value={values.companyAddress}
                error={Boolean(touched.companyAddress && errors.companyAddress)}
                helperText={touched.companyAddress && errors.companyAddress}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              {values.companyCountry === 'Indonesia' ||
              values.companyCountry === '' ? (
                <Autocomplete
                  style={{ marginTop: 15 }}
                  options={province && province.data}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={onProvinceChange}
                  value={values.provinceValues}
                  name="province"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Province"
                      name="province"
                      error={Boolean(touched.province && errors.province)}
                      helperText={touched.province && errors.province}
                      value={values.province}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="Province/State"
                  name="province"
                  style={{ marginTop: 15 }}
                  fullWidth
                  error={Boolean(touched.province && errors.province)}
                  helperText={touched.province && errors.province}
                  value={values.province}
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              {values.companyCountry === 'Indonesia' ||
              values.companyCountry === '' ? (
                <Autocomplete
                  style={{ marginTop: 15 }}
                  options={district && district.data}
                  disabled={values.province === ''}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={onDistrictChange}
                  name="district"
                  value={values.districtValues}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="District"
                      name="district"
                      error={Boolean(touched.district && errors.district)}
                      helperText={touched.district && errors.district}
                      value={values.district}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="District/City/Suburb"
                  name="district"
                  style={{ marginTop: 15 }}
                  error={Boolean(touched.district && errors.district)}
                  helperText={touched.district && errors.district}
                  value={values.district}
                  onBlur={handleBlur}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
            <Grid sm={6} item>
              <TextField
                label="Postal Code"
                margin="normal"
                type="number"
                name="postalCode"
                error={Boolean(touched.postalCode && errors.postalCode)}
                helperText={touched.postalCode && errors.postalCode}
                value={values.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyCountry"
                label="Company Country"
                margin="normal"
                value={values.companyCountry}
                error={Boolean(touched.companyCountry && errors.companyCountry)}
                helperText={touched.companyCountry && errors.companyCountry}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">PIC Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                label="PIC Name"
                margin="normal"
                type="text"
                fullWidth
                name="picName"
                error={Boolean(touched.picName && errors.picName)}
                helperText={touched.picName && errors.picName}
                value={values.picName}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid sm={6} item>
              <FormikPhoneInput
                name="picPhone"
                inputProps={{ name: 'picPhone' }}
                containerClass="signUpVisitor-phoneInput"
                errorText="PIC phone numbers can not be left blank."
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                error={Boolean(touched.picPhone && errors.picPhone)}
                helperText={touched.picPhone && errors.picPhone}
                value={values.picPhone}
                onChange={handlePicPhoneChange}
                countryCodeEditable={false}
                onBlur={handleBlur}
                country={
                  values?.companyCountryValues &&
                  values?.companyCountryValues?.code?.toLowerCase()
                }
                onlyCountries={
                  values?.companyCountryValues && [
                    values?.companyCountryValues?.code?.toLowerCase(),
                  ]
                }
                specialLabel="PIC Phone Number"
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                label="PIC Email"
                type="email"
                margin="normal"
                fullWidth
                name="picEmail"
                error={Boolean(touched.picEmail && errors.picEmail)}
                helperText={touched.picEmail && errors.picEmail}
                value={values.picEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                label="PIC Position"
                type="text"
                margin="normal"
                name="picPosition"
                error={Boolean(touched.picPosition && errors.picPosition)}
                helperText={touched.picPosition && errors.picPosition}
                value={values.picPosition}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                label="Password"
                placeholder="Your password must contain letters and numbers"
                type="password"
                margin="normal"
                fullWidth
                name="password"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                label="Confirm Password"
                type="password"
                margin="normal"
                fullWidth
                name="passwordConfirmation"
                error={Boolean(
                  touched.passwordConfirmation && errors.passwordConfirmation
                )}
                helperText={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Documents</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                style={{ marginTop: 15 }}
                options={applying}
                autoHighlight
                getOptionLabel={(option) => option.title}
                onChange={onApplyingAs}
                name="applyingAs"
                value={values.applyingValues}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Applying as"
                    name="applyingAs"
                    error={Boolean(touched.applyingAs && errors.applyingAs)}
                    helperText={touched.applyingAs && errors.applyingAs}
                    value={values.applyingAs}
                    onBlur={handleBlur}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            {values.companyCountry === 'Indonesia' ||
            values.companyCountry === '' ? (
              <Grid sm={6} item>
                <TextField
                  label="NPWP"
                  // type="number"
                  name="npwp"
                  margin="normal"
                  fullWidth
                  error={Boolean(touched.npwp && errors.npwp)}
                  helperText={touched.npwp && errors.npwp}
                  value={values.npwp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            ) : null}
          </Grid>
          {values.companyCountry === 'Indonesia' ||
          values.companyCountry === '' ? (
            <div>
              <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid sm={6} item>
                  <UploadDocument
                    name="npwpFile"
                    value={values.npwpFile}
                    onImageChange={setFieldValue}
                    error={touched.npwpFile && errors.npwpFile}
                    label="NPWP Documents"
                    type="npwp"
                    // setFieldError={setFieldError}
                  />
                </Grid>
                <Grid sm={6} item>
                  <UploadDocument
                    name="izinEkspor"
                    value={values.izinEkspor}
                    onImageChange={setFieldValue}
                    error={touched.izinEkspor && errors.izinEkspor}
                    label="Surat Izin Ekspor"
                    type="suratIzinEkspor"
                    // setFieldError={setFieldError}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid sm={6} item>
                  <UploadDocument
                    name="nib"
                    value={values.nib}
                    onImageChange={setFieldValue}
                    error={touched.nib && errors.nib}
                    label="NIB (Nomor Induk Berusaha)"
                    // state={val => state.setNib(val)}
                    type="nib"
                    setFieldError={setFieldError}
                  />
                </Grid>
                <Grid sm={6} item>
                  <UploadDocument
                    name="siup"
                    value={values.siup}
                    onImageChange={setFieldValue}
                    error={touched.siup && errors.siup}
                    label="SIUP"
                    // state={val => state.setSiup(val)}
                    type="siup"
                    setFieldError={setFieldError}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid sm={6} item>
                  <UploadOthersDocument
                    name="others"
                    value={
                      values.others && values.others[0] === null
                        ? []
                        : values.others
                    }
                    onImageChange={setFieldValue}
                    error={errors.others}
                    multiple
                    label="Other Documents (Optional)"
                    typeFile="application/pdf"
                    //  max={1}
                    type="others"
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid sm={6} item>
                  <UploadDocument
                    name="npwpFile"
                    value={values.npwpFile}
                    onImageChange={setFieldValue}
                    error={touched.npwpFile && errors.npwpFile}
                    label="Tax Number"
                    type="npwp"
                  />
                </Grid>
                <Grid sm={6} item>
                  <UploadDocument
                    name="nib"
                    value={values.nib}
                    onImageChange={setFieldValue}
                    error={touched.nib && errors.nib}
                    label="Proof of Business Ownership"
                    // state={val => state.setPobo(val)}
                    type="proof_business_ownership"
                    setFieldError={setFieldError}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid sm={6} item>
                  <UploadOthersDocument
                    name="others"
                    value={
                      values.others && values.others[0] === null
                        ? []
                        : values.others
                    }
                    onImageChange={setFieldValue}
                    error={errors.others}
                    multiple
                    label="Other Documents (Optional)"
                    //  max={1}
                    type="others"
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </Board>
    </div>
  );
};

const FormikCreate = withFormik(
  Create,
  registerSchemeValidations,
  initialValueForm,
  handleSubmit
);

const mapStateToProps = (state) => ({
  data: state.module.list,
  userRoles: state.module.view.userRoles,
});

export default connect(mapStateToProps, { signUp })(FormikCreate);
