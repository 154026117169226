import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  CircularProgress,
  Button,
} from '@material-ui/core';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const Confirm = (props) => {
  const { CustomContent } = props;
  const style =
    props.handleContent &&
    props.handleContent.button &&
    props.handleContent.button.style
      ? props.handleContent.button.style
      : {};

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.handleClose}
      className={props.loading ? 'confirm-no-root' : 'confirm-root'}
    >
      {props.loading ? (
        <div className="confirm-loading-root">
          <CircularProgress
            className="confirm-loading-item"
            thickness={3}
            size={60}
          />
        </div>
      ) : (
        <div className="confirm-root">
          <div className="confirm-head">
            <DialogTitle className="confirm-typo-head">
              {props.handleContent.title || 'Warning'}
            </DialogTitle>
          </div>

          {CustomContent ? (
            <CustomContent />
          ) : (
            <DialogContent className="confirm-content">
              <DialogContentText className="confirm-message">
                {props.handleContent.message || 'Warning'}
              </DialogContentText>
            </DialogContent>
          )}

          <DialogActions>
            <Button
              className="mr-2"
              onClick={props.handleClose}
              style={{
                border: '1px solid #E45858',
                color: '#E45858',
                fontSize: 14,
                textTransform: 'capitalize',
                borderRadius: 8,
                padding: 10,
                marginBottom: 6,
                width: 100,
              }}
            >
              Cancel
            </Button>
            <Button
              className="mr-2"
              style={{
                fontSize: 14,
                textTransform: 'capitalize',
                borderRadius: 8,
                padding: 10,
                marginRight: 20,
                marginBottom: 6,
                ...style,
              }}
              color="primary"
              onClick={props.handleAction}
            >
              {(props.handleContent &&
                props.handleContent.button &&
                props.handleContent.button.title) ||
                'Yes'}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default Confirm;
