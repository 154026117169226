import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from 'redux/actions';
import Header from './components/Header';
import Footer from './components/Footer';
import { userType } from 'data';
import { ForgotSchemeValidations } from './schemeValidations';
import withFormik from 'utils/withFormik';
import history from 'utils/history';

const initialValueForm = {
  userTypes: '',
  email: '',
  userTypesValues: {},
};

const ForgotPassword = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    // handleSubmit,
    isValid,
    // dirty,
    setFieldValue,
    // data,
  } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.oauth);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = () => {
    // setIsSuccess(true);
    if (isValid) {
      dispatch(
        forgotPassword({
          type: values.userTypes.toLowerCase(),
          email: values.email,
        })
      );
    }
  };

  useEffect(() => {
    if (user && user.message === 'OK') {
      setIsSuccess(true);
    }
  }, [user]);

  const onUserTypeChange = (event, values) => {
    setFieldValue('userTypes', values && values.title);
    setFieldValue('userTypesValues', values && values);
    // console.log(errors.country, values && values.label);
  };

  const onSuccess = () => {
    history.push('/signin');
  };

  return (
    <div className="app-wrapper auth-page">
      <Header />

      {/* <img className="bg-item-left" src={BgItemLeft} alt="bg-item" /> */}
      {/* <img className="bg-item-right" src={BgItemRight} alt="bg-item" /> */}

      <Container>
        <Row>
          <Col lg={6} md={8} className="offset-lg-3 offset-md-2">
            {!isSuccess ? (
              <div className="card">
                <div className="title-desc">
                  <h3>Forgot your password?</h3>
                  <p className="mb-30">
                    Please enter the email address associated with your
                    INA-ACCESS account. You will receive an email with
                    instructions to reset your password.
                  </p>
                </div>
                <h3 className="title">Enter your details</h3>
                <Autocomplete
                  id="country-select-demo"
                  name="userTypes"
                  style={{ marginTop: 30 }}
                  options={userType}
                  value={values.userTypesValues}
                  onChange={onUserTypeChange}
                  autoHighlight
                  getOptionLabel={(option) => option.title}
                  renderOption={(option) => (
                    <React.Fragment>{option.title}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User Type"
                      variant="outlined"
                      name="country"
                      // error={true}
                      error={Boolean(touched.userTypes && errors.userTypes)}
                      helperText={touched.userTypes && errors.userTypes}
                      value={values.userTypes}
                      onBlur={handleBlur}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <TextField
                  label="Email Address"
                  type="email"
                  name="email"
                  margin="normal"
                  onChange={handleChange}
                  value={values.email}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
                <div className="btn-wrap">
                  <Button
                    disabled={!isValid}
                    onClick={handleSubmit}
                    className={isValid ? 'button-disabled' : 'button'}
                  >
                    Reset Password
                  </Button>
                </div>
                <div className="offer">
                  Or login to dashboard instead <Link to="/login">here</Link>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="title-desc">
                  <h3>Please check your inbox</h3>
                  <p>
                    A reset password email has been sent to your inbox. Please
                    click on the link provided in the email to reset your
                    password.
                  </p>
                </div>
                <div className="btn-wrap">
                  <button onClick={onSuccess} className="button-back">
                    Back to Homepage
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};
const FormikForgot = withFormik(
  ForgotPassword,
  ForgotSchemeValidations,
  initialValueForm,
  null
);
export default FormikForgot;
