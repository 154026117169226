import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { TextField, Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { load, remove } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const View = () => {
  const { id } = useParams();

  const { tourism } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'tourism/request/item',
        id: id,
        customName:'tourism'
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = tourism && tourism.data;

  return (
    <div className="app-wrapper appointment-page">
      <ContainerHeader
        title="View Toursim Inquiry"
        breadcrumb={[
          // { link: '/tourism', label: 'Appointment' },
          { link: '/tourism', label: 'Tourism' },
          { link: `/tourism/view/${id}`, label: 'View Inquiry' },
        ]}
      />

      <Board
        actions={[
          {
            label: 'Delete Inquiry',
            onClick: () => {
              dispatch(
                remove({
                  name: 'tourism/request/item',
                  id: id,
                  customName: 'tourism',
                })
              );
            },
            confirm: true,
            confirmButton: {
              title: 'Delete',
              style: {
                color: '#fff',
                background: '#E14040',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Contact',
            confirmMessage: 'Are you sure you want to delete the tourism contact?',
            style: {
              color: '#E45858',
              border: '1px solid #E45858',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Submitted at{' '}
            {moment(value && value.createdAt).format('DD MMM YYYY')}
          </p>
          <Typography className="title">Details</Typography>
        </div>

        <div className="section">
          <Typography className="sub-title">Sender Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="fullName"
                label="Full Name"
                margin="normal"
                value={value?.fullName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="gender"
                label="Gender"
                margin="normal"
                value={_.capitalize( value?.gender)}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="email"
                label="Email Address"
                margin="normal"
                value={value?.email}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="phoneNumber"
                label="Phone Number"
                margin="normal"
                value={value?.phone}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            </Grid>
            
            <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="country"
                label="Country"
                margin="normal"
                value={value?.countryName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          
          </Grid>
        </div>

       
        <div className="section">
          <Typography className="sub-title">Message</Typography>
          
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                multiline
                rows={4}
                variant="outlined"
                name="message"
                label="Message"
                margin="normal"
                value={value && value.message}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>

     
    </div>
  );
};

export default View;
