import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { connect } from 'react-redux';
import { createPlenarySchemeValidation } from './schemeValidations';
import UploadImage from 'components/Upload/UploadImage';
import withFormik from 'utils/withFormik';
import { add, list as listRedux } from 'redux/actions/Module';
// import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';
// import TimePicker from '@mui/lab/TimePicker';
import UploadDocument from 'components/Upload/UploadDocument';
import { uploadBase64Image } from 'utils/uploadMedia';

const initialValueForm = {
  status: { value: 'draft', title: 'Draft' },
  title: '',
  eventDetail: '',
  excerpt: '',
  contact: '',
  category: '',
  meetingLink: '',
  thumbnail: [],
  banner: [],
  eventDate: '',
  startTime: '',
  endTime: '',
  agenda: '',
};

const handleSubmitForm = (payload, ctx) => {
  const {
    status,
    title,
    eventDetail,
    excerpt,
    contact,
    category,
    meetingLink,
    thumbnail,
    banner,
    eventDate,
    startTime,
    endTime,
    agenda,
  } = payload;

  const imgRegex = new RegExp(/data:image\/[^;]+;base64[^"]+/g)
  const imgSrc = payload.eventDetail.match(imgRegex) || []

  Promise.all(imgSrc.map(src => 
    new Promise( async (resolve) => {
      const res = await uploadBase64Image(src, 'others')
      resolve(res)
    })
  )).then(values => {
    for (let i = 0; i < values.length; i++) {
      if (values[i]?.status === 200) {
        payload.eventDetail = payload.eventDetail.replace(imgSrc[i], values[i].data.originalUrl)
      }
    }
    
    ctx.props.add({
      name: `events`,
      data: {
        title,
        description: eventDetail,
        excerpt,
        thumbnailImage: thumbnail.toString(),
        bannerImage: banner.toString(),
        status: status.value,
        category,
        date: eventDate,
        startTime,
        endTime,
        contact,
        agenda,
        meetingLink,
      },
      notLink: true,
      onSuccess: (res) => {
        ctx.props.history.push(`/plenary-sessions`);
      },
    });
  }).catch(e => {
    console.error('Error occured:', e.message)
  })
};

const CreateEvent = (props) => {
  const history = useHistory();
  // const dispatch = useDispatch();
  // const { list } = useSelector((state) => state.module);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    setFieldError,
  } = props;

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Add Event"
        breadcrumb={[
          { link: '/plenary-sessions', label: ' Sessions' },
          { link: '/plenary-sessions/create', label: 'Add Event' },
        ]}
      />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Add Event',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Add Event',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={values.status}
                onChange={(e, val) => setFieldValue('status', val)}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Event Information</Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="title"
                label="Title"
                margin="normal"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="eventDetail"
                label="Event Details"
                value={values.eventDetail}
                onChange={(val) =>
                  setFieldValue('eventDetail', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'eventDetail' } })}
                error={Boolean(touched.eventDetail && errors.eventDetail)}
                helperText={touched.eventDetail && errors.eventDetail}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                label="Excerpt"
                name="excerpt"
                multiline
                row={5}
                variant="outlined"
                required
                fullWidth
                value={values.excerpt}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.excerpt && errors.excerpt)}
                helperText={touched.excerpt && errors.excerpt}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="contact"
                label="Contact"
                margin="normal"
                value={values.contact}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.contact && errors.contact)}
                helperText={touched.contact && errors.contact}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <FormControl
                variant="outlined"
                fullWidth
                size="medium"
                margin="normal"
              >
                <InputLabel>Category</InputLabel>
                <Select
                  value={values.category}
                  name="category"
                  label="Category"
                  onChange={(event) =>
                    setFieldValue('category', event.target.value)
                  }
                  onBlur={handleBlur}
                  error={Boolean(touched.category && errors.category)}
                >
                  {[
                    { value: 'event', label: 'Event' },
                    { value: 'plenary', label: 'Plenary' },
                    { value: 'panel', label: 'Panel' },
                    { value: 'business_matching', label: 'Business Matching' }
                  ].map((value, index) => (
                    <MenuItem value={value.value}>{value.label}</MenuItem>
                  ))}
                </Select>
                {Boolean(touched.category && errors.category) && (
                  <FormHelperText htmlFor="render-select" error>
                    {touched.category && errors.category}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="meetingLink"
                label="Meeting Link"
                margin="normal"
                value={values.meetingLink}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.meetingLink && errors.meetingLink)}
                helperText={touched.meetingLink && errors.meetingLink}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="date"
                variant="outlined"
                name="eventDate"
                label="Event Date"
                margin="normal"
                value={values.eventDate}
                error={Boolean(touched.eventDate && errors.eventDate)}
                helperText={touched.eventDate && errors.eventDate}
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{
                  min: moment(new Date()).format('YYYY-MM-DD'),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="time"
                variant="outlined"
                name="startTime"
                label="Start Time"
                margin="normal"
                value={values.startTime}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.startTime && errors.startTime)}
                helperText={touched.startTime && errors.startTime}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="time"
                variant="outlined"
                name="endTime"
                label="End Time"
                margin="normal"
                value={values.endTime}
                error={Boolean(touched.endTime && errors.endTime)}
                helperText={touched.endTime && errors.endTime}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography className="title" style={{ marginBottom: 16 }}>
                Thumbnail
              </Typography>

              <UploadImage
                name="thumbnail"
                value={values.thumbnail}
                onImageChange={(val) => setFieldValue('thumbnail', val)}
                error={errors.thumbnail}
                maxFileSize={500}
                multiple
                max={1}
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 5mb
                </Typography>

                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended thumbnail size is 320px x 200px (.jpg, .jpeg, and
                  .png)
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography className="title" style={{ marginBottom: 16 }}>
                Banner
              </Typography>

              <UploadImage
                name="banner"
                value={values.banner}
                onImageChange={(val) => setFieldValue('banner', val)}
                error={errors.banner}
                multiple
                maxFileSize={500}
                max={1}
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 5mb
                </Typography>

                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended banner size is 720px x 480px (.jpg, .jpeg, and
                  .png)
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid sm={6} item>
          <UploadDocument
            name="agenda"
            value={values.agenda}
            onImageChange={setFieldValue}
            error={touched.agenda && errors.agenda}
            label="Agenda (Optional)"
            // state={val => state.setSiup(val)}
            type="others"
            setFieldError={setFieldError}
          />
        </Grid>
      </Board>
    </div>
  );
};

const FormikCreate = withFormik(
  CreateEvent,
  createPlenarySchemeValidation,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
});

export default connect(mapStateToProps, { add, listRedux })(FormikCreate);
