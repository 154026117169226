import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import AppLocale from '../lngProvider';
import Routes from '../routes';
import Notif from '../components/Frame';
import blueTheme from './themes/blueTheme';

const App = (props) => {
  const { themeColor, locale } = useSelector(({ settings }) => settings);

  document.body.classList.add(themeColor);

  let applyTheme = createMuiTheme(blueTheme);

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <LocalizationProvider
        dateLibInstance={moment}
        dateAdapter={MomentAdapter}
      >
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <div className="app-main">
            <Routes />
            <Notif />
          </div>
        </IntlProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default withRouter(App);
