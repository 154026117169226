import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { load } from "redux/actions/Module";
import {
	InputAdornment,
	IconButton,
	Grid,
	Card,
	Typography,
	TextField,
	CardContent,
	CardActions,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Tables from "./components/Tables";
import ImageIl from "assets/illustration/home-image.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "utils/localStorage";
import _ from "lodash";
import OverviewCard from "./components/OverviewCard";

const Home = () => {
	const [keyword, setKeyword] = useState("");
	const history = useHistory();

	const { view } = useSelector((state) => state.module);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			load({
				name: "statistic",
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const statistic = view && view.statistic && view.statistic.data;
	return (
		<div className="app-wrapper">
			<Grid container spacing={3}>
				<Grid item container>
					<Grid item lg>
						<div className="container-content">
							<div className="text-content">
								<h3 className="title">
									Welcome, {_.toUpper(getUser()?.user?.name)}
								</h3>
								{/* <div>
                  <h4>Current Active Business Forum:</h4>
                  <button className="btn">INA-CEE</button>
                </div> */}
							</div>
							<img src={ImageIl} alt="welcome img" />
						</div>
					</Grid>
				</Grid>

				<Grid item container direction="row" justify="space-between">
					<Typography style={{ fontSize: 32 }}>Overview</Typography>
				</Grid>

				<Grid item container spacing={5}>
					<Grid item lg={3}>
						<OverviewCard
							onClick={() => history.push("/exhibitors")}
							title="Total Exhibitors"
							total={statistic && statistic.exhibitor}
						/>
					</Grid>
					<Grid item lg={3}>
						<OverviewCard
							onClick={() => history.push("/exhibitors")}
							title="Approved Exhibitors"
							total={statistic && statistic.approvedExhibitor}
						/>
					</Grid>
					<Grid item lg={3}>
						<OverviewCard
							onClick={() => history.push("/visitors")}
							title="Total Visitors"
							total={statistic && statistic.visitor}
						/>
					</Grid>
					<Grid item lg={3}>
						<OverviewCard
							onClick={() => history.push("/products")}
							title="Total  Products"
							total={statistic && statistic.product}
						/>
					</Grid>
				</Grid>

				<Grid item container>
					<Grid item lg>
						<Card className="list-booking-home">
							<CardContent className="card-content">
								<h3 className="title-card-list-booking">
									List of New Exhibitor Application
								</h3>
								<Grid item lg={4}>
									<TextField
										name="search"
										variant="outlined"
										size="small"
										margin="normal"
										onChange={(e) => setKeyword(e.target.value)}
										placeholder="Search for a company..."
										fullWidth
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<IconButton>
														<SearchIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<div style={{ marginTop: 20 }}>
									<Tables search={keyword} />
								</div>
							</CardContent>
							<CardActions className="footer-card-list-booking">
								<div className="view-all">
									<h3
										onClick={() => history.push("/exhibitor-applications")}
										className="text-footer-card"
									>
										View All
									</h3>
								</div>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Home;
