import * as Yup from 'yup';

const schemeValidations = Yup.object().shape({
  eventName: Yup.string().required('Event name can not be left blank'),
  eventStartDate: Yup.date().default(() => new Date()),
  eventEndDate: Yup.date().min(
    Yup.ref('eventStartDate'),
    "End date can't be before Start date"
  ),
  status: Yup.string().required('Status must be selected')
});

export default schemeValidations;

