import React, { useState,useEffect } from 'react';
import { Button } from '@material-ui/core';
import Upload from './Upload';
import { getUser } from 'utils/localStorage';
const CompanyBanner = (props) => {
  const { companyDetail, updated } = props;
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState('');
  useEffect(() => {
		if (getUser()?.user) {
		  setUser(getUser()?.user);
		}
	  }, []);
  const handleCloseModal = (res) => {
    setOpenModal(false);
    updated(res);
  };
  const website =
    companyDetail?.companyWebsite?.includes('https://') ||
    companyDetail?.companyWebsite?.includes('http://')
      ? companyDetail?.companyWebsite
      : 'https://' + companyDetail?.companyWebsite;
  return (
    <div
      style={{
        marginTop: -100,
        borderTop: '14px solid #1c6ea4',
        borderRadius: 20,
        background: `linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff`,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
        display: 'grid',
        gridTemplateColumns: '279px 395px 524px',
        gridTemplateRows: 'repeat(2,120px)',
      }}
    >
      <div
        style={{
          gridArea: '1 / 1 / 3 / 2',
          width: 180,
          height: 180,
          margin: '30px 45px 30px 54px',
        }}
      >
        <img
          alt="company"
          style={{
            width: 134,
            height: 134,
            margin: 20,
            marginLeft: 23,
          }}
          src={companyDetail?.companyLogo}
        />
      </div>
      <div
        style={{
          gridArea: '1 / 2 / 2 / 3',
          textAlign: 'left',
        }}
      >
        <h2
          style={{
            margin: '40px 0px 8px 0px',
          }}
        >
          {companyDetail?.companyName}
        </h2>
        <p>
          <i
            style={{ fontSize: '24px', color: '#0a5379' }}
            className="fa fa-map-marker"
          ></i>
          {companyDetail?.country} <span style={{ color: '#949494' }}>|</span>{' '}
          {companyDetail?.establishment}
        </p>
      </div>
      <div
        style={{
          gridArea: '2 / 2 / 3 / 4',
          marginTop: 15,
        }}
      >
        <ul
          style={{
            marginLeft: -40,
            listStyle: 'none',
          }}
        >
          <li
            style={{
              marginRight: 20,
              float: 'left',
              textAlign: 'left',
              borderCollapse: 'separate',
              borderRight: '0.5px solid #949494',
              paddingRight: 20,
            }}
          >
            <p>Website</p>
            <a target="_blank" rel="noopener noreferrer" href={`${website}`}>
              {companyDetail?.companyWebsite}
            </a>
          </li>
          <span></span>
          <li
            style={{
              marginRight: 20,
              float: 'left',
              textAlign: 'left',
              borderCollapse: 'separate',
              borderRight: '0.5px solid #949494',
              paddingRight: 20,
            }}
          >
            <p>Business Sector</p>
            <span>{companyDetail?.sector}</span>
          </li>
          <li
            style={{
              marginRight: 20,
              float: 'left',
              textAlign: 'left',
              borderCollapse: 'separate',
              borderRight: '0.5px solid #949494',
              paddingRight: 20,
            }}
          >
            <p>Email Address</p>
            <span>{companyDetail?.email}</span>
          </li>
          <li
            style={{
              marginRight: 0,
              float: 'left',
              textAlign: 'left',
              borderCollapse: 'separate',
              borderRight: '0.5px solid #949494',
              border: 'none',
              paddingRight: 20,
            }}
          >
            <p>Contact Number</p>
            <span>
              {companyDetail &&
                companyDetail.companyPhoneNumber &&
                companyDetail.companyPhoneNumber.slice(0, -4) + '****'}
            </span>
          </li>
        </ul>
      </div>
      <div
        style={{
          gridArea: '3 / 11 / 3 / 2',
          gridRowStart: '3',
          gridRowEnd: '3',
          gridColumnStart: '4',
          gridColumnEnd: '3',
          marginBottom: 20,
          marginLeft: 210,
        }}
      >
         {!user?.roles?.includes('perwakilan') && (
        <Button
          style={{
            border: '1px solid #ED901A',
            borderRadius: 8,
            color: '#FFF',
            backgroundColor: '#ED901A',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => setOpenModal(true)}
        >
          Edit Exhibitor
        </Button> )}
      </div>
      {openModal && (
        <Upload
          open={openModal}
          handleOpen={handleCloseModal}
          companyDetail={companyDetail}
        />
      )}
    </div>
  );
};

export default CompanyBanner;
