import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { connect, useDispatch, useSelector } from 'react-redux';
import { productSchemeValidations } from '../schemeValidation';
import UploadImage from 'components/Upload/UploadImage';
import withFormik from 'utils/withFormik';
import { add, list as listRedux, load } from 'redux/actions/Module';
import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';

const initialValueForm = {
  status: { value: 'draft', title: 'Draft' },
  slug: '',
  productName: '',
  category: '',
  productDesc: '',
  sku: '',
  dimension: '',
  image: [],
};

const handleSubmitForm = (payload, ctx) => {
  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : ctx.props.match.params.exhibitorId;

  const {
    productName,
    category,
    productDesc,
    sku,
    dimension,
    image,
    status,
  } = payload;
  ctx.props.add({
    name: `exhibitor/${exhibitorId}/products`,
    data: {
      name: productName,
      sector: category,
      description: productDesc,
      SKU: sku,
      dimension: dimension,
      images: image,
      status: status.value,
    },
    notLink: true,
    onSuccess: (res) => {
      ctx.props.history.push(`/products/${res.data.slug}`);
    },
  });
};

const CreateProduct = (props) => {
  const history = useHistory();
  const slug = useParams().exhibitorId;
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.module);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : slug;

  const { exhibitor } = useSelector((state) => state.module.view);

  useEffect(() => {
    dispatch(
      listRedux({
        name: `exhibitor/${exhibitorId}/category`,
        customName: 'category',
      })
    );

    dispatch(
      load({
        name: `exhibitor/${exhibitorId}`,
        customName: 'exhibitor',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitorId]);

  const onCategoryChange = (event, values) => {
    setFieldValue(
      'category',
      values && values.name !== null ? values.name : ''
    );
    setFieldValue('categoryValues', values && values !== null ? values : {});
  };

  const breadcrumbAdmin = [
    { link: '/exhibitors', label: 'All Exhibitors' },
    { link: `/exhibitors/${exhibitorId}`, label: 'View Exhibitor' },
    { link: '', label: 'Add Product' },
  ];
  const breadcrumbExhibitor = [
    { link: '/my-company', label: 'My Company' },
    { link: '', label: 'Add Product' },
  ];
  const breadcrumb = getUser()?.user?.roles.includes('administrator')
    ? breadcrumbAdmin
    : getUser()?.user?.roles.includes('exhibitor')
    ? breadcrumbExhibitor
    : [];

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader title="Add Product" breadcrumb={breadcrumb} />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Add Product',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Add Product',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={values.status}
                onChange={(e, val) => setFieldValue('status', val)}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Product Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="productName"
                label="Product Name"
                margin="normal"
                value={values.productName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.productName && errors.productName)}
                helperText={touched.productName && errors.productName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <Autocomplete
                name="category"
                style={{ marginTop: 15 }}
                options={list && list.category && list.category.data}
                value={values.categoryValues}
                onChange={onCategoryChange}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    name="category"
                    // error={true}
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                    value={values.category}
                    onBlur={handleBlur}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="productDesc"
                label="Product Description"
                value={values.productDesc}
                onChange={(val) =>
                  setFieldValue('productDesc', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'productDesc' } })}
                error={Boolean(touched.productDesc && errors.productDesc)}
                helperText={touched.productDesc && errors.productDesc}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="exhibitor"
                label="Exhibitor"
                margin="normal"
                value={
                  exhibitor && exhibitor.data && exhibitor.data.companyName
                }
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                variant="outlined"
                name="sku"
                label="HS Code"
                margin="normal"
                value={values.sku}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.sku && errors.sku)}
                helperText={touched.sku && errors.sku}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="dimension"
                label="Dimension"
                margin="normal"
                value={values.dimension}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.dimension && errors.dimension)}
                helperText={touched.dimension && errors.dimension}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography className="title" style={{ marginBottom: 16 }}>
                Image
              </Typography>

              <UploadImage
                name="image"
                value={values.image}
                onImageChange={(val) => setFieldValue('image', val)}
                error={errors.image}
                multiple
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 10mb
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  You can upload up to 5 pictures
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Accepted file format is .jpg, .jpeg, and .png. We recommend
                  isolated product photo against a white background.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

const FormikCreate = withFormik(
  CreateProduct,
  productSchemeValidations,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
});

export default connect(mapStateToProps, { add, listRedux })(FormikCreate);
