import React from 'react';
import { useHistory } from 'react-router-dom';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';

const Index = () => {
  const history = useHistory();

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Banners"
        breadcrumb={[{ link: '/banners', label: 'Banners' }]}
      />

      <Tables
        title="All Pages"
        name="banner/page"
        minWidth={1200}
        viewDetail={{ name: 'banners', by: 'slug' }}
        columnHead={[
          { id: 'page', label: 'Page' },
          { id: '', label: 'Action' },
        ]}
        disableFooter
        columnBody={[
          { parent: 'page' },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/banners/page/${n.slug}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                  fontSize: 12,
                }}
              >
                View
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Index;
