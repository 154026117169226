import Immutable from 'seamless-immutable';

const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
const DRAWER_TYPE = 'drawer_type';
const FIXED_DRAWER = 'fixed_drawer';
const THEME_COLOR = 'theme_color';
const WINDOW_WIDTH = 'window-width';
const SWITCH_LANGUAGE = 'switch-language';
const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';

const INDIGO = 'indigo';
const CYAN = 'cyan';
const AMBER = 'amber';
const DEEP_ORANGE = 'deep-orange';
const PINK = 'pink';
const BLUE = 'blue';
const DEEP_PURPLE = 'deep-purple';
const GREEN = 'green';
const DARK_INDIGO = 'dark-indigo';
const DARK_CYAN = 'dark-cyan';
const DARK_AMBER = 'dark-amber';
const DARK_DEEP_ORANGE = 'dark-deep-orange';
const DARK_PINK = 'dark-pink';
const DARK_BLUE = 'dark-blue';
const DARK_DEEP_PURPLE = 'dark-deep-purple';
const DARK_GREEN = 'dark-green';
const DARK_THEME_COLOR = 'dark-theme';

// Craete Immutable object
const actionTypes = Immutable({
  TOGGLE_COLLAPSED_NAV,
  DRAWER_TYPE,
  FIXED_DRAWER,
  THEME_COLOR,
  WINDOW_WIDTH,
  SWITCH_LANGUAGE,
  HORIZONTAL_MENU_POSITION,
  // colors
  INDIGO,
  CYAN,
  AMBER,
  DEEP_ORANGE,
  PINK,
  BLUE,
  DEEP_PURPLE,
  GREEN,
  DARK_INDIGO,
  DARK_CYAN,
  DARK_AMBER,
  DARK_DEEP_ORANGE,
  DARK_PINK,
  DARK_BLUE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_THEME_COLOR,
});

export default actionTypes;

export function toggleCollapsedNav(isNavCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function setDrawerType(drawerType) {
  return { type: DRAWER_TYPE, drawerType };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeColor(color) {
  return { type: THEME_COLOR, color };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition,
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale,
  };
}
