import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';
import { edit, load } from 'redux/actions';
import UploadImage from 'components/Upload/UploadImage';
import TextEditor from 'components/Form/TextEditor';
import _ from 'lodash';
import { uploadBase64Image } from 'utils/uploadMedia';

const initialValueForm = {
  status: '',
  title: '',
  body: '',
  excerpt: '',
  thumbnail: [''],
  banner: [''],
  slug: '',
  publishedDate: '',
  category: '',
};

const mapPropsToValues = ({ news }) => {
  if (news && news.data) {
    const data = news.data;
    return {
      status: {
        value: data?.status,
        title: _.capitalize(data?.status),
      },
      title: data?.title,
      body: data?.content,
      excerpt: data?.excerpt,
      thumbnail: [data?.thumbnailImage],
      banner: [data?.bannerImage],
      publishedDate: data?.publishedDate,
      category: {
        title: data?.category?.categoryName,
        value: data?.category?.id,
      },
    };
  }
  return initialValueForm;
};

const handleFormSubmit = async (payload, ctx) => {
  const { newsId } = ctx.props.match.params;
  let data = payload;
  const imgRegex = new RegExp(/data:image\/[^;]+;base64[^"]+/g)
  const imgSrc = data.body.match(imgRegex) || []
  
  Promise.all(imgSrc.map(src => 
    new Promise( async (resolve) => {
      const res = await uploadBase64Image(src, 'others')
      resolve(res)
    })
  )).then(values => {
    for (let i = 0; i < values.length; i++) {
      if (values[i]?.status === 200) {
        data.body = data.body.replace(imgSrc[i], values[i].data.originalUrl)
      }
    }

    ctx.props.edit({
      name: 'news',
      id: newsId,
      data: {
        status: data.status.value,
        title: data.title,
        content: data.body,
        excerpt: data.excerpt,
        thumbnailImage: data.thumbnail[0],
        bannerImage: data.banner[0],
        publishedDate: data.publishedDate,
        category: data.category.value,
      },
      onSuccess: (res) => {
        ctx.props.history.push(`/press-releases/${res.data.id}`);
      },
      noLink: true,
    });
  }).catch(e => {
    console.error('Error occured:', e.message)
  })
};

const Edit = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  const history = useHistory();
  const { newsId } = useParams();
  const { categories, news } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'news',
        id: newsId,
      })
    );

    dispatch(
      load({
        name: 'news/category?status=active',
        customName: 'categories',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listCategories = categories?.data?.map((n) => ({
    value: n.id,
    title: n.categoryName,
  }));

  const view = news?.data;

  return (
    <div className="app-wrapper news-page">
      <ContainerHeader
        title="Edit Press Release"
        breadcrumb={[
          { link: '/press-releases', label: 'Press Release' },
          { link: '', label: 'Edit Press Release' },
        ]}
      />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                border: '1px solid #1AB759',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Save Changes',
            confirmMessage: 'Do you wish to add this?',
            onClick: () => handleSubmit(),
            style: {
              width: 148,
              color: '#fff',
              background: '#1AB759',
              border: '1px solid #1AB759',
            },
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#E14040',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Cancel Edit Press Release',
            confirmMessage: 'Do you want to cancel editing your Press Release?',
            onClick: () => history.goBack(),
            style: {
              width: 130,
              color: '#E45858',
              border: '1px solid #E45858',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Updated at {moment(view?.modifiedAt).format('DD MMMM YYYY')}
          </p>
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMMM YYYY')}
          </p>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={values.status}
                onChange={(e, val) => setFieldValue('status', val)}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Press Release Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="title"
                label="Title"
                margin="normal"
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="body"
                label="Body"
                value={values.body}
                onChange={(val) =>
                  setFieldValue('body', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'body' } })}
                error={Boolean(touched.body && errors.body)}
                helperText={touched.body && errors.body}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                multiline
                rows={2}
                variant="outlined"
                name="excerpt"
                label="Excerpt"
                margin="normal"
                error={Boolean(touched.excerpt && errors.excerpt)}
                helperText={touched.excerpt && errors.excerpt}
                value={values.excerpt}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Autocomplete
                name="category"
                options={listCategories || []}
                value={values.category}
                onChange={(e, val) => setFieldValue('category', val)}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    name="category"
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                    value={values.category}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography
                className="title"
                style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}
              >
                Thumbnail
              </Typography>
              <UploadImage
                name="thumbnail"
                value={values.thumbnail}
                onImageChange={(val) => setFieldValue('thumbnail', val)}
                error={errors.thumbnail}
                multiple
                max={1}
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 10mb
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended thumbnail size is 320px x 200px (.jpg, .jpeg, and
                  .png)
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography
                className="title"
                style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}
              >
                Banner
              </Typography>
              <UploadImage
                name="banner"
                value={values.banner}
                onImageChange={(val) => setFieldValue('banner', val)}
                error={errors.banner}
                max={1}
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 10mb
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended banner size is xxxpx x xxxpx (.jpg, .jpeg, and
                  .png)
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="date"
                variant="outlined"
                name="publishedDate"
                label="Published Date"
                margin="normal"
                value={moment(values.publishedDate).format('YYYY-MM-DD')}
                error={Boolean(touched.publishedDate && errors.publishedDate)}
                helperText={touched.publishedDate && errors.publishedDate}
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{
                  min: moment(new Date()).format('YYYY-MM-DD'),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

const mapStateToProps = (state) => ({
  news: state.module.view.news,
});

const mapDispatchToProps = {
  edit,
};

const FormikEdit = withFormik(
  Edit,
  schemeValidations,
  mapPropsToValues,
  handleFormSubmit
);

export default connect(mapStateToProps, mapDispatchToProps)(FormikEdit);
