import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load } from 'redux/actions/Module';
// import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';

const ViewRunningText = (props) => {
  const history = useHistory();
  const { page } = useParams();
  const dispatch = useDispatch();
  const { runningText } = useSelector((state) => state.module.view);

  useEffect(() => {
    dispatch(
      load({
        name: 'running-text',
        id: `${page}`,
        customName: 'runningText',
      })
    );

    // eslint-disable-next-line
  }, [page]);

  const view = runningText?.data;

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="View Running Text"
        breadcrumb={[
          { link: '/running-text', label: 'Running Text' },
          { link: `/running-text/${page}`, label: 'View Running Text' },
        ]}
      />
      <Board
        actions={[
          {
            label: 'Edit Running Text',
            confirm: false,
            style: {
              background: '#ED901A',
              color: '#fff',
              border: '1px solid #ED901A',
              padding: '8px 17px',
            },
            onClick: () => history.push(`/running-text/${page}/edit`),
          },
        ]}
      >
        <div className="section">
          <Typography className="title" style={{ marginBottom: 16 }}>
            Running Text Details
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="content"
                label="Content"
                value={view?.text || ''}
                readOnly
              />
              {/* <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="content"
                label="Content"
                margin="normal"
                value={view?.text !== null ? view?.text : ''}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

export default ViewRunningText;
