import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Oauth from './Oauth';
import Module from './Module';
import Global from './Global';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    oauth: Oauth,
    module: Module,
    global: Global,
  });
