import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { edit, remove } from 'redux/actions/Module';
import Confirm from 'components/Board/Confirm';
import { connect, useDispatch } from 'react-redux';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';

const initialValueForm = {
  sectorName: 'asdasd',
  status: 'Active',
};

const handleSubmit = (payload, ctx) => {
  const { sectorName, status, id } = payload;
  ctx.props.edit({
    name: 'sector/sectorcms',
    id,
    data: {
      name: sectorName,
      status: status === 'Active' ? true : false,
    },
    noLink: true,
    onSuccess: (res) => {
      ctx.props.handleOpen(false);
    },
  });
};

const Edit = ({ open, handleOpen, dataEdit, ...props }) => {
  const dispatch = useDispatch();
  const [draftModal, setDraftModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    // data,
  } = props;
  useEffect(() => {
    setFieldValue('sectorName', dataEdit?.name);
    setFieldValue('status', dataEdit?.status ? 'Active' : 'Inactive');
    setFieldValue('id', dataEdit?.id);
    setFieldValue('totalExhibitor', dataEdit?.totalExhibitor);

    // eslint-disable-next-line
  }, [dataEdit]);

  const handleChangeStatus = (event) => {
    setFieldValue('status', event.target.value);
  };

  const removeData = () => {
    dispatch(
      remove({
        name: 'sector/sectorcms',
        id: values.id,
        noLink: true,
        onSuccess: (res) => handleOpen(res),
      })
    );
  };
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Edit Sector
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="sectorName"
                label="Sector Name"
                margin="normal"
                value={values.sectorName}
                error={Boolean(touched.sectorName && errors.sectorName)}
                helperText={touched.sectorName && errors.sectorName}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  onChange={handleChangeStatus}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                  onBlur={handleBlur}
                  name="status"
                  label="Status"
                >
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'Inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setDeleteModal(true)}
          style={{
            background: '#E14040',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() =>
            values.status === 'Inactive' && values.totalExhibitor > 0
              ? setDraftModal(true)
              : handleSubmit()
          }
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Save
        </Button>
      </DialogActions>
      <Confirm
        open={draftModal}
        handleClose={() => setDraftModal(false)}
        handleAction={(e) => {}}
        handleContent={{
          button: {
            title: 'Save',
            style: {
              background: '#1AB759',
              border: '1px solid #1AB759',
              color: '#fff',
              width: 100,
              display: 'none',
            },
          },
          title: 'Draft Sector',
          message:
            'Sector can not be drafted when there are companies associated under it. Please remove all companies from this sector before drafting it. Note: We don’t recommend drafting sector due to the sheer amount of companies that might be affected.',
        }}
      />
      <Confirm
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleAction={
          values.totalExhibitor === 0
            ? (e) => {
                setDeleteModal(false);
                removeData();
              }
            : null
        }
        handleContent={{
          button: {
            title: 'Delete',
            style: {
              background: values.totalExhibitor === 0 ? '#E45858' : '#BFBFBF',
              color: '#fff',
              border:
                values.totalExhibitor === 0
                  ? '1px solid #E45858'
                  : '1px solid #BFBFBF',
              cursor: values.totalExhibitor === 0 ? 'pointer' : 'default',
            },
          },
          title: 'Delete Sector',
          message:
            values.totalExhibitor === 0
              ? 'This action can not be undone. Are you sure you want to delete the sector?'
              : 'Sector can not be deleted when there are companies associated under it. Please remove all companies from this sector before deleting it. Note: We don’t recommend deleting sector due to the sheer amount of companies that might be affected.',
        }}
      />
    </Dialog>
  );
};

const EditFormik = withFormik(
  Edit,
  schemeValidations,
  initialValueForm,
  handleSubmit
);
export default connect(null, { edit, remove })(EditFormik);
