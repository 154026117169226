import React, { Component, Fragment } from 'react';
import { Typography, Select, Input, MenuItem } from '@material-ui/core';
import Pagination from '../Pagination';

class TableFooter extends Component {
  render() {
    const {
      rowsPerPage,
      rowsPerPageOptions,
      onChangePage,
      onChangeRowsPerPage,
      count,
      page,
    } = this.props;
    return (
      <div className="table-footer-custom">
        <div className="rows-per-page">
          {rowsPerPage && (
            <Fragment>
              <Typography className="typography">Rows Per Page:</Typography>
              <Select
                className="selects"
                input={<Input disableUnderline />}
                value={rowsPerPage}
                onChange={onChangeRowsPerPage}
              >
                {rowsPerPageOptions.map((n, idn) => (
                  <MenuItem key={idn} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </Select>
            </Fragment>
          )}
        </div>

        <Pagination
          hasNext={Math.ceil(count / rowsPerPage) > page}
          hasPrevious={page > 1}
          total={Math.ceil(count / rowsPerPage)}
          current={page}
          pageSize={5}
          onChange={onChangePage}
        />

        <Typography className="typography">
          {`${count === 0 ? 0 : (page - 1) * rowsPerPage + 1}-${Math.min(
            count,
            page * rowsPerPage
          )} of ${count}`}
        </Typography>
      </div>
    );
  }
}

TableFooter.defaultProps = {
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50, 100],
  count: 0,
  page: 0,
};

export default TableFooter;
