import React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthRoute, OtherRoute, ShouldRoute } from "utils/accessRoute";

// Home
import Home from "modules/Home";

// Profile
import Profile from "modules/UserProfile";
import EditProfile from "modules/UserProfile/Edit";

// Authentication
import Login from "modules/Authentication/Login";
import ForgotPassword from "modules/Authentication/ForgotPassword";
import ResetPassword from "modules/Authentication/ResetPassword";

// Exhibitor
import AllExhibitors from "modules/Exhibitor";
import ViewExhibitor from "modules/Exhibitor/View";
import CreateExhibitor from "modules/Exhibitor/Create";
import CreateProduct from "modules/Exhibitor/Product/Create";
import CompanyDetailEdit from "modules/Exhibitor/CompanyDetailEdit";
import ExhibitorApplication from "modules/Exhibitor/ExhibitorApplication";
import ViewExhibitorApplication from "modules/Exhibitor/ExhibitorApplication/View";

// Product
import AllProducts from "modules/Product";
import ViewProduct from "modules/Product/View";
import EditProduct from "modules/Product/Edit";

// Appointment
import Appointment from "modules/Appointment";
import ViewAppointment from "modules/Appointment/View";

// Inbox
//import Inbox from "modules/Inbox";
//import ViewInbox from "modules/Inbox/View";

// Business Sector
import BusinessSector from "modules/BusinessSector";

// Visitor
import Visitor from "modules/Visitor";
import ViewVisitor from "modules/Visitor/View";
import EditVisitor from "modules/Visitor/Edit";

// Event
import Event from "modules/Event";

// News
import News from "modules/News";
import CreateNews from "modules/News/Create";
import ViewNews from "modules/News/View";
import EditNews from "modules/News/Edit";

// Region
import Region from "modules/Region";

// Country
import Country from "modules/Country";

// Banners
import Banners from "modules/Banners";
import ListBannersPage from "modules/Banners/ViewPages";
import CreateBanners from "modules/Banners/Create";
import EditBanners from "modules/Banners/Edit";
import ViewBanners from "modules/Banners/View";

// Running Text
import RunningText from "modules/RunningText";
import ViewRunningText from "modules/RunningText/View";
import EditRunningText from "modules/RunningText/Edit";

// Plenary Session
import PlenarySession from "modules/PlenarySession";
import CreatePlenarySession from "modules/PlenarySession/Create";
import EditPlenarySession from "modules/PlenarySession/Edit";
import ViewPlenarySession from "modules/PlenarySession/View";

// Speaker
import Speaker from "modules/Speaker";
import CreateSpeaker from "modules/Speaker/Create";
import EditSpeaker from "modules/Speaker/Edit";
import ViewSpeaker from "modules/Speaker/View";

// Participant
import EventParticipant from "modules/Participant/ListEvent";
import Participant from "modules/Participant";
import ViewParticipant from "modules/Participant/View";

// Media
import Media from "modules/Media";

// Statistics
import Stats from "modules/Stats";

// Tourism
import Tourism from "modules/Tourism";
import ViewTourism from 'modules/Tourism/View';

// Investment Inquiry
import InvestmentInquiry from "modules/InvestmentInquiry";
import ViewInvestmentInquiry from 'modules/InvestmentInquiry/View';

// Business Inquiry
import BusinessInquiry from "modules/BusinessInquiry";
import ViewBusinessInquiry from "modules/BusinessInquiry/View";
import CreateUploadBusinessInquiry from "modules/BusinessInquiry/Create-upload";
import ViewBusinessParticipant from "modules/BusinessInquiry/detail";
import MyBusinessInquiry from "modules/BusinessInquiry/my-business-inquiries";
import MyViewDetailBusinessInquiry from "modules/BusinessInquiry/my-detail";
import ViewDataBusinessInquiry from "modules/BusinessInquiry/Viewdata";
import EditDataBusinessInquiry from "modules/BusinessInquiry/Edit";
// User
import User from "modules/UsersPerwakilan/";
import CreateUser from "modules/UsersPerwakilan/Create";
import ViewUser from "modules/UsersPerwakilan/View";
import EditUser from "modules/UsersPerwakilan/Edit";

// Error
import Error from "modules/Error";
import Create from "modules/Users/Create";

const Index = ({ childProps }) => {
  return (
    <Switch>
      <Route exact path="/error" component={Error} props={childProps} />

      <ShouldRoute exact path="/login" component={Login} props={childProps} />
      <ShouldRoute
        exact
        path="/forgot-password"
        component={ForgotPassword}
        props={childProps}
      />
      <ShouldRoute
        exact
        path="/reset-password/:token"
        component={ResetPassword}
        props={childProps}
      />

      <AuthRoute exact path="/" component={Home} props={childProps} />
      <AuthRoute
        exact
        path="/profiles"
        component={Profile}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/profiles/edit"
        component={EditProfile}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/exhibitor-applications"
        component={ExhibitorApplication}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/exhibitor-applications/:exhibitorId"
        component={ViewExhibitorApplication}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/exhibitors"
        component={AllExhibitors}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/exhibitors/create"
        component={CreateExhibitor}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/exhibitors/:exhibitorId"
        component={ViewExhibitor}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/exhibitors/:exhibitorId/add-product"
        component={CreateProduct}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/exhibitors/:exhibitorId/edit"
        component={CompanyDetailEdit}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/my-company"
        component={ViewExhibitor}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/my-company/add-product"
        component={CreateProduct}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/my-company/edit"
        component={CompanyDetailEdit}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/products"
        component={AllProducts}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/products/:productId"
        component={ViewProduct}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/products/:productId/edit"
        component={EditProduct}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/appointments"
        component={Appointment}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/appointments/:appointmentId"
        component={ViewAppointment}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/business-sectors"
        component={BusinessSector}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/visitors"
        component={Visitor}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/visitors/:visitorId"
        component={ViewVisitor}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/visitors/:visitorId/edit"
        component={EditVisitor}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/business-forum"
        component={Event}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/press-releases"
        component={News}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/press-releases/create"
        component={CreateNews}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/press-releases/:newsId"
        component={ViewNews}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/press-releases/:newsId/edit"
        component={EditNews}
        props={childProps}
      />
      <AuthRoute exact path="/regions" component={Region} props={childProps} />
      <AuthRoute
        exact
        path="/countries"
        component={Country}
        props={childProps}
      />
      <AuthRoute exact path="/banners" component={Banners} props={childProps} />
      <AuthRoute
        exact
        path="/banners/page/:page"
        component={ListBannersPage}
        props={childProps}
      />

      <AuthRoute
        exact
        path="/banners/page/:page/create"
        component={CreateBanners}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/banners/page/:page/edit/:id"
        component={EditBanners}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/banners/page/:page/view/:id"
        component={ViewBanners}
        props={childProps}
      />
      <AuthRoute exact path="/media" component={Media} props={childProps} />
      <AuthRoute exact path="/stats" component={Stats} props={childProps} />
      <AuthRoute
        exact
        path="/running-text"
        component={RunningText}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/running-text/:page"
        component={ViewRunningText}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/running-text/:page/edit"
        component={EditRunningText}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/plenary-sessions"
        component={PlenarySession}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/plenary-sessions/create"
        component={CreatePlenarySession}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/plenary-sessions/:id"
        component={ViewPlenarySession}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/plenary-sessions/:id/edit"
        component={EditPlenarySession}
        props={childProps}
      />

      <AuthRoute
        exact
        path="/speakers"
        component={Speaker}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/speakers/create"
        component={CreateSpeaker}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/speakers/:id/"
        component={ViewSpeaker}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/speakers/:id/edit"
        component={EditSpeaker}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/participants/"
        component={EventParticipant}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/participants/:id"
        component={Participant}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/participants/view/:id"
        component={ViewParticipant}
        props={childProps}
      />

      {/* Tourism Contacts */}

      <AuthRoute
        exact
        path="/tourism"
        component={Tourism}
        props={childProps}
      />

      <AuthRoute
        exact
        path="/tourism/view/:id"
        component={ViewTourism}
        props={childProps}
      />

      {/* Investment Inquiry */}

      <AuthRoute
        exact
        path="/investment-inquiry"
        component={InvestmentInquiry}
        props={childProps}
      />

      <AuthRoute
        exact
        path="/investment-inquiry/view/:id"
        component={ViewInvestmentInquiry}
        props={childProps}
      />

      {/* Business Inquiry */}

      <AuthRoute
        exact
        path="/Business-inquiry"
        component={BusinessInquiry}
        props={childProps}
      />

      <AuthRoute
        exact
        path="/Business-inquiry/view/:id"
        component={ViewBusinessInquiry}
        props={childProps}
      />
     
     <AuthRoute
        exact
        path="/Business-inquiry/create-upload"
        component={CreateUploadBusinessInquiry}
        props={childProps}
      />
       <AuthRoute
        exact
        path="/Business-inquiry/participant/:id"
        component={ViewBusinessParticipant}
        props={childProps}
      />
        <AuthRoute
        exact
        path="/Business-inquiry/my-participant/:id"
        component={MyViewDetailBusinessInquiry}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/users"
        component={User}
        props={childProps}
      />
       <AuthRoute
        exact
        path="/create-user"
        component={CreateUser}
        props={childProps}
      />
 <AuthRoute
        exact
        path="/users/view/:id"
        component={ViewUser}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/users/:iduser/edit"
        component={EditUser}
        props={childProps}
      />
	 <AuthRoute
        exact
        path="/my-business-inquiry"
        component={MyBusinessInquiry}
        props={childProps}
      /> 
    <AuthRoute
        exact
        path="/Business-inquiry/viewdata/:id"
        component={ViewDataBusinessInquiry}
        props={childProps}
      />
      <AuthRoute
        exact
        path="/Business-inquiry/:id/edit"
        component={EditDataBusinessInquiry}
        props={childProps}
      />
      <OtherRoute props={childProps} />
    </Switch>
  );
};

export default Index;
