import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';
import TextEditor from 'components/Form/TextEditor';
import UploadImage from 'components/Upload/UploadImage';
import { load, edit } from 'redux/actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { uploadBase64Image } from 'utils/uploadMedia';
import { getUser } from "utils/localStorage";

import _ from 'lodash';

const initialValueForm = {
  status: '',
  title: '',
  body: '',
  status_progress: '',
  foto_1: [],
  foto_2: [],
  foto_3: [],
  foto_4: [],
  publishedDate: '',
  deadlineDate: '',
  sector: '',
  country:'',
  jumlah_permintaan: '',
};

const mapPropsToValues = ({ business }) => {
  if (business && business.data) {
    const data = business.data;
    return {
      status: {
        value: data?.status,
        title: _.capitalize(data?.status),
      },
      title: data?.title,
      body: data?.content,
      status_progress: data?.catatan_progress,

      foto_1: [data?.foto_1],
      foto_2: [data?.foto_2],
      foto_3: [data?.foto_3],
      foto_4: [data?.foto_4],
      publishedDate: data?.publishedDate,
      deadlineDate: data?.deadlineDate,
      sector: {
        title: data?.sector?.name,
        value: data?.sector?.id,
      },
      country: {
        title: data?.country?.name,
        value: data?.country?.id,
      },
      jumlah_permintaan: data?.jumlah_permintaan,
    };
  }
  return initialValueForm;
};
const handleFormSubmit = (payload, ctx) => {
  const { id } = ctx.props.match.params;
  let data = payload;

  const imgRegex = new RegExp(/data:image\/[^;]+;base64[^"]+/g)
  const imgSrc = data.body.match(imgRegex) || []
  
  Promise.all(imgSrc.map(src => 
    new Promise( async (resolve) => {
      const res = await uploadBase64Image(src, 'others')
      resolve(res)
    })
  )).then(values => {
    for (let i = 0; i < 1; i++) {
      if (values[i]?.status === 200) {
        payload.body = payload.body.replace(imgSrc[i], values[i].data.originalUrl)
      }
    }

    ctx.props.edit({
      name: 'business',
      id: id,
      data: {
        status: data.status.value,
        title: data.title,
        content: data.body,
        status_progress: data.status_progress,
        foto_1: data.foto_1[0],
        foto_2: data.foto_2[0],
        foto_3: data.foto_3[0],
        foto_4: data.foto_4[0],
        publishedDate: data.publishedDate,
        deadlineDate : data.deadlineDate,
        country: data.country.value,
        sector: data.sector.value,
        status: data.status.value,
        jumlah_permintaan :data.jumlah_permintaan,
        user : getUser()?.user?.id,
      },
      onSuccess: (res) => {
        ctx.props.history.push(`/Business-inquiry/viewdata/${res.data.id}`);
      },
      noLink: true,
    });
  }).catch(e => {
    console.error('Error occured:', e.message)
  })
};

const Edit = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { sector, business } = useSelector((state) => state.module.view);
  const { country } = useSelector((state) => state.module.view);

  useEffect(() => {
    dispatch(
      load({
        name: 'business',
        id: id,
      })
    );

    dispatch(
      load({
        name:'sector?keyword=&skip=0&limit=1000&order=&orderMethod=desc',
        customName: 'sector',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      load({
        name:'countrycms?keyword=&skip=0&limit=1000&order=&orderMethod=desc',
        customName: 'country',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listsector = sector?.data?.map((n) => ({
    value: n.id,
    title: n.name,
  }));

  const listcountrycms = country?.data?.map((n) => ({
    value: n.id,
    title: n.name,
  }));

  const view = business?.data;
  return (
       <div className="app-wrapper business-page">
      <ContainerHeader
        title="Add Business Inquiries"
        breadcrumb={[
          { link: '/business-inquiry', label: 'Business Inquiries' },
          { link: '', label: 'Add Business Inquiries' },
        ]}
      />
      <Board
      //disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                border: '1px solid #1AB759',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Save Changes',
            confirmMessage: 'Do you wish to add this?',
            onClick: () => handleSubmit(),
            style: {
              width: 148,
              color: '#fff',
              background: '#1AB759',
              border: '1px solid #1AB759',
            },
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
          <div className="section">
          <p className="date">
            Updated at {moment(view?.modifiedAt).format('DD MMMM YYYY')}
          </p>
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMMM YYYY')}
          </p>
        </div>

        <div className="section">
          <Typography className="title">Business Inquiries Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
            <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'progress', title: 'Progress' },
                  { value: 'draft', title: 'Draft' },
                  { value: 'done', title: 'Done' },
                ]}
                value={values.status}
                onChange={(e, val) => setFieldValue('status', val)}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="title"
                label="Title"
                margin="normal"
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="body"
                label="Body"
                value={values.body}
                onChange={(val) =>
                  setFieldValue('body', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'body' } })}
                error={Boolean(touched.body && errors.body)}
                helperText={touched.body && errors.body}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
          <Grid sm={12} item>
              <TextEditor
                name="status_progress"
                label="Status Progress"
                value={values.status_progress}
                onChange={(val) =>
                  setFieldValue('status_progress', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'status_progress' } })}
                error={Boolean(touched.status_progress && errors.status_progress)}
                helperText={touched.status_progress && errors.status_progress}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
            <Autocomplete
                name="country"
                options={listcountrycms}
                value={values.country}
                onChange={(e, val) =>
                  setFieldValue('country', val !== null ? val : '')
                }
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Negara"
                    variant="outlined"
                    name="country"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    value={values.country}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                />
            </Grid>
            
          </Grid>
          <Grid container spacing={3}>
          <Grid sm={6} item>
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                name="jumlah_permintaan"
                label="Jumlah Permintaan"
                margin="normal"
                error={Boolean(touched.jumlah_permintaan && errors.jumlah_permintaan)}
                helperText={touched.jumlah_permintaan && errors.jumlah_permintaan}
                value={values.jumlah_permintaan}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
            <Autocomplete
                name="sector"
                options={listsector}
                value={values.sector}
                onChange={(e, val) =>
                  setFieldValue('sector', val !== null ? val : '')
                }
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sector"
                    variant="outlined"
                    name="sector"
                    error={Boolean(touched.sector && errors.sector)}
                    helperText={touched.sector && errors.sector}
                    value={values.sector}
                    onBlur={handleBlur}
                    
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                />
            </Grid>
          </Grid>
        
          <Grid container spacing={3}>
          <Grid sm={6} item>
              <TextField
                readonly
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                name="Perwakilin RI"
                label="Perwakilin RI"
                margin="normal"
                error={Boolean(touched.PerwakilinRI && errors.PerwakilinRI)}
                helperText={touched.PerwakilinRI && errors.PerwakilinRI}
                value={getUser()?.user?.companyName}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
            <TextField
                fullWidth
                required
                type="date"
                variant="outlined"
                name="publishedDate"
                label="Published Date"
                margin="normal"
                value={moment(values.publishedDate).format('YYYY-MM-DD')}
                error={Boolean(touched.publishedDate && errors.publishedDate)}
                helperText={touched.publishedDate && errors.publishedDate}
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{
                  min: moment(new Date()).format('YYYY-MM-DD'),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
     

        <div className="section">
          <Grid container spacing={3}>
           <Grid sm={12} item>

              <Typography
                className="title"
                style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}
              >
                Foto
              </Typography>
 </Grid>
	  <Grid sm={3} item>
              <UploadImage
                name="foto_1"
                value={values.foto_1}
                onImageChange={(val) => setFieldValue('foto_1', val)}
                error={errors.foto_1}
                max={1}
              />
 </Grid>
  <Grid sm={3} item>
              <UploadImage
                name="foto_2"
                value={values.foto_2}
                onImageChange={(val) => setFieldValue('foto_2', val)}
                error={errors.foto_2}
                max={1}
              />
 </Grid>
  

  <Grid sm={3} item>
              <UploadImage
                name="foto_3"
                value={values.foto_3}
                onImageChange={(val) => setFieldValue('foto_3', val)}
                error={errors.foto_3}
                max={1}
              />
 </Grid>
    <Grid sm={3} item>
              <UploadImage
                name="foto_4"
                value={values.foto_4}
                onImageChange={(val) => setFieldValue('foto_4', val)}
                error={errors.foto_4}
                max={1}
              />
 </Grid>

  

    <Grid sm={12} item>
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 10mb
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended thumbnail size is 320px x 200px (.jpg, .jpeg, and
                  .png)
                </Typography>
              </div>
           </Grid>

          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
               fullWidth
               required
               type="date"
               variant="outlined"
               name="deadlineDate"
               label="Deadline Date"
               margin="normal"
               value={moment(values.deadlineDate).format('YYYY-MM-DD')}
               error={Boolean(touched.deadlineDate && errors.deadlineDate)}
               helperText={touched.deadlineDate && errors.deadlineDate}
               onBlur={handleBlur}
               onChange={handleChange}
               inputProps={{
                 min: moment(new Date()).format('YYYY-MM-DD'),
               }}
               InputLabelProps={{
                 shrink: true,
               }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

const mapStateToProps = (state) => ({
  business: state.module.view.business,
});

const mapDispatchToProps = {
  edit,
};

const FormikEdit = withFormik(
  Edit,
  schemeValidations,
  mapPropsToValues,
  handleFormSubmit
);

export default connect(mapStateToProps, mapDispatchToProps)(FormikEdit);

