import * as Yup from 'yup';

const schemeValidations = Yup.object().shape({
  title: Yup.string().required('Title can not be left blank.'),
  body: Yup.string().required('Body can not be left blank.'),
  excerpt: Yup.string().required('Excerpt can not be left blank.'),
  thumbnail: Yup.array().min(1, 'Image is required.'),
  banner: Yup.array().min(1, 'Image is required.'),
  publishedDate: Yup.string().required('Please select the publishing date.'),
  category: Yup.string().required('Please select a category.'),
});

export default schemeValidations;
