import React from 'react';
import { List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const NavMenuItem = (props) => {
  const { name, icon, link } = props;
  let links = link.split('/')[1];
  let currentLink = window.location.pathname.split('/')[1];
  let isActive = links === currentLink;
  return (
    <List component="div" className="nav-menu-item">
      <NavLink
        exact={true}
        className={`prepend-icon nav-menu-link ${isActive && 'active'}`}
        to={link}
      >
        {!!icon ? (
          <span
            class="material-icons"
            style={{
              fontSize: '15px',
              marginLeft: '4.3px',
              marginRight: '14px',
            }}
          >
            {icon}
          </span>
        ) : (
          <FiberManualRecordIcon
            style={{
              fontSize: '12px',
              marginLeft: '4.3px',
              marginRight: '14px',
            }}
          />
        )}
        <span className="nav-text">{name}</span>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
