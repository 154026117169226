import React, { useEffect, useState  } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, TextField } from '@material-ui/core';
import FormikPhoneInput from 'components/FormikPhoneInput';

import { EmailOutlined as EmailIcon } from '@material-ui/icons';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from 'redux/actions';
import SendEmail from './SendEmail';

const ViewBusinessParticipant = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [openSendMail, setOpenSendMail] = useState(false);
  const { business } = useSelector((state) => state.module.view);
  const id = useParams().id;
  useEffect(() => {
    dispatch(
      load({
        name: 'business/BusinessRegister',
        id: id,
        customName: 'business',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const view = business?.data;
  const removeData = () => {
    dispatch(
      remove({
        name: 'business/BusinessRegister',
        id,
        noLink: true,
        onSuccess: (res) => history.push('/participants'),
      })
    );
  };
  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="View Business Inquiries Participant Details"
        breadcrumb={[
          { link: '/business-inquiry', label: 'Business Inquiries' },
          {
            link: `/Business-inquiry/participant/${id}`,
            label: 'View Business Inquiries Participant Details',
          },
        ]}
      />

      <Board
                 >
        <div className="section">
          <Typography className="title" style={{ marginBottom: 10 }}>
            Detail Participants
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
            <div >
              {view?.foto_1
        ? <img src={view?.foto_1} width="200px" alt="title" />
        : <img src="https://media.ia.webapdemo.com/product_image/1634727163206_20211020_174634.jpg" width="200px" alt="title" />
               
      }
              </div>
            </Grid>
            <Grid sm={6} item>
            <div >
              {view?.foto_2
        ? <img src={view?.foto_2} width="200px" alt="title" />
        : <img src="https://media.ia.webapdemo.com/product_image/1634727163206_20211020_174634.jpg" width="200px" alt="title" />
               
      }
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="fullname"
                label="Full Name"
                margin="normal"
                value={view?.fullName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyEmail"
                label="Email Address"
                margin="normal"
                value={view?.email}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormikPhoneInput
                disabled
                name="picPhone"
                inputProps={{ name: 'picPhone' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                // values="62123126969"
                // value="6234234234"
                values={view?.phoneNumber || ''}
                value={view?.phoneNumber || ''}
                countryCodeEditable={false}
                specialLabel="Phone Number"
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
          </Grid>
          <br />
          <div className="section">
            <Grid container spacing={3}>
              <Grid sm={6} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="companyName"
                  label="Company/Institution name"
                  margin="normal"
                  value={view?.institutionName}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid sm={6} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="yourCountry*"
                  label="Your Country"
                  margin="normal"
                  value={view?.country}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid sm={12} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="yourCountry*"
                  label="Message"
                  margin="normal"
                  value={view?.message}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
           
          </div>
        
        </div>
     
 </Board>
    </div>
  );
};

export default ViewBusinessParticipant;
