import * as Yup from 'yup';

const lettersAndSpace = /^[A-Za-z\s]*$/;

const ViewSchemeValidations = () =>
  Yup.object().shape({
    sectorName: Yup.string()
      .matches(lettersAndSpace, 'Sector Name can only contain letters')
      .required('Sector name can not be left blank.'),
    status: Yup.string().required('Status can not be left blank.'),
  });
export default ViewSchemeValidations;
