import * as Yup from 'yup';

const schemeValidations = Yup.object().shape({
  status: Yup.string().required('Status can not be left blank.'),
  fullName: Yup.string()
    .matches(/^[A-Za-z\s]*$/, 'Full name can only contain letters.')
    .required('Full name can not be left blank.'),
  email: Yup.string()
    .email('Email address uses an invalid email format.')
    .required('Email address can not be left blank.'),
  phoneNumber: Yup.string().required('Phone number can not be left blank.'),
  company: Yup.string().required('Company name can not be left blank.'),
  country: Yup.string().required(
    'Select the country your company is operating from.'
  ),
  businessSector: Yup.array()
    .min(1, 'Select the sector your company is in.')
    .max(3, 'You can only select up to a maximum of 3 business sectors.'),
});

export default schemeValidations;
