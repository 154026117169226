import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';

const Index = (props) => {
  const history = useHistory();
  const { page } = useParams();

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title={_.capitalize(page)}
        breadcrumb={[
          { link: '/banners', label: 'Banners' },
          { link: '/banners', label: 'All Pages' },
          { link: `/banners/${page}`, label: _.capitalize(page) },
        ]}
      />

      <div className="action-btn">
        <Button
          style={{
            background: '#ED901A',
            borderRadius: 8,
            padding: '8px 24px',
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
          }}
          onClick={() => props.history.push(`/banners/page/${page}/create`)}
        >
          Add New Banner
        </Button>
      </div>

      <Tables
        title="All Banners"
        name={`banner/${page}`}
        minWidth={1200}
        viewDetail={{ name: 'exhibitors', by: 'id' }}
        columnHead={[
          { id: 'preview', label: 'Preview' },
          { id: 'title', label: 'Title' },
          { id: 'order', label: 'Order' },
          { id: 'status', label: 'Status' },
          { id: '', label: 'Action' },
        ]}
        columnBody={[
          {
            parent: (n) =>
              n.type === 'image' ? (
                <img src={n.image} width={86} height={86} alt="Banner" />
              ) : (
                <video width={100} height={86} controls>
                  <source src={n.image} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ),
          },
          { parent: 'title' },
          { parent: 'sort' },
          { parent: 'status' },
          {
            parent: (n) => (
              <Button
                onClick={() =>
                  history.push(`/banners/page/${page}/view/${n.id}`)
                }
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                  fontSize: 12,
                }}
              >
                View
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Index;
