import React from 'react';
import { Card, Typography } from '@material-ui/core';

const OverviewCard = ({ title, total, onClick }) => {
  return (
    <Card
      onClick={onClick}
      className="cursor-pointer"
      style={{
        borderRadius: 8,
        backgroundColor: '#FADEBA',
        padding: '16px 26px',
        boxShadow:
          '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
      }}
    >
      <Typography style={{ fontSize: 20, fontWeight: 500 }}>{title}</Typography>
      <Typography style={{ fontSize: 52, fontWeight: 500 }}>
        {total ? total : 0}
      </Typography>
    </Card>
  );
};

export default OverviewCard;
