import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, TextField, Chip } from '@material-ui/core';
import _ from 'lodash';
import Gallery from 'components/Gallery';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load } from 'redux/actions/Module';
// import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';

const ViewBanner = (props) => {
  const history = useHistory();
  const { id, page } = useParams();
  const dispatch = useDispatch();
  const { banner } = useSelector((state) => state.module.view);

  // const { list } = useSelector((state) => state.module);

  useEffect(() => {
    dispatch(
      load({
        name: 'banner',
        id: `${page}/${id}`,
      })
    );

    // eslint-disable-next-line
  }, []);

  const view = banner?.data;

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="View Banner"
        breadcrumb={[
          { link: '/banners', label: 'Banners' },
          { link: '/banners', label: 'All Pages' },
          { link: `/banners/page/${page}`, label: _.capitalize(page) },
          { link: `/banners/page/${page}/view/${id}`, label: 'View Banner' },
        ]}
      />
      <Board
        actions={[
          {
            label: 'Edit Banner',
            confirm: false,
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => history.push(`/banners/page/${page}/edit/${id}`),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="status"
                label="Status"
                margin="normal"
                value={_.capitalize(view?.status)}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="order"
                label="Order"
                disabled
                margin="normal"
                value={view?.sort}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title" style={{ marginBottom: 16 }}>
            Media Type
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="media"
                label="Media"
                margin="normal"
                value={_.capitalize(view?.type)}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        {view?.type === 'image' ? (
          <div>
            <div className="section">
              <Typography className="title" style={{ marginBottom: 16 }}>
                Banner Details
              </Typography>
              <Grid container spacing={3}>
                <Grid sm={12} item>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="title"
                    label="Title"
                    margin="normal"
                    disabled
                    value={view?.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={12} item>
                  <TextEditor
                    name="description"
                    label="Description"
                    value={view?.description || ''}
                    readOnly
                  />
                </Grid>
              </Grid>
            </div>
            <div className="section">
              <Gallery
                title="Desktop Background Image"
                images={[{ url: view?.images }]}
              />
            </div>
            <div className="section">
              <Gallery
                title="Mobile Background Image"
                images={[{ url: view?.image_mobile }]}
              />
            </div>
            <div className="section">
              <Typography className="title" style={{ marginBottom: 16 }}>
                Button
              </Typography>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="titleButton"
                    label="Button Title"
                    margin="normal"
                    disabled
                    value={view?.button_txt}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="link"
                    label="Link"
                    margin="normal"
                    disabled
                    value={view?.link}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        ) : null}
        {view?.type === 'video' ? (
          <div className="section">
            <Chip
              fullWidth
              required
              variant="outlined"
              name="video"
              label={
                view?.image?.split('/')[view?.image?.split('/').length - 1] ||
                'No File'
              }
              margin="normal"
              value={view?.image}
              style={{ width: '50%' }}
              onClick={() => (window.location.href = view?.image)}
              disabled={[undefined, '', null, []].includes(view?.image)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        ) : null}
      </Board>
    </div>
  );
};

export default ViewBanner;
