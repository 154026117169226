import React, { useState } from 'react';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';
import ExportModal from 'components/ExportModal';
import Create from './Create';
import Edit from './Edit';

const Index = () => {
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  return (
    <div className="app-wrapper event-page">
      <ContainerHeader
        title="Business Forum"
        breadcrumb={[{ link: '/business-forum', label: 'Business Forum' }]}
      />

      <div className="action-btn">
        <Button
          style={{
            border: '1px solid #ED901A',
            borderRadius: 8,
            color: '#ED901A',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => setIsOpenExport(true)}
        >
          Export
        </Button>
        <Button
          style={{
            background: '#ED901A',
            padding: '8px 24px',
            borderRadius: 8,
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
          }}
          onClick={() => setIsOpenCreate(true)}
        >
          Add Event
        </Button>
      </div>

      <Tables
        title="Business Forum"
        name="classes"
        columnHead={[
          { id: 'id', canFilter: false, label: 'No' },
          { id: 'eventName', canFilter: false, label: 'Event Name' },
          { id: 'status', canFilter: false, label: 'Status' },
          { id: 'date', canFilter: false, label: 'Date' },
          { id: 'createdAt', canFilter: false, label: 'Created At' },
          { id: 'action', canFilter: false, label: 'Action' },
        ]}
        columnBody={[
          { parent: 'id' },
          { parent: 'eventName' },
          { parent: 'status' },
          { parent: 'date' },
          { parent: 'createdAt' },
          { parent: 'action' },
        ]}
      />

      <ExportModal
        open={isOpenExport}
        handleOpen={(val) => setIsOpenExport(val)}
      />

      <Create open={isOpenCreate} handleOpen={(val) => setIsOpenCreate(val)} />

      <Edit open={isOpenEdit} handleOpen={(val) => setIsOpenEdit(val)} />
    </div>
  );
};

export default Index;
