import React from 'react';
import KemluLogo from 'assets/images/footer/kemlu-logo.svg';
import KemendagLogo from 'assets/images/footer/kemendag-logo-new.svg';
import KemenparekrafLogo from 'assets/images/footer/kemenparekraf-logo.svg';
import BKPMLogo from 'assets/images/footer/bkpm-logo.svg';
import BankIndonesiaLogo from 'assets/images/footer/bi-logo.svg';
import WonderfullIndonesiLogo from 'assets/images/footer/wonderfull-Indonesia.svg';

const Footer = () => {
  return (
    <footer className="footer-auth">
      <div>
        <img className="img" src={KemluLogo} alt="government-logo" />
        <img className="img" src={KemendagLogo} alt="government-logo" />
        <img className="img" src={KemenparekrafLogo} alt="government-logo" />
        <img className="img" src={BKPMLogo} alt="government-logo" />
        <img className="img" src={BankIndonesiaLogo} alt="government-logo" />
        <img
          className="img"
          src={WonderfullIndonesiLogo}
          alt="government-logo"
        />
      </div>
      <div className="footerBottom">
        © 2021 Kementerian Luar Negeri Republik Indonesia. All Right Reserved.
      </div>
    </footer>
  );
};

export default Footer;
