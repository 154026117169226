import * as Yup from 'yup';

export const createSpeaker = Yup.object().shape({
  speakerName: Yup.string().required('Full name can not be left blank.'),
  title: Yup.string().required('Title can not be left blank.'),
  profile: Yup.string().required('Profile can not be left blank.'),

  // category: Yup.string().required(
  //   'Please select which event this speaker will participate.'
  // ),
  image: Yup.array().min(1, 'Image is required.'),
});
