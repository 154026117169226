import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { asMutable } from 'seamless-immutable';
import { v4 as uuidv4 } from 'uuid';

import { countries, sector } from 'data';
import { useHistory, useParams } from 'react-router-dom';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { load, edit, list as listRedux } from 'redux/actions';
import { useSelector, useDispatch, connect } from 'react-redux';
import moment from 'moment';
import FormikPhoneInput from 'components/FormikPhoneInput';

const initialValueForm = {
  statusField: {},
  status: '',
  fullName: '',
  email: '',
  phoneNumber: '',
  company: '',
  country: '',
  countryValues: {},
  businessSector: '',
  businessSectorValues: {},
  visitorCountry: '',
  visitorCountryValues: {},
};
const mapPropsToValue = ({ data }) => {
  const { visitor } = data;
  if (visitor) {
    let mutableData = asMutable(visitor, { deep: true });
    return {
      status: visitor.data.status,
      fullName: visitor.data.name,
      email: visitor.data.email,
      phoneNumber: visitor.data.phoneNumber,
      company: visitor.data.companyName,
      companyNameOld: visitor.data.companyName,
      emailOld: visitor.data.email,
      country: visitor.data.companyCountry,
      countryValues: {
        code: visitor.data.companyCountry,
        createdAt: visitor.data.companyCountry,
        deletedAt: visitor.data.companyCountry,
        flag: visitor.data.companyCountry,
        id: visitor.data.companyCountry,
        modifiedAt: visitor.data.companyCountry,
        name: visitor.data.companyCountry,
        uuid: visitor.data.companyCountry,
      },
      visitorCountry: visitor.data.country,
      visitorCountryValues: {
        code: visitor.data.country,
        createdAt: visitor.data.country,
        deletedAt: visitor.data.country,
        flag: visitor.data.country,
        id: visitor.data.country,
        modifiedAt: visitor.data.country,
        name: visitor.data.country,
        uuid: visitor.data.country,
      },
      businessSector:
        mutableData &&
        mutableData?.data?.businessSector?.map((val) => ({
          id: val,
          name: val,
        })),
      businessSectorValues:
        mutableData &&
        mutableData?.data?.businessSector?.map((val) => ({
          id: val,
          name: val,
        })),
    };
  }
  return initialValueForm;
};
const handleSubmit = (payload, ctx) => {
  // console.log(ctx);
  const {
    // statusField,
    status,
    fullName,
    email,
    phoneNumber,
    company,
    country,
    // countryValues,
    businessSector,
    visitorCountry,
    // businessSectorValues,
  } = payload;
  let business = [];
  // ctx.props.match.params.visitorId
  businessSector.map((value) => {
    business.push(value.name);
    return value;
  });
  ctx.props.edit({
    name: `visitor/${ctx.props.match.params.visitorId}`,
    data: {
      email: email,
      name: fullName,
      phoneNumber: phoneNumber,
      companyName: company,
      companyCountry: country,
      businessSector: business,
      country: visitorCountry,
      status,
    },
    linkSuccess: `visitors/${ctx.props.match.params.visitorId}`,
  });
};
const Edit = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    setFieldError,
    // data,
  } = props;
  const history = useHistory();
  const slug = useParams().visitorId;
  const [emailValid, setEmailValid] = useState(false);
  const [companyValid, setCompanyValid] = useState(false);
  const [mutableCountries, setMutableCountries] = useState([]);

  const dispatch = useDispatch();
  const { countriesApi } = useSelector((state) => state.module.list);
  const { visitor, businessSectorList } = useSelector(
    (state) => state.module.view
  );
  useEffect(() => {
    dispatch(
      listRedux({
        name: 'country',
        customName: 'countriesApi',
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (countriesApi) {
      let countriesApiMutable = asMutable(countriesApi, { deep: true });

      setMutableCountries(countriesApiMutable);
    }

    // eslint-disable-next-line
  }, [countriesApi]);
  const checkCompanyName = () => {
    if (values.company !== '') {
      dispatch(
        load({
          name: 'check',
          id: `companyPhone?field=companyName&detail=${values.company}&as=visitor`,
          onSuccess: (res) => onSuccessCheckCompanyName(res),
        })
      );
    }
  };
  const onSuccessCheckCompanyName = (res) => {
    if (res.data) {
      setFieldError('company', 'Company name has already been used.');
    } else {
      setCompanyValid(true);
    }
  };
  const checkEmail = () => {
    if (values.email !== '') {
      dispatch(
        load({
          name: 'check',
          id: `email?role=visitor&email=${values.email}`,
          onSuccess: (res) => onSuccessCheckEmail(res),
        })
      );
    }
  };
  const onSuccessCheckEmail = (res) => {
    if (res.data) {
      setFieldError('email', 'Email address has already been used.');
    } else {
      setEmailValid(true);
    }
    // console.log(res);
  };
  const onSubmit = () => {
    if (
      (emailValid && companyValid) ||
      (values.emailOld === values.email &&
        values.companyNameOld === values.company)
    ) {
      handleSubmit();
    } else {
      checkCompanyName();
      checkEmail();
    }
  };

  useEffect(() => {
    if (emailValid && companyValid) {
      handleSubmit();
    }
    // console.log('valid and valid');
    // eslint-disable-next-line
  }, [emailValid, companyValid]);

  useEffect(() => {
    dispatch(
      load({
        name: 'visitor',
        id: slug,
      })
    );
    dispatch(
      load({
        name: 'sector/getlist',
        customName: 'businessSectorList',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const view = visitor?.data;
  const onStatusChange = (event, values) => {
    setFieldValue('status', event.target.value);
  };

  const onBusinessSector = (event, values) => {
    setFieldValue('businessSector', values);
  };

  let businessSectorOptions =
    businessSectorList &&
    businessSectorList.data.map((val) => ({ id: val.name, name: val.name }));
  let businessSectorOptionsUpdate = [];

  useEffect(() => {
    if (businessSectorList?.data) {
      // eslint-disable-next-line
      businessSectorOptionsUpdate =
        // eslint-disable-next-line
        businessSectorList &&
        businessSectorList.data.map((val) => ({
          id: val.name,
          name: val.name,
        }));
    }
  }, [businessSectorList]);

  const handleBlurBusinessSector = (event, values) => {
    // setLoading(true)
    dispatch(
      listRedux({
        name: 'sector/getlist',
        query: `keyword=${values}`,
        customName: 'businessSectorList',
      })
    );
  };
  const handlePicPhoneChange = (value, country) => {
    setFieldValue('phoneNumber', value);
  };
  console.log(dirty, isValid, errors);
  return (
    <div className="app-wrapper visitor-page">
      <ContainerHeader
        title="Edit Visitor"
        breadcrumb={[
          { link: '/visitors', label: 'Visitors' },
          { link: '', label: 'Edit Visitor' },
        ]}
      />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save Change',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Save Changes',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => onSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <p style={{ marginTop: 40 }}>{`Updated at ${moment(
            view?.modifiedAt
          ).format('D MMMM YYYY')}`}</p>
          <p>{`Submited at ${moment(view?.createdAt).format(
            'D MMMM YYYY'
          )}`}</p>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <FormControl
                variant="outlined"
                fullWidth
                size="medium"
                margin="normal"
              >
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  name="status"
                  label="country"
                  onChange={onStatusChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                >
                  {['active', 'inactive'].map((value, index) => (
                    <MenuItem key={uuidv4()} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Visitor Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="fullName"
                label="Full Name"
                margin="normal"
                value={values.fullName}
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && errors.fullName}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="email"
                label="Email Address"
                margin="normal"
                disabled
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormikPhoneInput
                name="phoneNumber"
                errorText="Phone number can not be left blank."
                inputProps={{ name: 'phoneNumber' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                value={values.phoneNumber}
                onChange={handlePicPhoneChange}
                countryCodeEditable={false}
                onBlur={handleBlur}
                specialLabel="Phone Number"
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
            <Grid sm={6} item>
              <Autocomplete
                style={{ marginTop: 15 }}
                options={
                  mutableCountries?.data
                    ? mutableCountries?.data?.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )
                    : countries
                }
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={(e, val) => {
                  setFieldValue('visitorCountry', val.name);
                  setFieldValue('visitorCountryValues', val);
                }}
                name="visitorCountry"
                value={values.visitorCountryValues}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Visitor Country"
                    name="visitorCountry"
                    error={Boolean(
                      touched.visitorCountry && errors.visitorCountry
                    )}
                    helperText={touched.visitorCountry && errors.visitorCountry}
                    value={values.visitorCountry}
                    onBlur={handleBlur}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="company"
                label="Company Name"
                margin="normal"
                disabled
                error={Boolean(touched.company && errors.company)}
                helperText={touched.company && errors.company}
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <Autocomplete
                style={{ marginTop: 15 }}
                options={
                  mutableCountries?.data
                    ? mutableCountries?.data?.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )
                    : countries
                }
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={(e, val) => {
                  setFieldValue('country', val.name);
                  setFieldValue('countryValues', val);
                }}
                name="country"
                value={values.countryValues}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    name="country"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    value={values.country}
                    onBlur={handleBlur}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                multiple
                name="businessSector"
                options={
                  businessSectorOptionsUpdate.length > 0
                    ? businessSectorOptionsUpdate
                    : businessSectorList
                      ? businessSectorOptions && businessSectorOptions
                      : sector
                }
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                style={{ marginTop: 15 }}
                onChange={onBusinessSector}
                value={
                  values?.businessSector?.length === 0
                    ? []
                    : values?.businessSector
                }
                onBlur={handleBlur}
                onInputChange={handleBlurBusinessSector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Business Sector"
                    error={Boolean(errors.businessSector)}
                    value={values.businessSector}
                    helperText={errors.businessSector}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={
                      values?.businessSector?.length > 0
                        ? ''
                        : 'Please select up to 3 business sectors'
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: state.module.view,
});
const FormikEdit = withFormik(
  Edit,
  schemeValidations,
  mapPropsToValue,
  handleSubmit
);
export default connect(mapStateToProps, { edit })(FormikEdit);
