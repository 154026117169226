import React from 'react';
import InaAccessLogo from 'assets/images/INA-Access.svg';

const Header = () => {
  return (
    <div className="header-auth">
      <img src={InaAccessLogo} alt="ina-logo" />
    </div>
  );
};

export default Header;
