import React from 'react';
import { Button as FlatButton, IconButton, Tooltip } from '@material-ui/core';
import {
  ChevronLeft as NavigationFirstPage,
  ChevronRight as NavigationLastPage,
} from '@material-ui/icons';

const flatButtonStyle = {
  minWidth: 16,
};

const calculateRange = (arg) => {
  const { total, current, pageSize } = arg;
  let end = total;
  let start = 1;
  if (pageSize < end) {
    // rounded to the nearest integer smaller
    let beforeNumber = Math.round(pageSize / 2 - 0.5);
    const afterNumber = beforeNumber;
    if (pageSize % 2 === 0) {
      beforeNumber -= 1;
    }

    if (current <= beforeNumber + 1) {
      end = pageSize;
    } else if (current >= total - afterNumber) {
      start = total - pageSize + 1;
    } else {
      start = current - beforeNumber;
      end = current + afterNumber;
    }
  }

  return { end, start };
};

const getStateFromProps = (props) => {
  let { total, current, pageSize } = props;
  total = total > 0 ? total : 1;
  current = current > 0 ? current : 1;
  pageSize = pageSize > 0 ? pageSize : 1;
  current = current < total ? current : total;
  pageSize = pageSize < total ? pageSize : total;
  return { current, pageSize, total };
};

const Page = ({
  value,
  isActive,
  onClick,
  stylePage,
  styleButton,
  stylePrimary,
}) =>
  !styleButton ? (
    <FlatButton
      className="paginationNumber"
      style={{ ...flatButtonStyle, ...stylePage }}
      label={value.toString()}
      color={isActive ? 'primary' : 'default'}
      onClick={onClick}
    >
      {value.toString()}
    </FlatButton>
  ) : (
    <div
      style={[isActive ? stylePrimary : styleButton]}
      label={value.toString()}
      onClick={onClick}
    >
      {value}
    </div>
  );

const FirstPageLink = ({ onClick, disabled, styleFirstPageLink }) =>
  !styleFirstPageLink ? (
    <Tooltip title="Go to First Page">
      <span>
        <IconButton
          disabled={disabled}
          className="arrowButton"
          onClick={onClick}
        >
          <NavigationFirstPage style={{ marginRight: -6.5 }} />
          <NavigationFirstPage style={{ marginLeft: -6.5 }} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <div style={styleFirstPageLink} onClick={onClick} />
  );

const LastPageLink = ({ onClick, disabled, styleLastPageLink }) =>
  !styleLastPageLink ? (
    <Tooltip title="Go to Last Page">
      <span>
        <IconButton
          disabled={disabled}
          className="arrowButton"
          onClick={onClick}
        >
          <NavigationLastPage style={{ marginRight: -6.5 }} />
          <NavigationLastPage style={{ marginLeft: -6.5 }} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <div style={styleLastPageLink} onClick={onClick} />
  );

const PreviousPageLink = ({ onClick, disabled, styleFirstPageLink }) =>
  !styleFirstPageLink ? (
    <Tooltip title="Go to Previous Page">
      <span>
        <IconButton
          disabled={disabled}
          className="arrowButton"
          onClick={onClick}
        >
          <NavigationFirstPage />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <div style={styleFirstPageLink} onClick={onClick} />
  );

const NextPageLink = ({ onClick, disabled, styleLastPageLink }) =>
  !styleLastPageLink ? (
    <Tooltip title="Go to Next Page">
      <span>
        <IconButton
          disabled={disabled}
          className="arrowButton"
          onClick={onClick}
        >
          <NavigationLastPage />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <div style={styleLastPageLink} onClick={onClick} />
  );
class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const temp = getStateFromProps(props);
    this.setCurrent = this.setCurrent.bind(this);

    this.state = {
      ...temp,
      ...calculateRange(temp),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const temp = getStateFromProps(props);
    return {
      ...temp,
      ...calculateRange(temp),
    };
  }

  setCurrent(current) {
    const temp = { ...this.state, current };
    this.props.onChange(current);
    this.setState({
      ...temp,
      ...calculateRange(temp),
    });
  }

  render() {
    const { FirstPageIcon, LastPageIcon, hasPrevious, hasNext } = this.props;
    let FirstPageComponent = FirstPageLink;
    let LastPageComponent = LastPageLink;
    if (FirstPageIcon) {
      FirstPageComponent = FirstPageIcon;
    }
    if (LastPageIcon) {
      LastPageComponent = LastPageIcon;
    }
    const array = [];
    for (let i = this.state.start; i <= this.state.end; i += 1) {
      array.push(i);
    }

    return (
      <div className="pagination-custom" style={{ ...this.props.styleRoot }}>
        <FirstPageComponent
          disabled={!hasPrevious}
          onClick={() => this.setCurrent(1)}
          styleFirstPageLink={this.props.styleFirstPageLink}
        />
        <PreviousPageLink
          disabled={this.state.current === 1}
          onClick={() => this.setCurrent(this.state.current - 1)}
          styleFirstPageLink={this.props.styleFirstPageLink}
        />
        {array.map((page, k) => (
          <Page
            key={k}
            value={page}
            isActive={this.state.current === page}
            onClick={() => this.setCurrent(page)}
            styleButton={this.props.styleButton}
            stylePrimary={this.props.stylePrimary}
            stylePage={this.props.stylePage}
          />
        ))}
        <NextPageLink
          disabled={this.state.current === this.state.total}
          onClick={() => this.setCurrent(this.state.current + 1)}
          styleLastPageLink={this.props.styleLastPageLink}
        />
        <LastPageComponent
          disabled={!hasNext}
          onClick={() => this.setCurrent(this.state.total)}
          styleLastPageLink={this.props.styleLastPageLink}
        />
      </div>
    );
  }
}

export default Pagination;
