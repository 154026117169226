import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import to_qs from 'utils/queryString';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import {
  Chip,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import ExportModal from 'components/ExportModal';
import moment from 'moment';
import AutoComplete from 'components/Form/AutoComplete';

const FilterModal = ({ open, handleOpen, onFilter }) => {
  const [form, setForm] = useState({
    country: ''
  });
  const [filters, setFilters] = useState([]);

  const handleApplyFilter = () => {
    let query = {
      country: []
    };
    filters.forEach((n) => {
      if (n.name === 'country') {
        query.country.push(n.value);
      }
    });
    onFilter(query);
  };

  const addFilter = (name) => {
    let value = {
      name,
      label: form[name].label,
      value: form[name].value,
    };
    if (form[name]) {
      const isAny = filters.filter((n) => n.name === name && n.value === form[name].value).length > 0;
      if (!isAny) {
        setFilters([...filters, value]);
      }
    }
    setForm({
      country: ''
    });
  };

  const handleDelete = (idx) => {
    let data = filters.filter((n, index) => index !== idx);
    setFilters(data);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent style={{ padding: 40 }}>

        <div>
          <Typography
            style={{
              marginBottom: 12,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Current filter
          </Typography>
          <div>
            {filters.map((n, i) => (
              <Chip
                key={i}
                label={n.label}
                onDelete={() => handleDelete(i)}
                style={{ marginRight: 10 }}
              />
            ))}
          </div>
        </div>

        <div>
          <Typography
            style={{
              marginBottom: 20,
              marginTop: 85,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Filter Data
          </Typography>

          <Grid container spacing={3} alignItems="center">
						<Grid item sm={6}>
							<AutoComplete
								shrink
								label="Country"
								name="country"
								onChange={(val) =>
									setForm({
										...form,
										country: {
											label: val.label,
											value: val.value,
										},
									})
								}
								variant="outlined"
								size="medium"
								value={form.country}
								url="/country"
								urlName="keyword"
								dataStructure={(n) => ({
									...n,
									label: n.name,
									value: n.id,
								})}
							/>
						</Grid>
						<Grid item sm={6}>
							<Button
								onClick={() => addFilter("country")}
								style={{
									marginTop: 15,
									background: "#ED901A",
									borderRadius: 8,
									fontSize: 14,
									color: "#ffffff",
									textTransform: "capitalize",
									padding: "11px 26px",
								}}
							>
								Add
							</Button>
						</Grid>
					</Grid>
        </div>

      </DialogContent>

      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            handleApplyFilter();
            handleOpen(false);
          }}
          style={{
            background: '#ED901A',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Apply Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Index = () => {
  const slug = useParams().id;
  const history = useHistory();
  const [keyword, setKeyword] = useState('');

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [query, setQuery] = useState({
    country: []
  });

  return (
    <div className="app-wrapper appointment-page">
      <ContainerHeader
        title="Business Inquiry"
        breadcrumb={[{ link: '/business-inquiry', label: 'Business Inquiry' }]}
      />

      {/* <div className="action-btn">
        <Button
          style={{
            border: '1px solid #ED901A',
            borderRadius: 8,
            color: '#ED901A',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => setIsOpenExport(true)}
        >
          Export
        </Button>
      </div> */}

      <Tables
        name={`business/Businessparticipantby/${slug}`}
        addQueryString={to_qs(query)}
        setKeyword={setKeyword}
        withFilterModal={(val) => setIsOpenFilter(val)}
        withSearch={{ placeholder: 'Search for name or email' }}
        columnHead={[
          { id: 'id', label: 'No', disableSort: true },
          { id: 'fullName', label: 'Full Name' },
          { id: 'email', label: 'Email' },
          { id: 'phoneNumber', label: 'Nomor Hp', disableSort: true },
          { id: 'created_at', label: 'Register on' },
          { id: '', label: 'Action', disableSort: true },
        ]}
        columnBody={[
          { parent:'number' },
          { parent:(n) => n.fullName },
          { parent:(n) => n.email },
          { parent:(n) => n.phoneNumber },
          { parent: (n) => moment(n.createdAt).format('DD MMM YYYY') },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/Business-inquiry/participant/${n.id}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
              >
                View Details
              </Button>
            ),
          },
        ]}
        orderByTerm='sortBy' 
        orderTerm='sort'
      />

      <FilterModal
        open={isOpenFilter}
        handleOpen={(val) => setIsOpenFilter(val)}
        onFilter={(val) => setQuery({ ...val })}
        filter={query}
      />

      {isOpenExport && (
        <ExportModal
          name="appointment"
          customName="appointments"
          filter={query}
          keyword={keyword}
          open={isOpenExport}
          handleOpen={(val) => setIsOpenExport(val)}
        />
      )}
    </div>
  );
};

export default Index;
