import React from "react";
import { getUser } from "utils/localStorage";
import AppointmentAdmin from "./AppointmentListAdmin";
import AppointmentExhibitor from "./AppointmentListExhibitor";

const Index = () => {
	if (
		getUser()?.user?.roles.includes("administrator") ||
		getUser()?.user?.roles.includes("operator")
	) {
		return <AppointmentAdmin />;
	} else if (getUser()?.user?.roles.includes("exhibitor")) {
		return <AppointmentExhibitor />;
	}
};

export default Index;
