import React from 'react';
import { connect } from 'react-redux';
import history from 'utils/history';
import { Breadcrumbs, Link } from '@material-ui/core';
import { isPlural, singular } from 'pluralize';
import { NavigateNext } from '@material-ui/icons';

const home = {
  label: <span>Home</span>,
  link: '/',
};

const BreadCrumb = ({ title, url, breadcrumb }) => {
  let data = url.pathname.split('/');
  // check if page is home
  data =
    data.length === 2 && new Set(data).size !== data.length
      ? data.filter((n, i) => data.indexOf(n) === i)
      : data;
  const labelData = data.map((n) => {
    if (n === '') {
      return <span>Home</span>;
    } else if (isPlural(n)) {
      return `List of ${n}`;
    } else if (n === 'create') {
      return `Create ${singular(data[1])}`;
    } else if (n === 'edit') {
      return `Edit ${singular(data[1])}`;
    } else if (parseInt(n, 10)) {
      return `View ${singular(data[1])}`;
    }
    return n;
  });

  return (
    <div className="page-heading">
      <h2 className="title mb-3 mb-sm-0">{title}</h2>
      <Breadcrumbs className="breadcrumb-custom" separator={<NavigateNext />}>
        {breadcrumb
          ? [home, ...breadcrumb].map((sub, i) => (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  history.push(sub.link);
                }}
                active={(labelData.length === i + 1).toString()}
                component={labelData.length === i + 1 ? 'span' : 'a'}
                key={i}
                href={sub.link}
              >
                {sub.label}
              </Link>
            ))
          : labelData.map((sub, i) => (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    i > 0 ? `${data.slice(0, i + 1).join('/')}` : '/'
                  );
                }}
                active={(labelData.length === i + 1).toString()}
                component={labelData.length === i + 1 ? 'span' : 'a'}
                key={i}
                href={i > 0 ? `${data.slice(0, i + 1).join('/')}` : '/'}
              >
                {sub}
              </Link>
            ))}
      </Breadcrumbs>
    </div>
  );
};

const mapStateToProps = ({ router }) => ({
  url: router.location,
});

export default connect(mapStateToProps)(BreadCrumb);
