import {all} from 'redux-saga/effects';
import oauthSagas from './Oauth';
import moduleSagas from './Module';

export default function* rootSaga(getState) {
  yield all([
    oauthSagas(),
    moduleSagas(),
  ]);
}
