import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';

const Navigation = (props) => {
  const { menuItems } = props;
  return (
    <List
      style={{ marginLeft: '5px' }}
      component="nav"
      disablePadding
      className="side-nav-menu"
    >
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.type === 'section' ? (
            <NavSection {...item} />
          ) : item.type === 'collapse' ? (
            <NavCollapse {...item} />
          ) : item.type === 'item' ? (
            <NavMenuItem {...item} />
          ) : null}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Navigation;
