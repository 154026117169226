import actionTypes from '../actions/Setting';

const initialSettings = {
  navCollapsed: false,
  drawerType: actionTypes.FIXED_DRAWER,
  themeColor: actionTypes.BLUE,
  width: window.innerWidth,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };
    case actionTypes.TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };
    case actionTypes.DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType,
      };
    case actionTypes.WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case actionTypes.THEME_COLOR:
      return {
        ...state,
        themeColor: action.color,
      };
    case actionTypes.SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
