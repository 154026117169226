import React from 'react';
import { TextField, CircularProgress, Button } from '@material-ui/core';
import { Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from 'redux/actions';
import Header from './components/Header';
import Footer from './components/Footer';
import { LoginSchemeValidations } from './schemeValidations';
import withFormik from 'utils/withFormik';

const initialValueForm = {
  email: '',
  password: '',
};

const Login = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    // handleSubmit,
    isValid,
    dirty,
    // data,
  } = props;

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.global);

  const handleSubmit = () => {
    if (isValid) {
      dispatch(
        signIn({
          name: values.email,
          password: values.password,
        })
      );
    }
  };

  return (
    <div className="app-wrapper auth-page">
      <Header />
      {/* <img className="bg-item-left" src={BgItemLeft} alt="bg-item" /> */}
      {/* <img className="bg-item-right" src={BgItemRight} alt="bg-item" /> */}

      <Container>
        <Row>
          <Col lg={6} md={8} className="offset-lg-3 offset-md-2">
            <div className="card padding-vertical">
              <h3 className="title">Login to INA-ACCESS Dashboard</h3>
              <TextField
                label="Email Address"
                name="email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onKeyDown={(e) => {
                  e.keyCode === 13 && handleSubmit();
                }}
              />
              <TextField
                label="Password"
                type="password"
                name="password"
                margin="normal"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onKeyDown={(e) => {
                  e.keyCode === 13 && handleSubmit();
                }}
              />
              {/* <h3 className="forgot-password-text">
                <Link to="/forgot-password">Forgot password?</Link>
              </h3> */}
              <div className="btn-wrap">
                <Button
                  disabled={!(dirty && isValid)}
                  onClick={handleSubmit}
                  className={!(dirty && isValid) ? 'button-disabled' : 'button'}
                >
                  {loading ? (
                    <CircularProgress style={{ color: 'white' }} size={25} />
                  ) : (
                    'Login'
                  )}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};
const FormikLogin = withFormik(
  Login,
  LoginSchemeValidations,
  initialValueForm,
  null
);
export default FormikLogin;
