import React, { Component } from 'react';

import { useHistory } from 'react-router-dom';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';
import ContainerHeader from 'components/BreadCrumbTitle';
import {Link} from 'react-router-dom'

const Index = (props) => {
  const history = useHistory();
    return (
      <div className="app-wrapper">
        <ContainerHeader title={'List of Users'} />
<div className="action-btn">
      <Link to='create-user'>
      <Button
          style={{
            background: '#ED901A',
            padding: '8px 24px',
            borderRadius: 8,
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
          }}
           >
          Add Perwakilan
        </Button></Link>
	  
      </div>
        <Tables
          name="users"
          acl="users"
          // addQuery={{ category: 'customer' }}
          withFilter
          columnHead={[
            { id: 'Name', canFilter: true, label: 'Name' },
            { id: 'Perwakilan', canFilter: true, label: 'Perwakilan' },
            { id: 'status', canFilter: true, label: 'Status' },
            { id: '', canFilter: false, label: 'Action' },
          ]}
          columnBody={[
            { parent: 'name' },
            { parent: 'companyName' },
            { parent: 'status' },
	    {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/users/view/${n.id}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
              >
                View
              </Button>
            ),
          },
	
          ]}
                 />
      </div>
    );
  }

export default Index;
