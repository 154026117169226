import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutoCompleteComponent from 'components/Form/AutoComplete';
import { countries } from 'data';
import { add, list, load } from 'redux/actions/Module';
import { connect, useSelector, useDispatch } from 'react-redux';

import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';

const initialValueForm = {
  country: '',
  regionId: '3',
  status: '',
};
const handleSubmit = (payload, ctx) => {
  const { regionId, country, status, countryValues } = payload;
  ctx.props.add({
    name: 'countrycms',
    data: {
      code: countryValues.code,
      name: country,
      status,
      regionId,
    },
    notLink: true,
    onSuccess: (res) => {
      ctx.props.handleOpen(false);
    },
  });
};

const Create = ({ open, handleOpen, ...props }) => {
  const {
    values,
    touched,
    errors,
    // handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    // data,
    resetForm,
  } = props;

  const dispatch = useDispatch();
  const { countrycms } = useSelector((state) => state.module.view);
  const [errorCountry, setErrorCountry] = useState(false);
  const [helperTextCountry, setHelperTextCountry] = useState('');

  useEffect(() => {
    resetForm();
    setFieldValue('status', 'active');
    dispatch(
      load({
        name:
          'countrycms?keyword=&skip=0&limit=250&order=action&orderMethod=DESC',
        customName: 'countrycms',
      })
    );
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    const codeLowerCase = values?.countryValues?.code?.toLowerCase();
    // console.log(codeLowerCase);
    const findIndex = countrycms?.data?.findIndex(
      (val) => val?.code === codeLowerCase
    );
    // console.log(findIndex);
    if (findIndex && findIndex > 0) {
      setErrorCountry(true);
      setHelperTextCountry('Country has already been added.');
    } else {
      setErrorCountry(false);
      setHelperTextCountry('');
    }
    // eslint-disable-next-line
  }, [values.country]);

  const handleChangeStatus = (event) => {
    setFieldValue('status', event.target.value);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Add New Country
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Autocomplete
                name="country"
                style={{ marginTop: 15 }}
                options={countries}
                value={values.countryValues}
                onChange={(e, val) => {
                  setFieldValue(
                    'country',
                    val && val?.label !== null ? val.label : ''
                  );
                  setFieldValue('countryValues', val !== null ? val : {});
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    name="country"
                    error={
                      Boolean(touched.country && errors.country) ||
                      Boolean(errorCountry && helperTextCountry)
                    }
                    helperText={
                      (touched.country && errors.country) ||
                      (errorCountry && helperTextCountry)
                    }
                    value={values.country}
                    onBlur={handleBlur}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid sm={12} item>
              <AutoCompleteComponent
                shrink
                label="Region"
                name="region"
                onChange={(val) => {
                  setFieldValue(
                    'regionId',
                    val && val?.id !== null && val?.id !== 0 ? val.id : ''
                  );
                  setFieldValue('regionIdValues', val !== null ? val : {});
                }}
                variant="outlined"
                size="medium"
                value={values.regionIdValues}
                url="/region/getlist"
                urlName="keyword"
                error={errors.regionId}
                helperText={errors.regionId}
                dataStructure={(n) => ({
                  ...n,
                  label: n.title,
                  value: n.title,
                })}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  onChange={handleChangeStatus}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                  onBlur={handleBlur}
                  name="status"
                  label="Status"
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={
            !(dirty && isValid) || Boolean(errorCountry && helperTextCountry)
          }
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateFormik = withFormik(
  Create,
  schemeValidations,
  initialValueForm,
  handleSubmit
);

export default connect(null, { add, list })(CreateFormik);
