import React from 'react';
import { useHistory } from 'react-router-dom';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';

const Index = () => {
  const history = useHistory();

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Speakers"
        breadcrumb={[{ link: '/speakers', label: 'Speakers' }]}
      />
      <div className="action-btn">
        <Button
          style={{
            background: '#ED901A',
            borderRadius: 8,
            padding: '8px 24px',
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
          }}
          onClick={() => history.push('/speakers/create')}
        >
          Add Speaker
        </Button>
      </div>
      <Tables
        title="Speakers"
        name="speaker"
        minWidth={1200}
        viewDetail={{ name: 'banners', by: 'slug' }}
        columnHead={[
          { id: 'id', disableSort: false, label: 'No' },
          { id: 'name', label: 'Speaker Name' },
          { id: 'event', label: 'Event' },
          { id: 'status', label: 'Status' },
          { id: '', label: 'Action' },
        ]}
        // disableFooter
        columnBody={[
          { parent: 'number' },
          { parent: 'fullName' },
          { parent: (n) => (n.event ? n.event.title : '') },
          { parent: 'status' },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/speakers/${n.id}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                  fontSize: 12,
                }}
              >
                View
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Index;
