import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';
import CreateCategory from './Category/Create';
import EditCategory from './Category/Edit';
import moment from 'moment';

const Index = (props) => {
  const history = useHistory();

  const [openCreateCategory, setOpenCreateCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [dataEdit, setDataEdit] = useState({});

  const handleCloseCategory = (res) => {
    setOpenEditCategory(false);
    setReloadTable(!reloadTable);
  };

  const editCategory = (data) => {
    setDataEdit(data);
    setReloadTable(!reloadTable);
    setOpenEditCategory(true);
  };

  const handleCloseCreateCategory = (res) => {
    setOpenCreateCategory(false);
    setReloadTable(!reloadTable);
  };

  return (
    <div className="app-wrapper news-page">
      <ContainerHeader
        title="Press Releases"
        breadcrumb={[{ link: '/press-releases', label: 'Press Releases' }]}
      />

      <div className="action-btn">
        <Button
          style={{
            background: '#ED901A',
            padding: '8px 24px',
            borderRadius: 8,
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
          }}
          onClick={() => props.history.push('/press-releases/create')}
        >
          Add Press Release
        </Button>
      </div>

      <Tables
        title="Press Releases"
        name="news"
        withSearch={{ placeholder: 'Search for a press release...' }}
        columnHead={[
          { id: '', canFilter: false, label: 'No', disableSort: false },
          { id: 'title', canFilter: false, label: 'Title' },
          { id: 'category', canFilter: false, label: 'Category' },
          { id: 'status', canFilter: false, label: 'Status' },
          { id: 'published_date', canFilter: false, label: 'Published At' },
          { id: '', canFilter: false, label: 'Action' },
        ]}
        columnBody={[
          { parent: 'number' },
          { parent: 'title' },
          { parent: (n) => n?.category?.categoryName },
          { parent: 'status' },
          {
            parent: (n) => moment(n.publishedDate).format('DD MMM YYYY'),
          },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/press-releases/${n.id}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
              >
                View
              </Button>
            ),
          },
        ]}
      />

      <div className="action-btn">
        <Button
          style={{
            background: '#ED901A',
            padding: '8px 24px',
            borderRadius: 8,
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
            marginTop: 40,
          }}
          onClick={() => setOpenCreateCategory(true)}
        >
          Add Category
        </Button>
      </div>

      <Tables
        isSinglePage
        title="Category"
        name="news/category"
        reload={reloadTable}
        columnHead={[
          { id: '', canFilter: false, label: 'No', disableSort: false },
          { id: 'categoryName', canFilter: false, label: 'Category Name' },
          { id: 'order', canFilter: false, label: 'Order' },
          { id: 'created_at', canFilter: false, label: 'Created At' },
          { id: 'status', canFilter: false, label: 'Status' },
          { id: '', canFilter: false, label: 'Action' },
        ]}
        columnBody={[
          { parent: 'number' },
          { parent: 'categoryName' },
          { parent: 'order' },
          { parent: (n) => moment(n.createdAt).format('DD MMM YYYY') },
          { parent: 'status' },
          {
            parent: (n) => (
              <Button
                onClick={() => editCategory(n)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
              >
                Edit
              </Button>
            ),
          },
        ]}
      />

      {openCreateCategory && (
        <CreateCategory
          open={openCreateCategory}
          handleOpen={(val) => handleCloseCreateCategory(val)}
        />
      )}

      {openEditCategory && (
        <EditCategory
          dataEdit={dataEdit}
          open={openEditCategory}
          handleOpen={(val) => handleCloseCategory(val)}
        />
      )}
    </div>
  );
};

export default Index;
