import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';
import TextEditor from 'components/Form/TextEditor';
import UploadImage from 'components/Upload/UploadImage';
import { load, add } from 'redux/actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { uploadBase64Image } from 'utils/uploadMedia';

const initialValueForm = {
  status: { value: 'draft', title: 'Draft' },
  title: '',
  body: '',
  excerpt: '',
  thumbnail: [],
  banner: [],
  publishedDate: '',
  category: '',
};

const handleFormSubmit = (payload, ctx) => {
  const {
    status,
    title,
    body,
    excerpt,
    thumbnail,
    banner,
    publishedDate,
    category,
  } = payload;

  const imgRegex = new RegExp(/data:image\/[^;]+;base64[^"]+/g)
  const imgSrc = body.match(imgRegex) || []
  
  Promise.all(imgSrc.map(src => 
    new Promise( async (resolve) => {
      const res = await uploadBase64Image(src, 'others')
      resolve(res)
    })
  )).then(values => {
    for (let i = 0; i < values.length; i++) {
      if (values[i]?.status === 200) {
        payload.body = payload.body.replace(imgSrc[i], values[i].data.originalUrl)
      }
    }

    ctx.props.add({
      name: 'news',
      data: {
        title,
        content: payload.body,
        excerpt,
        thumbnailImage: thumbnail[0],
        bannerImage: banner[0],
        publishedDate,
        category: category.value,
        status: status.value,
      },
      notLink: true,
      onSuccess: () => {
        ctx.props.history.push('/press-releases');
      },
    });
  }).catch(e => {
    console.error('Error occured:', e.message)
  })
};

const Create = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.module.view);

  useEffect(() => {
    dispatch(
      load({
        name: 'news/category?status=active',
        customName: 'categories',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listCategories = categories?.data?.map((n) => ({
    value: n.id,
    title: n.categoryName,
  }));

  return (
    <div className="app-wrapper news-page">
      <ContainerHeader
        title="Add Press Release"
        breadcrumb={[
          { link: '/press-releases', label: 'Press Release' },
          { link: '', label: 'Add Press Release' },
        ]}
      />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Add Press Release',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Add Press Release',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={values.status}
                onChange={(e, val) =>
                  setFieldValue('status', val !== null ? val : '')
                }
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Press Release Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="title"
                label="Title"
                margin="normal"
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="body"
                label="Body"
                value={values.body}
                onChange={(val) =>
                  setFieldValue('body', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'body' } })}
                error={Boolean(touched.body && errors.body)}
                helperText={touched.body && errors.body}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                multiline
                rows={2}
                variant="outlined"
                name="excerpt"
                label="Excerpt"
                margin="normal"
                error={Boolean(touched.excerpt && errors.excerpt)}
                helperText={touched.excerpt && errors.excerpt}
                value={values.excerpt}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Autocomplete
                name="category"
                options={listCategories}
                value={values.statusField}
                onChange={(e, val) =>
                  setFieldValue('category', val !== null ? val : '')
                }
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    name="category"
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                    value={values.statusField}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography
                className="title"
                style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}
              >
                Thumbnail
              </Typography>

              <UploadImage
                name="thumbnail"
                value={values.thumbnail}
                onImageChange={(val) => setFieldValue('thumbnail', val)}
                error={errors.thumbnail}
                max={1}
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 10mb
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended thumbnail size is 320px x 200px (.jpg, .jpeg, and
                  .png)
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography
                className="title"
                style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}
              >
                Banner
              </Typography>

              <UploadImage
                name="banner"
                value={values.banner}
                onImageChange={(val) => setFieldValue('banner', val)}
                error={errors.banner}
                max={1}
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 10mb
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended banner size is xxxpx x xxxpx (.jpg, .jpeg, and
                  .png)
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="date"
                variant="outlined"
                name="publishedDate"
                label="Published Date"
                margin="normal"
                value={values.publishedDate}
                error={Boolean(touched.publishedDate && errors.publishedDate)}
                helperText={touched.publishedDate && errors.publishedDate}
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{
                  min: moment(new Date()).format('YYYY-MM-DD'),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

const FormikCreate = withFormik(
  Create,
  schemeValidations,
  initialValueForm,
  handleFormSubmit
);

export default connect(null, { add })(FormikCreate);
