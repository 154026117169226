import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import to_qs from 'utils/queryString';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import {
  Chip,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import ExportModal from 'components/ExportModal';
import moment from 'moment';
import AutoComplete from 'components/Form/AutoComplete';

const FilterModal = ({ open, handleOpen, onFilter, filter }) => {
  const [form, setForm] = useState({
    sender: '',
    recipient: '',
    event: '',
  });
  const [filters, setFilters] = useState([]);

  const handleApplyFilter = () => {
    let query = {
      sender: [],
      recipient: [],
      event: [],
    };
    filters.forEach((n) => {
      if (n.name === 'sender') {
        query.sender.push(n.value);
      } else if (n.name === 'recipient') {
        query.recipient.push(n.value);
      } else if (n.name === 'event') {
        query.event.push(n.value.label);
      }
    });
    onFilter(query);
  };

  const addFilter = (name) => {
    let value = {
      name,
      value: form[name],
    };
    console.log(value);
    if (form[name]) {
      setFilters([...filters, value]);
    }
    setForm({
      sender: '',
      recipient: '',
      event: '',
    });
  };

  const handleDelete = (idx) => {
    let data = filters.filter((n, index) => index !== idx);
    setFilters(data);
  };
  // const query = filter?.event?.length !== 0 ? filter?.event : filters;
  useEffect(() => {
    if (filter?.event?.length !== 0) {
      let filterTemp = [];
      for (let index = 0; index < filter?.event?.length; index++) {
        const element = filter?.event[index];
        console.log(element);
        let value = {
          name: 'event',
          value: {
            label: element,
          },
        };
        filterTemp.push(value);
      }
      setFilters(filterTemp);

      // filter?.event?.map((val, index) => {
      //   let value = {
      //     name: 'event',
      //     value: {
      //       label: val,
      //     },
      //   };
      //   setFilters([...filters, value]);
      //   return value;
      // });
    }
    // eslint-disable-next-line
  }, [filter]);
  console.log(filter, 'filter');
  console.log(filters, 'filters');

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 12,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Current filter
          </Typography>
          <div>
            {filters?.map((n, i) => (
              <Chip
                key={i}
                label={n.value.label}
                onDelete={() => handleDelete(i)}
                style={{ marginRight: 10 }}
              />
            ))}
          </div>
        </div>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              marginTop: 85,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Filter Data
          </Typography>

          <Grid container spacing={3} alignItems="center">
            <Grid item sm={6}>
              <AutoComplete
                shrink
                label="Events"
                name="event"
                onChange={(val) =>
                  setForm({
                    ...form,
                    event: {
                      label: val.label,
                      value: val.value,
                    },
                  })
                }
                variant="outlined"
                size="medium"
                value={form.event}
                url="/events/list"
                urlName="keyword"
                dataStructure={(n) => ({
                  ...n,
                  label: n.eventName,
                  value: n.id,
                })}
              />
            </Grid>
            <Grid item sm={6}>
              <Button
                onClick={() => addFilter('event')}
                style={{
                  marginTop: 15,
                  background: '#ED901A',
                  borderRadius: 8,
                  fontSize: 14,
                  color: '#ffffff',
                  textTransform: 'capitalize',
                  padding: '11px 26px',
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleApplyFilter();
            handleOpen(false);
          }}
          style={{
            background: '#ED901A',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Apply Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Index = () => {
  const history = useHistory();

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [query, setQuery] = useState({
    sender: [],
    recipient: [],
    event: [],
  });

  return (
    <div className="app-wrapper appointment-page">
      <ContainerHeader
        title="My Appointments"
        breadcrumb={[{ link: '/appointments', label: 'My Appointments' }]}
      />

      <div className="action-btn">
        <Button
          style={{
            border: '1px solid #ED901A',
            borderRadius: 8,
            color: '#ED901A',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => setIsOpenExport(true)}
        >
          Export
        </Button>
      </div>

      <Tables
        title="Appointments"
        name="appointment"
        addQueryString={to_qs(query)}
        withFilterModal={(val) => setIsOpenFilter(val)}
        withSearch={{ placeholder: 'Search for a company...' }}
        columnHead={[
          { id: 'id', label: 'No' },
          { id: 'sender', label: 'Sender Company' },
          { id: 'recipient', label: 'Recipient Exhibitor' },
          { id: 'event', label: 'Event' },
          { id: 'created_at', label: 'Created At' },
          { id: '', label: 'Action' },
        ]}
        columnBody={[
          { parent: 'number' },
          { parent: (n) => n.user && n.user.companyName },
          { parent: (n) => n.exhibitor && n.exhibitor.companyName },
          { parent: (n) => n.event && n.event.title },
          { parent: (n) => moment(n.createdAt).format('DD MMM YYYY') },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/appointments/${n.id}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                }}
              >
                View Details
              </Button>
            ),
          },
        ]}
      />

      {isOpenFilter && (
        <FilterModal
          open={isOpenFilter}
          handleOpen={(val) => setIsOpenFilter(val)}
          onFilter={(val) => setQuery({ ...val })}
          filter={query}
        />
      )}

      {isOpenExport && (
        <ExportModal
          name="appointment"
          customName="appointments"
          filter={query}
          open={isOpenExport}
          handleOpen={(val) => setIsOpenExport(val)}
        />
      )}
    </div>
  );
};

export default Index;
