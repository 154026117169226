import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { notif, confirmForm, dialogGlobal } from 'redux/actions/Global';
import { Button } from '@material-ui/core';
import NavigationPrompt from 'react-router-navigation-prompt';
import Confirm from './Confirm';

const ActionComponent = ({ label, icon, disabled, style, actionClick }) => {
  const styles = () => {
    if (disabled) {
      delete style.background;
      delete style.color;
      delete style.border;
    }
    return style;
  };
  return (
    <div className="action-wrap">
      <Button
        disabled={disabled}
        style={{
          background: disabled && '#E7E7E7',
          color: disabled && '#fff',
          border: disabled && 'none',
          ...styles(),
        }}
        onClick={actionClick}
      >
        <span className="board-label">{icon ? icon : label}</span>
      </Button>
    </div>
  );
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      dialog: false,
      dialogClick: null,
      confirmButton: '',
      confirmTitle: '',
      confirmMessage: '',
      CustomContent: null,
    };
  }

  handleConfirm = ({ confirmAction, ...others }) => {
    this.setState({ dialog: true, dialogClick: confirmAction, ...others });
  };

  handleAuth = () => {
    this.props.notif({
      open: true,
      variant: 'error',
      message: 'User is Not Authorized',
    });
    this.props.history.push('/');
  };

  render() {
    const { children, actions, loading, noCard, disableLink } = this.props;

    const {
      dialog,
      dialogClick,
      confirmButton,
      confirmTitle,
      confirmMessage,
      CustomContent,
    } = this.state;

    return (
      <div
        className={!noCard ? 'board-root' : ''}
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <NavigationPrompt when={disableLink}>
          {({ onConfirm, onCancel }) => (
            <Confirm
              open
              handleClose={onCancel}
              handleAction={() => {
                this.props.confirmForm(false);
                onConfirm();
              }}
              handleContent={{
                button: 'OK',
                title: '',
                message:
                  "You haven't saved your changes. Do you want to continue?",
              }}
            />
          )}
        </NavigationPrompt>

        <Confirm
          open={dialog}
          handleClose={() => this.setState({ dialog: false })}
          handleAction={(e) => {
            this.setState({ dialog: false }, () =>
              this.props.confirmForm(false)
            );
            return dialogClick(e);
          }}
          handleContent={{
            button: confirmButton,
            title: confirmTitle,
            message: confirmMessage,
          }}
          CustomContent={CustomContent}
        />

        <Confirm open={loading} handleClose={() => null} loading />

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          onChange={() => this.props.confirmForm(true)}
          style={{ flex: '1 0 auto' }}
        >
          {children}
        </form>

        <div className="board-action-wrapper">
          {actions &&
            actions.map((n, i) => {
              const params = {};
              let actionClick = n.onClick;
              if (n.confirm) {
                params.confirmAction = n.onClick;
                params.confirmButton = n.confirmButton;
                params.confirmTitle = n.confirmTitle;
                params.confirmMessage = n.confirmMessage;
                actionClick = () => this.handleConfirm(params);
              }
              return (
                <ActionComponent
                  key={i}
                  style={n.style}
                  label={n.label}
                  icon={n.icon}
                  disabled={n.noDisabled ? false : this.props.disabled}
                  actionClick={(e) => {
                    this.props.confirmForm(false);
                    actionClick(e);
                  }}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.global.loading,
  disableLink: state.global.confirmForm,
  dialogData: state.global.dialog,
});

const mapDispatchToProps = {
  dialogGlobal,
  notif,
  confirmForm,
};

Index.defaultProps = {
  disabled: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
