import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppLayout from '../containers/AppLayout';

export const OtherRoute = () => (
  <Route
    path="*"
    render={(props) =>
      localStorage.getItem('user') ? (
        <Redirect
          to={{
            pathname: '/error',
            state: { from: props.location },
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { search } = props.location;
      const token = search.replace(/\?token=/, '');

      return !localStorage.getItem('user') && token ? (
        <Component {...props} />
      ) : localStorage.getItem('user') && token ? (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      ) : localStorage.getItem('user') ? (
        <AppLayout>
          <Component {...props} />
        </AppLayout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export const ShouldRoute = ({ component: Component, location, ...rest }) => {
  const { from } = location.state || {
    from: { pathname: '/', state: { from: location } },
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.getItem('user') ? (
          <Component {...props} />
        ) : (
          <Redirect to={from} />
        )
      }
    />
  );
};
