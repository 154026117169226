import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Board from 'components/Board';
import { add,load } from 'redux/actions/Module';
import withFormik from 'utils/withFormik';
import schemeValidations from './schemeValidations';
import { connect, useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
const initialValueForm = {
  name: '',
  companyEmail: '',
  password: '',
  country:'',
  companyName:'',
  phoneNumber:'',
};

const handleFormSubmit = (payload, ctx) => {
  const {
    name,
    companyEmail,
    password,
    country,
    companyName,
    phoneNumber,
  } = payload;

  ctx.props.add({
    name: 'users/perwakilan',
    data: {
      name,
      companyEmail,
      password,
      country: country.value,
      companyName,
      phoneNumber,
    },
      notLink: true,
      onSuccess: () => {
        ctx.props.history.push('/users');
      },
    });
 
};

const CreateUserPerwakilan = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.module.view);

  useEffect(() => {
    dispatch(
      load({
        name:'countrycms?keyword=&skip=0&limit=1000&order=&orderMethod=desc',
        customName: 'country',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listcountrycms = country?.data?.map((n) => ({
    value: n.name,
    title: n.name,
  }));
  

    return (
      
      <div className="app-wrapper">
       <ContainerHeader
        title="Add User Perwakilan"
        breadcrumb={[
          { link: '/users', label: 'User Perwakilan' },
          { link: '', label: 'Add User Perwakilan' },
        ]}
      />
        <Board
        actions={[
          {
            label: 'Add Perwakilan',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Add Perwakilan',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
         <div className="section">
       
       </div>
       <div className="section">
       
       
         <TextField
            fullWidth
            required
            variant="outlined"
            name="name"
            label="Username"
            margin="normal"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            required
            variant="outlined"
            name="phoneNumber"
            label="phone Number"
            margin="normal"
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
            value={values.phoneNumber}
            onBlur={handleBlur}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField 
            fullWidth
            required
            variant="outlined"
            name="companyName"
            label="Perwakilan"
            margin="normal"
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
            value={values.companyName}
            onBlur={handleBlur}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
	   <Autocomplete
                name="country"
                options={listcountrycms}
                value={values.statusField}
                onChange={(e, val) =>
                  setFieldValue('country', val !== null ? val : '')
                }
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    name="country"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    value={values.statusField}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                />
          <TextField
            fullWidth
            required
            type="email"
            name="companyEmail"
            label="Email"
            margin="normal"
            variant="outlined"
            size="small"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            required
            name="password"
            label="Password"
            type="password"
            margin="normal"
            variant="outlined"
            size="small"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            value={values.password}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
         </div>
        </Board>
      </div>
    );
  }


const CreateFormik = withFormik(
  CreateUserPerwakilan,
  schemeValidations,
  initialValueForm,
  handleFormSubmit
);

export default connect(null, { add })(CreateFormik);
