import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, MenuItem } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { add, list } from 'redux/actions/Module';
import withFormik from 'utils/withFormik';
import qs from 'querystringify';
import { createSchemeValidations } from './schemeValidations';
import AutoComplete from 'components/Form/AutoComplete';

const initialValueForm = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  verifyPassword: '',
  roles: '',
  userCategory: '',
  companyId: '',
};

const handleFormSubmit = (payload, ctx) => {
  const {
    firstName,
    lastName,
    email,
    password,
    verifyPassword,
    roles,
    userCategory,
    properties,
    companyId,
  } = payload;
  ctx.props.add({
    name: 'users',
    data: {
      firstName,
      lastName,
      email: email.toLowerCase(),
      password,
      verifyPassword,
      roles: [roles],
      category: userCategory,
      propertyIds: Array.isArray(properties) && properties.map((n) => n._id),
      companyId: companyId._id,
    },
  });
};

class Create extends Component {
  componentDidMount() {
    this.props.list({
      name: 'user-roles',
      customName: 'userRoles',
    });
  }

  handleBreadCrumb = (search) => {
    const { view } = this.props;
    const id = view && view.users && view.users._id;
    switch (true) {
      case search.hasOwnProperty('category'):
        const label = this.props.location.pathname.includes('create')
          ? 'Create Staff'
          : 'View Staff';
        return [
          { link: '/staffs', label: 'list of staffs' },
          { link: `/staffs/${id}?category=${search.category}`, label },
        ];
      default: {
        const label = this.props.location.pathname.includes('create')
          ? 'Create User'
          : 'View User';
        return [
          { link: '/users', label: 'list of users' },
          { link: `/users/${id}`, label },
        ];
      }
    }
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
      handleBlur,
      data,
      isValid,
      dirty,
    } = this.props;
    const search = qs.parse(this.props.history.location.search);
    return (
      <div className="app-wrapper">
        <ContainerHeader
          title={
            search.hasOwnProperty('category') ? 'Create Staff' : 'Create User'
          }
          breadcrumb={this.handleBreadCrumb(search)}
        />
        <Board acl="users" create={handleSubmit} disabled={!(dirty && isValid)}>
          <TextField
            fullWidth
            required
            name="firstName"
            label="First Name"
            type="text"
            margin="normal"
            variant="outlined"
            size="small"
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            onBlur={handleBlur}
            value={values.firstName}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            name="lastName"
            label="Last Name"
            margin="normal"
            variant="outlined"
            size="small"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            fullWidth
            required
            type="email"
            name="email"
            label="Email"
            margin="normal"
            variant="outlined"
            size="small"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            id="roles"
            required
            name="roles"
            select
            fullWidth
            label="Roles"
            variant="outlined"
            size="small"
            value={values.roles}
            onChange={handleChange}
            margin="normal"
            onBlur={handleBlur}
            error={Boolean(touched.roles && errors.roles)}
            helperText={touched.roles && errors.roles}
          >
            {data.userRoles &&
              data.userRoles.data.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            id="userCategory"
            name="userCategory"
            required
            select
            fullWidth
            label="User Category"
            variant="outlined"
            size="small"
            value={values.userCategory}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.userCategory && errors.userCategory)}
            helperText={touched.userCategory && errors.userCategory}
            margin="normal"
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="customer">Customer</MenuItem>
            <MenuItem value="trainer">Trainer</MenuItem>
          </TextField>
          {values.userCategory === 'admin' && (
            <AutoComplete
              required
              oneFetch
              label="Select Company"
              onChange={(e) => setFieldValue('companyId', e)}
              urlName="name"
              variant="outlined"
              size="small"
              value={values.companyId}
              url="/company"
              dataStructure={(n) => ({ ...n, label: n.name, value: n._id })}
            />
          )}
          <React.Fragment>
            {values.userCategory === 'admin' &&
              values.companyId &&
              values.companyId._id !== null && (
                <AutoComplete
                  oneFetch
                  required
                  variant="outlined"
                  size="small"
                  margin="normal"
                  label="Select Properties"
                  onChange={(e) => setFieldValue('properties', e)}
                  urlName="propertyName"
                  value={values.properties}
                  url={`/property/${values.companyId && values.companyId._id}`}
                  onBlur={handleBlur}
                  error={Boolean(touched.properties && errors.properties)}
                  helperText={touched.properties && errors.properties}
                  dataStructure={(n) => ({ ...n, label: n.name, value: n._id })}
                  isMulti
                />
              )}
          </React.Fragment>
          <TextField
            fullWidth
            required
            name="password"
            label="Password"
            type="password"
            margin="normal"
            variant="outlined"
            size="small"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            value={values.password}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            required
            name="verifyPassword"
            label="Verify Password"
            type="password"
            margin="normal"
            variant="outlined"
            size="small"
            error={Boolean(touched.verifyPassword && errors.verifyPassword)}
            helperText={touched.verifyPassword && errors.verifyPassword}
            onBlur={handleBlur}
            value={values.verifyPassword}
            onChange={handleChange}
          />
        </Board>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.module.list,
});

const CreateFormik = withFormik(
  Create,
  createSchemeValidations,
  initialValueForm,
  handleFormSubmit
);

export default connect(mapStateToProps, { add, list })(CreateFormik);
