import React from 'react';
import CustomScrollbars from 'utils/CustomScrollbars';
import Navigation from 'components/Navigation';
import { getUser } from 'utils/localStorage';

const SideBarContent = () => {
  const navigationMenus = getUser()?.user?.menus;

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
