import * as Yup from 'yup';

// eslint-disable-next-line
const regWebsite = /^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_\.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/;
const lettersAndNumbers = /^[A-Za-z0-9]*$/;
const number = /^[0-9]*$/;
const lettersAndSpace = /^[A-Za-z\s]*$/;
// const npwpRegex = /^[1-9][1-9][.]([\d]{3})[.]([\d]{3})[.][\d][-]([\d]{3})[.]([\d]{3})$/g;
// const letters = /^[A-Za-z]*$/;
export const registerSchemeValidations = Yup.object().shape({
  companyName: Yup.string()
    .matches(lettersAndSpace, 'Company name can only contain letters')
    .required('Company name can not be left blank.'),
  country: Yup.string().required(
    'Select the country your company is operation from'
  ),
  companyCountry: Yup.string().required(
    'Select the country your company is located.'
  ),
  businessSector: Yup.array()
    .min(1, 'Select the sector your company is in.')
    .max(3, 'Maximum Business Sectors is 3')
    .required('Select the sector your company is in.'),
  companyPhoneNumber: Yup.string().required(
    'Company phone numbers can not be left blank.'
  ),
  companyEmail: Yup.string()
    .email('Email address uses an invalid email format.')
    .required('Email address can not be left blank.'),
  companyWebsite: Yup.string()
    .matches(regWebsite, 'Website uses an invalid website format.')
    .required('Company website cannot be left blank.'),
  companyAddress: Yup.string().required('Address can not be left blank'),
  province: Yup.string().when('companyCountry', {
    is: 'Indonesia',
    then: Yup.string().required('Select the province your company is located'),
    otherwise: Yup.string().required(
      'Select the province/state your company is located.'
    ),
  }),
  district: Yup.string().when('companyCountry', {
    is: 'Indonesia',
    then: Yup.string().required('Select the district your company is located'),
    otherwise: Yup.string().required(
      'Select the district/city/suburb your company is located.'
    ),
  }),
  postalCode: Yup.string().required('Postal code can not be left blank'),
  picName: Yup.string()
    .matches(lettersAndSpace, 'Company name can only contain letters')
    .required('PIC name can only contain letters'),
  picPhone: Yup.string().required('PIC phone numbers can not be left blank'),
  picEmail: Yup.string()
    .email('Email address uses an invalid email format.')
    .required('Email address can not be left blank.'),
  picPosition: Yup.string().required('PIC position can not be left blank'),
  applyingAs: Yup.string().required(
    'Please indicate what you are applying as for.'
  ),
  nib: Yup.string().required('Please attach the required document'),
  siup: Yup.string().when('companyCountry', {
    is: 'Indonesia',
    then: Yup.string().required('Please attach the required document'),
  }),
  npwp: Yup.string().when('companyCountry', {
    is: 'Indonesia',
    then: Yup.string()
      // .matches(
      //   npwpRegex,
      //   'NPWP should have the following format: 11.111.000.2-222.000'
      // )
      .required('NPWP can not be left blank'),
  }),
  npwpFile: Yup.string().required('Please attach the required document'),
  izinEkspor: Yup.string().when('companyCountry', {
    is: 'Indonesia',
    then: Yup.string().required('Surat Izin ekspor can not be left blank'),
  }),
  password: Yup.string()
    .matches(
      lettersAndNumbers,
      'Password can only contain letters and numbers.'
    )
    .required('Password can only contain letters and numbers.'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password does not match.')
    .required('Password does not match.'),
});

export const editSchemeValidations = Yup.object().shape({
  companyName: Yup.string()
    // .matches(lettersAndSpace, 'Company name can only contain letters')
    .nullable(),
  // .required('Company name can not be left blank.'),
  // country: Yup.string(),
  // .required(
  //   'Select the country your company is operation from'
  // ),
  // companyCountry: Yup.string(),
  // .required(
  //   'Select the country your company is located.'
  // ),
  businessSector: Yup.array()
    .max(3, 'Maximum Business Sectors is 3')
    .nullable(),
  // .required('Select the sector your company is in.'),
  // companyPhoneNumber: Yup.string(),
  // .required(
  //   'Company phone numbers can not be left blank.'
  // ),
  companyEmail: Yup.string()
    .email('Email address uses an invalid email format.')
    .nullable(),
  // .required('Email address can not be left blank.'),
  companyWebsite: Yup.string()
    .matches(regWebsite, 'Website uses an invalid website format.')
    .nullable(),
  // .required('Website uses an invalid website format.'),
  // companyAddress: Yup.string(),
  // .required('Address can not be left blank'),
  // province: Yup.string()
  //   .when('companyCountry', {
  //     is: 'Indonesia',
  //     then: Yup.string().required('Select the province your company is located'),
  //     otherwise: Yup.string().required(
  //       'Select the province/state your company is located.'
  //     ),
  //   }),
  // district: Yup.string()
  //   .when('companyCountry', {
  //     is: 'Indonesia',
  //     then: Yup.string().required('Select the district your company is located'),
  //     otherwise: Yup.string().required(
  //       'Select the district/city/suburb your company is located.'
  //     ),
  //   }),
  // postalCode: Yup.string(),
  // .required('Postal code can not be left blank'),
  picName: Yup.string()
    .matches(lettersAndSpace, 'Company name can only contain letters')
    .nullable(),
  // .required('PIC name can only contain letters'),
  // picPhone: Yup.string(),
  // .required('PIC phone numbers can not be left blank'),
  picEmail: Yup.string()
    .email('Email address uses an invalid email format.')
    .nullable(),
  // .required('Email address can not be left blank.'),
  // picPosition: Yup.string(),
  // .required('PIC position can not be left blank'),
  // applyingAs: Yup.string(),
  // .required(
  //   'Please indicate what you are applying as for.'
  // ),
  nib: Yup.string().nullable(),
  // .required('Please attach the required document'),
  siup: Yup.string().nullable(),
  // .when('companyCountry', {
  //   is: (companyCountry) => ['Indonesia', 'ID'].includes(companyCountry),
  //   then: Yup.string().required('Please attach the required document'),
  // }),
  // npwp: Yup.string().when('companyCountry', {
  //   is: (companyCountry) => ['Indonesia', 'ID'].includes(companyCountry),
  //   then: Yup.string().matches(
  //     npwpRegex,
  //     'NPWP should have the following format: 11.111.000.2-222.000'
  //   ),
  //   // .required('NPWP can not be left blank'),
  // }),
  npwpFile: Yup.string().nullable(),
  // .required('Please attach the required document'),
  izinEkspor: Yup.string().nullable(),
  // .when('companyCountry', {
  //   is: (companyCountry) => ['Indonesia', 'ID'].includes(companyCountry),
  //   then: Yup.string().required('Surat Izin ekspor can not be left blank'),
  // }),
});

export const categorySchemeValidations = Yup.object().shape({
  name: Yup.string().required('Category name can not be left blank.'),
  order: Yup.string().required(
    'Select in which order this category will be shown.'
  ),
  status: Yup.string().required('Status can not be left blank'),
});

export const productSchemeValidations = Yup.object().shape({
  productName: Yup.string().required('Product name can not be left blank.'),
  category: Yup.string().required('Category can not be left blank.'),
  productDesc: Yup.string().required(
    'Product description can not be left blank.'
  ),
  // sku: Yup.string().required('SKU can not be left blank.'),
  // dimension: Yup.string().required('Dimension can not be left blank.'),
  image: Yup.array().min(
    1,
    'Image is required. Please upload a minimum of 1 image, up to 5 maximum.'
  ),
});

export const Upload = Yup.object().shape({
  logo: Yup.string().required(''),
  bacgkround: Yup.string().required(''),
});

export const aboutExhibitor = Yup.object().shape({
  about: Yup.string().required('About exhibitor can not be left blank.'),
  video: '',
  file: Yup.string().required('Please attach the required documents.'),
  founding: Yup.string()
    .matches(number, 'Year of founding can only contain numbers')
    .required('Year of founding can not be left blank'),
});
