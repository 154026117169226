import React from 'react';
import { useHistory } from 'react-router-dom';
import Board from 'components/Board';
import { Grid, Typography, Avatar } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';

const View = () => {
  const history = useHistory();

  return (
    <div className="app-wrapper my-profile">
      <ContainerHeader
        title="Admin Profile"
        breadcrumb={[{ link: '/profiles', label: 'Admin Profile' }]}
      />

      <Board
        actions={[
          {
            label: 'Edit',
            style: {
              background: '#ED901A',
              color: '#fff',
            },
            onClick: () => history.push('/profiles/edit'),
          },
        ]}
      >
        <Grid container spacing={5}>
          <Grid
            item
            lg={6}
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Avatar className="logo" src="" />
            <Typography className="title">Muhammad Mirza</Typography>
          </Grid>

          <Grid item lg={6} className="detail">
            <Typography className="title-detail">Profile Details</Typography>
            <div className="detail-list">
              <Typography className="detail-title">Username:</Typography>
              <p className="detail-value">mmirza</p>
            </div>
            <div className="detail-list">
              <Typography className="detail-title">Email:</Typography>
              <p className="detail-value">mirza@admin.com</p>
            </div>
            <div className="detail-list">
              <Typography className="detail-title">Password:</Typography>
              <p className="detail-value">********</p>
            </div>
            <div className="detail-list">
              <Typography className="detail-title">First Name:</Typography>
              <p className="detail-value">Muhammad</p>
            </div>
            <div className="detail-list">
              <Typography className="detail-title">Last Name:</Typography>
              <p className="detail-value">Mirza</p>
            </div>
            <div className="detail-list">
              <Typography className="detail-title">Created At:</Typography>
              <p className="detail-value">02-03-2021 | 22:00</p>
            </div>
            <div className="detail-list">
              <Typography className="detail-title">Updated On:</Typography>
              <p className="detail-value">03-03-2021 | 22:00</p>
            </div>
          </Grid>
        </Grid>
      </Board>
    </div>
  );
};

export default View;
