import React, { Component } from 'react';
import { Snackbar, SnackbarContent }  from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { notifClose, notif, newChat
  // , notifications 
} from 'redux/actions/Global';
import io from 'socket.io-client';
import { BASE_URL } from 'utils/url';
import { withStyles } from '@material-ui/styles';
import { getUser } from 'utils/localStorage';
import { connect } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function SB (props) {
  const { classes, className, message, variant, onClose } = props;
  const Icon = variantIcon[variant];

  return (
    <div className={classes.root}>
      <SnackbarContent 
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span className={classes.message}> 
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </div>
  );
}

export const SbNotif = withStyles(styles1)(SB);

class WrapNotif extends Component {
  // componentDidMount() {
  //   this.loadNotif()
  // }

  loadNotif = () => {
    const { user , token } = getUser();
    if (user && user._id) {
      const socket = io(process.env.BASE_URL || BASE_URL, { query: { token } });
      if (!socket.id) {
        socket.on('connect', () => {
          socket.emit('activeUsers', { roleId: user.roleId, userId: user._id });
          socket.emit('join', { userId: user._id });
        });
        socket.on('notifByRole', (data) => {
          this.props.notif({ open: true, variant: 'info', message: data.content, socket: true });
        });
        socket.on('new-chat', (data) => {
          this.props.newChat(data);
        });
      }
    }
  }

  render () {
    const { dataNotif, notifStore } = this.props;
    return (
      <Snackbar
        anchorOrigin={dataNotif.socket ? {
            vertical: 'top',
            horizontal: 'right',
          } : {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
        open={dataNotif.open}
        autoHideDuration={3000}
        onClose={notifStore}
      >
        <SbNotif
          onClose={notifStore}
          variant={dataNotif.variant}
          message={dataNotif.message}
        />
      </Snackbar>
    )
  }
}

const mapDispatchToProps = {
  notifStore: notifClose,
  notif,
  newChat,
};

const mapStateToProps = state => ({
  dataNotif: state.global.notif,
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapNotif);
