import React, { useState } from 'react';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';
import Create from './Create';
import Edit from './Edit';
import moment from 'moment';

const Index = () => {
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [reload, setIsReload] = useState(false);

  const update = (value) => {
    setIsOpenEdit(true);
    setDataEdit(value);
  };

  const closeCreate = () => {
    setIsOpenCreate(false);
    setIsReload(!reload);
  };
  const closeEdit = () => {
    setIsOpenEdit(false);
    setIsReload(!reload);
  };
  return (
    <div className="app-wrapper business-sector-page">
      <ContainerHeader
        title="Business Sector"
        breadcrumb={[{ link: '/business-sectors', label: 'Business Sector' }]}
      />

      <div className="action-btn">
        <Button
          style={{
            background: '#ED901A',
            padding: '8px 24px',
            borderRadius: 8,
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
          }}
          onClick={() => setIsOpenCreate(true)}
        >
          Add Sector
        </Button>
      </div>

      <Tables
        isSinglePage
        reload={reload}
        title="Business Sector"
        name="sector"
        withSearch={{ placeholder: 'Search for business sector...' }}
        columnHead={[
          { id: 'id', disableSort: false, label: 'No' },
          { id: 'name', canFilter: false, label: 'Sector Name' },
          { id: 'status', canFilter: false, label: 'Status' },
          {
            id: 'numberExhibitor',
            canFilter: false,
            label: 'Number of Exhibitor',
          },
          { id: 'created_at', canFilter: false, label: 'Created At' },
          { id: '', canFilter: false, label: 'Action' },
        ]}
        columnBody={[
          { parent: 'number' },
          { parent: 'name' },
          { parent: (n) => (n.status ? 'Active' : 'Inactive') },
          { parent: (n) => (n.totalExhibitor > 0 ? n.totalExhibitor : 0) },
          {
            parent: (n) =>
              `${n.createdAt && moment(n.createdAt).format('DD MMM YYYY')}`,
          },
          {
            parent: (n) =>
              n.totalExhibitor === 0 ? (
                <Button
                  onClick={() => update(n)}
                  style={{
                    background: '#3C91D6',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                  Edit
                </Button>
              ) : null,
          },
        ]}
      />

      <Create open={isOpenCreate} handleOpen={(val) => closeCreate(val)} />

      <Edit
        open={isOpenEdit}
        handleOpen={(val) => closeEdit(val)}
        dataEdit={dataEdit}
      />
    </div>
  );
};

export default Index;
