import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import history from 'utils/history';
import { request, requestBlob } from 'utils/request';
import { BASE_URL } from 'utils/url';
import { getUser } from 'utils/localStorage';
import FileSaver from 'file-saver';
import moment from 'moment';

import actionTypes, {
  addSuccess,
  loadSuccess,
  editSuccess,
  removeSuccess,
  bulkRemoveSuccess,
  approveSuccess,
  bulkApproveSuccess,
  listSuccess,
  list as loadTable,
} from '../actions/Module';
import { notif, loading, loadingTable } from '../actions/Global';

const notifError = (msg) =>
  notif({ open: true, variant: 'error', message: msg });
const notifSuccess = (msg) =>
  notif({ open: true, variant: 'success', message: msg });

function* add({ name, id, data, customRedirect, notLink, onSuccess }) {
  yield put(loading(true));
  const apiUrl = `${BASE_URL}/api/`;
  const requestURL = id ? apiUrl + `${name}/${id}` : apiUrl + name;
  const body = JSON.stringify(data);
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
      body,
    });
    if (onSuccess) {
      yield onSuccess(res);
      yield put(notifSuccess(res.message || 'Create success'));
    }
    if (!notLink) {
      yield put(notifSuccess(res.message || 'Create success'));
      if (!customRedirect) {
        yield call(history.push, `/${name}`);
      } else {
        yield call(history.push, `/${customRedirect}`);
      }
    }
    yield put(addSuccess(res));
    yield put(loading(false));
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
  }
}

function* load({ name, id, customName, onSuccess, linkSuccess, noLink }) {
  yield put(loading(true));
  const apiUrl = `${BASE_URL}/api/`;
  const requestURL = `${apiUrl}${id ? name + '/' + id : name}`;
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
    });
    yield put(loadSuccess({ name: customName || name, res }));
    yield put(loading(false));
    if (onSuccess) {
      yield onSuccess(res);
    }
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    yield call(history.push, `/${name}`);
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
  }
}

function* edit({ name, id, data, linkSuccess, onSuccess, noLink }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/${name}/${id || ''}`;
  const body = JSON.stringify(data);
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
      body,
    });
    yield put(notifSuccess(res.message || 'Update Success'));
    yield put(editSuccess(res));
    yield put(loading(false));
    if (onSuccess) {
      onSuccess(res);
    }
    if (!noLink) {
      if (linkSuccess) {
        yield call(history.push, `/${linkSuccess}`);
      } else {
        yield call(history.push, `/${name}/${id}`);
      }
    }
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
  }
}

function* remove({ name, id, customName, onSuccess, noLink }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/${name}/${id}`;
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
    });
    yield put(notifSuccess(res.message || 'Delete success'));
    yield put(removeSuccess(res));
    yield put(loading(false));
    if (onSuccess) {
      onSuccess(res);
    }
    if (!noLink) {
      if (!customName) {
        yield call(history.push, `/${name}`);
      } else {
        yield call(history.push, `/${customName}`);
      }
    }
  } catch (err) {
    const res = yield err.response.json();
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
    yield put(loading(false));
  }
}

function* bulkRemove({ name, data }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/${name}/bulk-delete`;
  const body = JSON.stringify({ ids: data });
  const listParams = yield select((state) => state.module.listParams);
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${auth.token}`,
      },
      body,
    });
    yield put(loading(false));
    yield put(notifSuccess(res.message));
    yield put(bulkRemoveSuccess(res));
    yield put(
      loadTable({
        name,
        query: listParams[name].query,
      })
    );
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    }
  }
}

function* approve({ name, id, status = 'approved' }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/${name}/${id}/approve`;
  try {
    const auth = JSON.parse(getUser());
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${auth.token}`,
      },
      body: JSON.stringify({ status }),
    });
    yield put(notifSuccess(res.message));
    yield put(approveSuccess(res));
    yield put(loading(false));
    yield call(history.push, `/${name}`);
  } catch (err) {
    yield put(loading(false));
    const res = yield err.response.json();
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
  }
}

function* bulkApprove({ name, data, status = 'approved' }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/${name}/bulk-approve`;
  const body = JSON.stringify({ ids: data, status });
  const listParams = yield select((state) => state.module.listParams);
  try {
    const auth = JSON.parse(getUser());
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${auth.token}`,
      },
      body,
    });
    yield put(notifSuccess(res.message));
    yield put(loading(false));
    yield put(bulkApproveSuccess(res));
    yield put(
      loadTable({
        name,
        query: listParams[name].query,
      })
    );
  } catch (err) {
    yield put(loading(false));
    const res = yield err.response.json();
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
  }
}

function* list({ name, query, customName, onSuccess }) {
  yield put(loadingTable(true));
  const apiUrl = `${BASE_URL}/api/`;
  const requestURL = apiUrl + `${name}?${query || ''}`;
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
    });
    yield put(listSuccess({ name: customName || name, res }));
    yield put(loadingTable(false));
    if (onSuccess) {
      yield onSuccess(res);
    }
  } catch (err) {
    const res = yield err.response.json();
    yield put(loadingTable(false));
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
  }
}

function* exportExcel({ module, id, url, query, onSuccess }) {
  yield put(loading(true));
  let requestURL = `${BASE_URL}/api/${module}/download-excel/${id}`;
  if (url) {
    requestURL = `${BASE_URL}/api/${url}`;
  }
  if (query) {
    requestURL = `${requestURL}?${query}`;
  }
  try {
    const auth = getUser();
    const data = yield call(requestBlob, requestURL, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${auth.token}`,
      },
    });
    FileSaver.saveAs(
      data,
      `${module || url}-${moment().format('DD-MM-YYYY HH:mm')}.xlsx`
    );
    if (onSuccess) {
      onSuccess({ name: module || url });
      yield put(notifSuccess(data.message || 'Export Excel Success'));
    }
  } catch (err) {
    if (onSuccess) {
      onSuccess({ name: module || url });
    }
    yield put(loading(false));
    if (err.message) {
      yield put(notifError(err.message));
    }
  }
}

function* importExcel({ module, url, file, onSuccess }) {
  yield put(loading(true));
  let requestURL = `${BASE_URL}/api/registrations/import-excel`;
  const jylo = new FormData();
  jylo.set('newImport', file);
  try {
    const auth = getUser();
    const data = yield call(requestBlob, requestURL, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${auth.token}`,
      },
      body: jylo,
    });
    yield put(notifSuccess(data.message || 'Import Excel Success'));
    if (onSuccess) {
      onSuccess({ name: module || url });
      yield put(notifSuccess(data.message || 'Import Excel Success'));
    }
  } catch (err) {
    if (onSuccess) {
      onSuccess({ name: module || url });
    }
    yield put(loading(false));
    if (err.message) {
      yield put(notifError(err.message));
    }
  }
}

function* sendEmail({ name, id, data, onSuccess }) {
  yield put(loading(true));
  const apiUrl = `${BASE_URL}/api/`;
  const requestURL = id ? apiUrl + `${name}/${id}` : apiUrl + name;
  const body = JSON.stringify(data);
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
      body,
    });
    if (onSuccess) {
      yield onSuccess(res);
    }
    yield put(notifSuccess(res.message || 'Email has been sent'));
    yield put(loading(false));
  } catch (err) {
    const res = yield err.response.json();
    yield put(loading(false));
    if (res.message || res.errors.message) {
      yield put(notifError(res.message || res.errors.message));
    } else {
      yield put(notifError(err.message));
    }
  }
}

function* moduleWatcher() {
  yield all([
    takeLatest(actionTypes.ADD, add),
    takeEvery(actionTypes.LOAD, load),
    takeLatest(actionTypes.EDIT, edit),
    takeLatest(actionTypes.REMOVE, remove),
    takeLatest(actionTypes.BULK_REMOVE, bulkRemove),
    takeLatest(actionTypes.APPROVE, approve),
    takeLatest(actionTypes.BULK_APPROVE, bulkApprove),
    takeEvery(actionTypes.LIST, list),
    takeEvery(actionTypes.EXPORT_EXCEL, exportExcel),
    takeEvery(actionTypes.IMPORT_EXCEL, importExcel),
    takeLatest(actionTypes.SEND_EMAIL, sendEmail),
  ]);
}

export default moduleWatcher;
