import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Grid, Typography, Chip } from '@material-ui/core';
import { EmailOutlined as EmailIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import FormikPhoneInput from 'components/FormikPhoneInput';
import { connect, useDispatch, useSelector } from 'react-redux';
import { add, load } from 'redux/actions';
import SendEmail from './SendEmail';
import moment from 'moment';
import { sector } from 'data';
import { asMutable } from 'seamless-immutable';
import withFormik from 'utils/withFormik';
import _ from 'lodash';
// import Gallery from 'components/Gallery';

const initialValues = {
  sector: [],
};

const mapPropsToValue = ({ data }) => {
  const { exhibitor } = data;
  if (exhibitor) {
    let mutableData = asMutable(exhibitor, { deep: true });
    return {
      sector:
        mutableData &&
        mutableData.data.sector.map((val) => ({
          id: val,
          name: val,
        })),
      picPhone: exhibitor.data.user.phoneNumber,
      companyPhoneNumber: exhibitor.data.companyPhoneNumber,
    };
  }
  return initialValues;
};

const View = (props) => {
  const { values } = props;
  const { exhibitorId } = useParams();
  const [openSendMail, setOpenSendMail] = useState(false);

  const { exhibitor, businessSectorList } = useSelector(
    (state) => state.module.view
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'exhibitor',
        id: exhibitorId,
      })
    );

    dispatch(
      load({
        name: 'sector/getlist',
        customName: 'businessSectorList',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = exhibitor?.data;

  let businessSectorOptions = businessSectorList?.data.map((val) => ({
    id: val.name,
    name: val.name,
  }));

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Open Application"
        breadcrumb={[
          { link: '/exhibitor-applications', label: 'Exhibitor Application' },
          { link: '', label: 'Open Application' },
        ]}
      />

      <Board
        actions={[
          {
            icon: <EmailIcon />,
            onClick: () => setOpenSendMail(true),
            style: {
              color: '#3C91D6',
              border: '1px solid #3C91D6',
              fontSize: 8,
            },
          },
          {
            label: 'Approve',
            onClick: () =>
              dispatch(
                add({
                  name: `exhibitor/${exhibitorId}/approve`,
                  customRedirect: 'exhibitor-applications',
                  data: {},
                })
              ),
            confirm: true,
            confirmButton: {
              title: 'Approve',
              style: {
                background: '#1AB759',
                border: '1px solid #1AB759',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Approve Exhibitor Application',
            confirmMessage: 'Are you sure you want to Approve the exhibitor?',
            style: {
              width: 140,
              color: '#fff',
              background: '#1AB759',
              border: '1px solid #1AB759',
            },
          },
          {
            label: 'Reject',
            onClick: () =>
              dispatch(
                add({
                  name: `exhibitor/${exhibitorId}/reject`,
                  customRedirect: 'exhibitor-applications',
                  data: {},
                })
              ),
            confirm: true,
            confirmButton: {
              title: 'Reject',
              style: {
                background: '#E14040',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Reject Exhibitor Application',
            confirmMessage:
              'This action can not be undone. Are you sure you want to reject the exhibitor?',
            style: {
              width: 140,
              color: '#E45858',
              border: '1px solid #E45858',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMM YYYY')}
          </p>
          <Typography className="title">Company Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyName"
                label="Company/Institution Name"
                margin="normal"
                disabled
                value={view?.companyName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="picCountry"
                label="PIC Country"
                margin="normal"
                value={view?.user?.country?.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                multiple
                disabled
                name="businessSector"
                options={
                  businessSectorList
                    ? businessSectorOptions && businessSectorOptions
                    : sector
                }
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                value={values.sector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Business Sector"
                    name="businessSector"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormikPhoneInput
                disabled
                name="companyPhoneNumber"
                inputProps={{ name: 'companyPhoneNumber' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                value={values.companyPhoneNumber}
                countryCodeEditable={false}
                specialLabel="Company Phone Number"
                {...props}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyEmail"
                label="Company Email"
                margin="normal"
                disabled
                value={view?.user?.companyEmail}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyWebsite"
                label="Company Website"
                margin="normal"
                disabled
                value={view?.companyWebsite}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                // multiline
                rows={4}
                variant="outlined"
                name="companyAddress"
                label="Company Address"
                margin="normal"
                disabled
                value={view?.companyAddress}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="province"
                label="Province"
                margin="normal"
                disabled
                value={view?.companyAddressProvince}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="district"
                label="District"
                margin="normal"
                disabled
                value={view?.companyAddressDistrict}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="postalCode"
                label="Postal Code"
                margin="normal"
                disabled
                value={view?.companyAddressPostalCode}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="country"
                label="Company Country"
                margin="normal"
                value={view?.companyCountry}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">PIC Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PICName"
                label="PIC Name"
                margin="normal"
                disabled
                value={view?.user?.name}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <FormikPhoneInput
                disabled
                name="picPhone"
                inputProps={{ name: 'picPhone' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                value={values.picPhone}
                countryCodeEditable={false}
                specialLabel="PIC Phone Number"
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PICEmail"
                label="PIC Email"
                margin="normal"
                disabled
                value={view?.email}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PIC Position"
                label="PIC Position"
                margin="normal"
                value={view?.user?.position}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item></Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Documents</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="Applying As"
                label="Applying As"
                margin="normal"
                value={`Exhibitor for ${_.capitalize(view?.as)}`}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {['Indonesia', 'ID', ''].includes(view?.user?.country?.name) ? (
              <Grid sm={6} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="npwp"
                  label="NPWP"
                  margin="normal"
                  value={view?.noNpwp}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
          {['Indonesia', 'ID', ''].includes(view?.user?.country?.name) ? (
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    NPWP
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="npwp"
                    label={
                      view?.npwp?.split('/')[
                        view?.npwp?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.npwp}
                    style={{ width: '50%' }}
                    onClick={() => (window.location.href = view?.npwp)}
                    disabled={[undefined, '', null, []].includes(view?.npwp)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    NIB (Nomor Induk Berusaha)
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="nib"
                    label={
                      view?.nib?.split('/')[view?.nib?.split('/').length - 1] ||
                      'No File'
                    }
                    margin="normal"
                    value={view?.nib}
                    onClick={() => (window.location.href = view?.nib)}
                    disabled={[undefined, '', null, []].includes(view?.nib)}
                    style={{ width: '50%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    SIUP
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="siup"
                    label={
                      view?.siup?.split('/')[
                        view?.siup?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.siup}
                    style={{ width: '50%' }}
                    onClick={() => (window.location.href = view?.siup)}
                    disabled={[undefined, '', null, []].includes(view?.siup)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Surat Izin Ekspor
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="suratIzinEkspor"
                    label={
                      view?.suratIzinEkspor?.split('/')[
                        view?.suratIzinEkspor?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.suratIzinEkspor}
                    onClick={() =>
                      (window.location.href = view?.suratIzinEkspor)
                    }
                    style={{ width: '50%' }}
                    disabled={[undefined, '', null, []].includes(
                      view?.suratIzinEkspor
                    )}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Other Documents
                  </Typography>
                  {/* </div> */}
                  {view?.others?.map((n) => (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={
                        n?.file?.split('/')[n?.file?.split('/').length - 1] ||
                        'No File'
                      }
                      margin="normal"
                      value={n?.file}
                      style={{ width: '50%' }}
                      onClick={() => (window.location.href = n?.file)}
                      disabled={[undefined, '', null, []].includes(n?.file)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ))}
                  {view?.others?.length === 0 ? (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={'No File'}
                      margin="normal"
                      style={{ width: '50%' }}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Tax Documents
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="npwp"
                    label={
                      view?.npwp?.split('/')[
                        view?.npwp?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.npwp}
                    style={{ width: '50%' }}
                    onClick={() => (window.location.href = view?.npwp)}
                    disabled={[undefined, '', null].includes(view?.npwp)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Proof Of Business Ownership
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="nib"
                    label={
                      view?.nib?.split('/')[view?.nib?.split('/').length - 1] ||
                      'No File'
                    }
                    margin="normal"
                    value={view?.nib}
                    onClick={() => (window.location.href = view?.nib)}
                    style={{ width: '50%' }}
                    disabled={[undefined, '', null].includes(view?.nib)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Other Documents
                  </Typography>
                  {/* </div> */}
                  {view?.others?.map((n) => (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={
                        n?.file?.split('/')[n?.file?.split('/').length - 1] ||
                        'No File'
                      }
                      margin="normal"
                      value={n?.file}
                      style={{ width: '50%' }}
                      onClick={() => (window.location.href = n?.file)}
                      disabled={[undefined, '', null, []].includes(n?.file)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ))}
                  {view?.others?.length === 0 ? (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={'No File'}
                      margin="normal"
                      style={{ width: '50%' }}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </Board>

      {openSendMail && (
        <SendEmail
          id={exhibitorId}
          data={view}
          open={openSendMail}
          handleOpen={(val) => setOpenSendMail(val)}
        />
      )}

      <SendEmail />
    </div>
  );
};

const FormikView = withFormik(View, null, mapPropsToValue, null);

const mapStateToProps = (state) => ({
  data: state.module.view,
});

export default connect(mapStateToProps)(FormikView);
