import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Typography, Chip, TextField } from '@material-ui/core';
import Board from 'components/Board';
import Tables from 'components/Tables';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import CompanyBanner from './CompanyBannerVIew';
import CreateCategory from './Category/Create';
import EditCategory from './Category/Edit';
import EditOverview from './CompanyOverview/Edit';
import { load } from 'redux/actions';
import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';

const CompanyProfile = () => {
  const history = useHistory();
  const slug = useParams().exhibitorId;
  const [openCreateCategory, setOpenCreateCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openEditOverview, setOpenEditOverview] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const { exhibitor } = useSelector((state) => state.module.view);
  const [isUpdated, setIsUpdated] = useState(false);
  const dispatch = useDispatch();
  const [user, setUser] = useState('');
  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : slug;

  useEffect(() => {
    dispatch(
      load({
        name: 'exhibitor',
        id: exhibitorId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  useEffect(() => {
		if (getUser()?.user) {
		  setUser(getUser()?.user);
		}
	  }, []);
  const view = exhibitor && exhibitor.data;

  const handleCloseCategory = (res) => {
    setOpenEditCategory(false);
    setReloadTable(!reloadTable);
  };

  const editCategory = (data) => {
    setDataEdit(data);
    setReloadTable(!reloadTable);
    setOpenEditCategory(true);
  };

  const handleCloseCreateCategory = (res) => {
    setOpenCreateCategory(false);
    setReloadTable(!reloadTable);
  };

  const handleUpdated = (res) => {
    setIsUpdated(!isUpdated);
  };

  const handleCloseOverview = (res) => {
    setOpenEditOverview(false);
    setIsUpdated(!isUpdated);
  };

  const editOverview = () => {
    setIsUpdated(!isUpdated);
    setOpenEditOverview(true);
  };

  const backgroundImage = view && view.backgroundPhoto && view.backgroundPhoto;

  return (
    <div style={{ paddingTop: 20 }}>
      <div
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: 320,
          borderRadius: 20,
        }}
      ></div>
      <div>
        <CompanyBanner
          companyDetail={view}
          updated={(val) => handleUpdated(val)}
        />
      </div>
      <div style={{ marginTop: 40 }}>
      {user?.roles?.includes('perwakilan')!==true?
        <Board 
          actions={[
            {
              label: 'Edit',
              onClick: () => editOverview(),
              style: {
                color: '#fff',
                border: '1px solid #ED901A',
                background: '#ED901A',
                padding: '8px 12px',
              },
            },
            
          ]}
        >
             <div className="section">
            <Typography className="title">Company Overview</Typography>
            <TextEditor
              name="companyProfile"
              label="About Exhibitor"
              value={view?.companyProfile || ''}
              readOnly
            />
            <TextField
              fullWidth
              required
              variant="outlined"
              name="establishment"
              label="Year of Founding"
              margin="normal"
              value={view?.establishment}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="title">Video</Typography>

            <TextField
              fullWidth
              required
              variant="outlined"
              name="companyVideoEmbed"
              label="Embed Code"
              margin="normal"
              value={view?.companyVideoEmbed}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="title">PDF</Typography>
            <Chip
              fullWidth
              required
              variant="outlined"
              name="suratIzinEkspor"
              label={
                view?.companyProfileFile?.split('/')[
                  view?.companyProfileFile?.split('/').length - 1
                ] || 'No File'
              }
              margin="normal"
              value={view?.companyProfileFile}
              onClick={() =>
                (window.location.href = view?.companyProfileFile?.includes(
                  'https'
                )
                  ? view?.companyProfileFile
                  : `https://ina-access-bucket.s3.ap-southeast-1.amazonaws.com/${view?.companyProfileFile}`)
              }
              style={{ width: '50%' }}
              disabled={[undefined, '', null, []].includes(
                view?.companyProfileFile
              )}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </Board>:<Board> 
          <div className="section">
            <Typography className="title">Company Overview</Typography>
            <TextEditor
              name="companyProfile"
              label="About Exhibitor"
              value={view?.companyProfile || ''}
              readOnly
            />
            <TextField
              fullWidth
              required
              variant="outlined"
              name="establishment"
              label="Year of Founding"
              margin="normal"
              value={view?.establishment}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="title">Video</Typography>

            <TextField
              fullWidth
              required
              variant="outlined"
              name="companyVideoEmbed"
              label="Embed Code"
              margin="normal"
              value={view?.companyVideoEmbed}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography className="title">PDF</Typography>
            <Chip
              fullWidth
              required
              variant="outlined"
              name="suratIzinEkspor"
              label={
                view?.companyProfileFile?.split('/')[
                  view?.companyProfileFile?.split('/').length - 1
                ] || 'No File'
              }
              margin="normal"
              value={view?.companyProfileFile}
              onClick={() =>
                (window.location.href = view?.companyProfileFile?.includes(
                  'https'
                )
                  ? view?.companyProfileFile
                  : `https://ina-access-bucket.s3.ap-southeast-1.amazonaws.com/${view?.companyProfileFile}`)
              }
              style={{ width: '50%' }}
              disabled={[undefined, '', null, []].includes(
                view?.companyProfileFile
              )}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </Board> }
      </div>

      <div style={{ marginTop: 40 }}>
        <div className="action-btn">
        {!user?.roles?.includes('perwakilan') && (
          <Button
            style={{
              border: '1px solid #ED901A',
              borderRadius: 8,
              color: '#FFF',
              backgroundColor: '#ED901A',
              fontSize: 14,
              padding: '8px 24px',
              textTransform: 'capitalize',
            }}
            onClick={() => setOpenCreateCategory(true)}
          >
            Add Category
          </Button>)}
        </div>
        
        <Tables
          isSinglePage
          reload={reloadTable}
          title="Category"
          name={`exhibitor/${exhibitorId}/category`}
          columnHead={[
            { id: 'id', label: 'No', disableSort: false },
            { id: 'name', label: 'Category Name' },
            { id: 'order', label: 'Order' },
            { id: 'createdAt', label: 'Created At' },
            { id: 'status', label: 'Status' },
            { id: '', label: 'Action' },
          ]}
          columnBody={[
            { parent: 'number' },
            { parent: 'name' },
            { parent: 'order' },
            {
              parent: (n) =>
                `${n.createdAt && moment(n.createdAt).format('DD MMM YYYY')}`,
            },
            { parent: (n) => (n.active ? 'active' : 'deactive') },
            {
              parent: (n) => (
                <Button
                  onClick={() => editCategory(n)}
                  style={{
                    background: '#3C91D6',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                  Edit
                </Button>
              ),
            },
          ]}
        />
      </div>

      <div style={{ marginTop: 40 }}>
        <div className="action-btn">
        {!user?.roles?.includes('perwakilan') && (
          <Button
            style={{
              border: '1px solid #ED901A',
              borderRadius: 8,
              color: '#FFF',
              backgroundColor: '#ED901A',
              fontSize: 14,
              padding: '8px 24px',
              textTransform: 'capitalize',
            }}
            onClick={() => {
              if (getUser()?.user?.roles.includes('administrator')) {
                history.push(`/exhibitors/${exhibitorId}/add-product`);
              } else if (getUser()?.user?.roles.includes('exhibitor')) {
                history.push(`/my-company/add-product`);
              }
            }}
          >
            Add Products
          </Button> )}
        </div>
         {!getUser()?.user?.roles.includes('perwakilan') && (
        <Tables
          title="Products"
          name={`exhibitor/${exhibitorId}/products`}
          columnHead={[
            { id: 'id', label: 'No', disableSort: false },
            { id: 'name', label: 'Product Name' },
            { id: 'category', label: 'Category' },
            { id: 'exhibitor', label: 'Exhibitor' },
            { id: 'country', label: 'Country' },
            { id: 'status', label: 'Status' },
            { id: '', label: 'Action' },
          ]}
          columnBody={[
            { parent: 'number' },
            { parent: 'name' },
            { parent: (n) => n.sector.name },
            { parent: (n) => n.exhibitor.slug },
            { parent: (n) => n.exhibitor.user.country.name },
            { parent: 'status' },
            
            {parent: (n) => (
                
                <Button
                  onClick={() => history.push(`/products/${n.slug}`)}
                  style={{
                    background: '#3C91D6',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                  View
                </Button>
              ),
            },
          ]} 
        />)}
         {getUser()?.user?.roles.includes('perwakilan') && (
        <Tables
          title="Products"
          name={`exhibitor/${exhibitorId}/products`}
          columnHead={[
            { id: 'id', label: 'No', disableSort: false },
            { id: 'name', label: 'Product Name' },
            { id: 'category', label: 'Category' },
            { id: 'exhibitor', label: 'Exhibitor' },
            { id: 'country', label: 'Country' },
            { id: 'status', label: 'Status' },
          ]}
          columnBody={[
            { parent: 'number' },
            { parent: 'name' },
            { parent: (n) => n.sector.name },
            { parent: (n) => n.exhibitor.slug },
            { parent: (n) => n.exhibitor.user.country.name },
            { parent: 'status' },
            
          
          ]} 
        />)}
      </div>

      {openCreateCategory && (
        <CreateCategory
          open={openCreateCategory}
          handleOpen={(val) => handleCloseCreateCategory(val)}
        />
      )}

      {openEditCategory && (
        <EditCategory
          dataEdit={dataEdit}
          open={openEditCategory}
          exhibitor={exhibitorId}
          handleOpen={(val) => handleCloseCategory(val)}
        />
      )}

      {editOverview && (
        <EditOverview
          dataEdit={view}
          open={openEditOverview}
          exhibitor={exhibitorId}
          handleOpen={(val) => handleCloseOverview(val)}
        />
      )}
    </div>
  );
};

export default CompanyProfile;
