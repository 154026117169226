import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from 'redux/actions';
import moment from 'moment';
import TextEditor from 'components/Form/TextEditor';
import Gallery from 'components/Gallery';

const View = () => {
  const history = useHistory();
  const id = useParams().id;
  const { users } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'users',
        id: id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = users?.data;

  return (
    <div className="app-wrapper news-page">
      <ContainerHeader
        title="View User"
        breadcrumb={[
          { link: '/users', label: 'User' },
          { link: '', label: 'View User' },
        ]}
      />

      <Board
        actions={[
          {
            label: 'Edit User',
            noDisabled: true,
            onClick: () => history.push(`/users/${id}/edit`),
            style: {
              color: '#fff',
              border: '1px solid #ED901A',
              background: '#ED901A',
            },
          },
          {
            label: 'Delete User',
            noDisabled: true,
            onClick: () => {
              dispatch(
                remove({
                  name: 'users',
                  id: id,
                  customName: 'users',
                })
              );
            },
            confirm: true,
            confirmButton: {
              title: 'Delete',
              style: {
                color: '#fff',
                background: '#E14040',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete User',
            confirmMessage:
              'Are you sure you want to Delete the User?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Updated at {moment(view?.modifiedAt).format('DD MMMM YYYY')}
          </p>
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMMM YYYY')}
          </p>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="status"
                label="Status"
                margin="normal"
                value={view?.status}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
            fullWidth
            required
            variant="outlined"
            name="name"
            label="Name"
            margin="normal"
            disabled
            value={view?.name}
            InputLabelProps={{
              shrink: true,
            }}
           />
          <TextField
            fullWidth
            required
            variant="outlined"
            label="Phone Number"
            name="phoneNumber"
            margin="normal"
            disabled
            value={view?.phoneNumber}
            InputLabelProps={{
              shrink: true,
            }}
            />
          <TextField 
            fullWidth
            required
            variant="outlined"
            label="Perwakilan"
            name="companyName"
            margin="normal"
            disabled
            value={view?.companyName}
            InputLabelProps={{
              shrink: true,
            }}
            />
	    <TextField 
            fullWidth
            required
            variant="outlined"
            label="Email"
            name="companyEmail"
            margin="normal"
            disabled
            value={view?.companyEmail}
            InputLabelProps={{
              shrink: true,
            }}
            />
	     <TextField 
            fullWidth
            required
            variant="outlined"
            label="Country"
            name="Country"
            margin="normal"
            disabled
            value={view?.country.name}
            InputLabelProps={{
              shrink: true,
            }}
            />

          
            </Grid>
          </Grid>
        </div>

    
      </Board>
    </div>
  );
};

export default View;
