import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { TextField, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import UploadImage from 'components/Upload/UploadImage';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';
import { connect, useDispatch, useSelector } from 'react-redux';
import { edit, load } from 'redux/actions';
import _ from 'lodash';
import TextEditor from 'components/Form/TextEditor';

const initialValueForm = {
  SKU: '',
  description: '',
  dimension: '',
  images: '',
  name: '',
  status: '',
  exhibitor: '',
  sector: '',
};

const mapPropsToValues = ({ product }) => {
  if (product && product.data) {
    const data = product.data;
    return {
      SKU: data.SKU,
      description: data.description,
      dimension: data.dimension,
      images: data.images.map((n) => n.url),
      name: data.name,
      status: {
        value: data.status,
        title: _.capitalize(data.status),
      },
      exhibitor: data.exhibitor,
      sector: {
        id: data.sector.id,
        name: data.sector.name,
      },
    };
  }
  return initialValueForm;
};

const handleFormSubmit = (payload, ctx) => {
  const { productId } = ctx.props.match.params;
  const data = payload;
  ctx.props.edit({
    name: 'products',
    id: productId,
    data: {
      name: data.name,
      description: data.description,
      SKU: data.SKU,
      dimension: data.dimension,
      images: data.images,
      sector: data.sector.name,
      status: data.status.value,
    },
    onSuccess: (res) => {
      ctx.props.history.push(`/products/${res.data.slug}`);
    },
    noLink: true,
  });
};

const Edit = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  const history = useHistory();
  const { productId } = useParams();
  const { categories, products } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();
  const view = products?.data;

  useEffect(() => {
    dispatch(
      load({
        name: 'products',
        id: productId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (products?.data?.exhibitor?.slug) {
    }
    dispatch(
      load({
        name: 'products',
        customName: 'categories',
        id: `${products?.data?.exhibitor?.slug}/categories`,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listCategories = categories?.data?.map((n) => ({
    id: n.id,
    name: n.name,
  }));

  return (
    <div className="app-wrapper product-page">
      <ContainerHeader
        title="View Products"
        breadcrumb={[
          { link: '/products', label: 'All Products' },
          { link: '', label: 'View Product' },
        ]}
      />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                border: '1px solid #1AB759',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Save Changes',
            confirmMessage: 'Do you wish to add this?',
            onClick: () => handleSubmit(),
            style: {
              width: 148,
              color: '#fff',
              background: '#1AB759',
              border: '1px solid #1AB759',
            },
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#E14040',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Cancel Edit Product',
            confirmMessage: 'Do you want to cancel editing your product?',
            onClick: () => history.goBack(),
            style: {
              width: 130,
              color: '#E45858',
              border: '1px solid #E45858',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Updated at {moment(view?.modifiedAt).format('DD MMM YYYY')}
          </p>
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMM YYYY')}
          </p>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={values.status}
                onChange={(e, val) => setFieldValue('status', val)}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Product Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="name"
                label="Product Name"
                margin="normal"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <Autocomplete
                name="sector"
                style={{ marginTop: 15 }}
                options={listCategories ? listCategories : []}
                value={values.sector}
                onChange={(e, val) => setFieldValue('sector', val)}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    name="sector"
                    error={Boolean(touched.sector && errors.sector)}
                    helperText={touched.sector && errors.sector}
                    // onBlur={handleBlur}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="description"
                label="Product Description"
                value={values.description}
                onChange={(val) =>
                  setFieldValue('description', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'description' } })}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                disabled
                variant="outlined"
                name="exhibitor"
                label="Exhibitor"
                margin="normal"
                value={values.exhibitor && values.exhibitor.user.companyName}
                error={Boolean(touched.exhibitor && errors.exhibitor)}
                helperText={touched.exhibitor && errors.exhibitor}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="SKU"
                label="HS Code"
                margin="normal"
                value={values.SKU}
                error={Boolean(touched.SKU && errors.SKU)}
                helperText={touched.SKU && errors.SKU}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="dimension"
                label="Dimension"
                margin="normal"
                value={values.dimension}
                error={Boolean(touched.dimension && errors.dimension)}
                helperText={touched.dimension && errors.dimension}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography
                className="title"
                style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}
              >
                Image
              </Typography>

              <UploadImage
                name="images"
                value={values.images}
                onImageChange={(val) => setFieldValue('images', val)}
                error={errors.images}
                multiple
              />

              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 10mb
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  You can upload up to 5 pictures
                </Typography>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Accepted file format is .jpg, .jpeg, and .png. We recommend
                  isolated product photo against a white background.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.module.view.products,
});

const mapDispatchToProps = {
  edit,
};

const FormikEdit = withFormik(
  Edit,
  schemeValidations,
  mapPropsToValues,
  handleFormSubmit
);

export default connect(mapStateToProps, mapDispatchToProps)(FormikEdit);
