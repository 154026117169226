import React, { useEffect } from 'react'; // useEffect
import {
  //  useParams,
  useHistory,
} from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createSpeaker } from './schemeValidations';
import UploadImage from 'components/Upload/UploadImage';
import withFormik from 'utils/withFormik';
import {
  add,
  list as listRedux,
  // load
} from 'redux/actions/Module';
// import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';
import { uploadBase64Image } from 'utils/uploadMedia';

const initialValueForm = {
  status: { value: 'draft', title: 'Draft' },
  speakerName: '',
  title: '',
  profile: '',

  category: '',
  image: [],
};

const handleSubmitForm = (payload, ctx) => {
  const {
    status,
    speakerName,
    title,
    profile,

    category,
    image,
  } = payload;

  const imgRegex = new RegExp(/data:image\/[^;]+;base64[^"]+/g);
  const imgSrc = payload.profile.match(imgRegex) || [];

  Promise.all(
    imgSrc.map(
      (src) =>
        new Promise(async (resolve) => {
          const res = await uploadBase64Image(src, 'others');
          resolve(res);
        })
    )
  )
    .then((values) => {
      for (let i = 0; i < values.length; i++) {
        if (values[i]?.status === 200) {
          payload.profile = payload.profile.replace(
            imgSrc[i],
            values[i].data.originalUrl
          );
        }
      }

      ctx.props.add({
        name: `speaker`,
        data: {
          status: status.value,
          fullName: speakerName,
          title,
          profile,

          event: category,
          profileImage: image.toString(),
        },
        notLink: true,
        onSuccess: (res) => {
          ctx.props.history.push(`/speakers`);
        },
      });
    })
    .catch((e) => {
      console.error('Error occured:', e.message);
    });
};

const CreateEvent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.module.list);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  useEffect(() => {
    dispatch(
      listRedux({
        name: 'events',
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Add Speakers"
        breadcrumb={[
          { link: '/speakers', label: 'Speakers' },
          { link: '/speakers/create', label: 'Add Speakers' },
        ]}
      />

      <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Add Speakers',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Add Speaker',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'active', title: 'Active' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={values.status}
                onChange={(e, val) => setFieldValue('status', val)}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                    value={values.status}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Speaker Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="speakerName"
                label="FullName"
                margin="normal"
                value={values.speakerName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.speakerName && errors.speakerName)}
                helperText={touched.speakerName && errors.speakerName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                label="Title"
                variant="outlined"
                name="title"
                required
                fullWidth
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="profile"
                label="Profile"
                value={values.profile}
                onChange={(val) =>
                  setFieldValue('profile', val === '<p><br></p>' ? '' : val)
                }
                onBlur={(e) => handleBlur({ target: { name: 'profile' } })}
                error={Boolean(touched.profile && errors.profile)}
                helperText={touched.profile && errors.profile}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography className="title" style={{ marginBottom: 16 }}>
                Profile Image
              </Typography>

              <UploadImage
                name="image"
                value={values.image}
                onImageChange={(val) => setFieldValue('image', val)}
                error={errors.image}
                multiple
                maxFileSize={500}
                max={1}
              />
              <div style={{ width: 160 }}>
                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Max file size is 5mb
                </Typography>

                <Typography
                  fontStyle="italic"
                  style={{ fontSize: 10, color: '#696969' }}
                >
                  Recommended Profile Image size is 240px x 240px (.jpg, .jpeg,
                  and .png)
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Typography className="title" style={{ marginBottom: 16 }}>
                Event
              </Typography>
              <FormControl
                variant="outlined"
                fullWidth
                size="medium"
                margin="normal"
              >
                <InputLabel>Event Name</InputLabel>
                <Select
                  value={values.category}
                  name="category"
                  label="Category"
                  onChange={(event) =>
                    setFieldValue('category', event.target.value)
                  }
                  onBlur={handleBlur}
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category}
                >
                  {events?.data?.map((value, index) => (
                    <MenuItem value={value.id}>{value.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

const FormikCreate = withFormik(
  CreateEvent,
  createSpeaker,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
});

export default connect(mapStateToProps, { add, listRedux })(FormikCreate);
