import React from 'react';
import { useHistory } from 'react-router-dom';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';

const Index = () => {
  const history = useHistory();

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Participants"
        breadcrumb={[{ link: '/participants', label: 'Participants' }]}
      />

      <Tables
        title="Events"
        name="events"
        minWidth={1200}
        viewDetail={{ name: 'banners', by: 'slug' }}
        columnHead={[
          { id: 'id', label: 'No', disableSort: false },
          { id: 'name', label: 'Event Name' },

          { id: '', label: 'Action' },
        ]}
        columnBody={[
          { parent: 'number' },
          { parent: 'title' },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/participants/${n.id}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                  fontSize: 12,
                }}
              >
                View
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Index;
