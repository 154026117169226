import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from 'redux/actions';
import moment from 'moment';
import TextEditor from 'components/Form/TextEditor';
import Gallery from 'components/Gallery';

const View = () => {
  const history = useHistory();
  const { newsId } = useParams();
  const { news } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'news',
        id: newsId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = news?.data;

  return (
    <div className="app-wrapper news-page">
      <ContainerHeader
        title="View Press Release"
        breadcrumb={[
          { link: '/press-releases', label: 'Press Release' },
          { link: '', label: 'View Press Release' },
        ]}
      />

      <Board
        actions={[
          {
            label: 'Edit Press Release',
            noDisabled: true,
            onClick: () => history.push(`/press-releases/${newsId}/edit`),
            style: {
              color: '#fff',
              border: '1px solid #ED901A',
              background: '#ED901A',
            },
          },
          {
            label: 'Delete Press Release',
            noDisabled: true,
            onClick: () => {
              dispatch(
                remove({
                  name: 'news',
                  id: newsId,
                  customName: 'press-releases',
                })
              );
            },
            confirm: true,
            confirmButton: {
              title: 'Delete',
              style: {
                color: '#fff',
                background: '#E14040',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Press Release',
            confirmMessage:
              'Are you sure you want to Delete the Press Release?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Updated at {moment(view?.modifiedAt).format('DD MMMM YYYY')}
          </p>
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMMM YYYY')}
          </p>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="status"
                label="Status"
                margin="normal"
                value={view?.status}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Press Release Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="title"
                label="Title"
                margin="normal"
                value={view?.title}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="description"
                label="Description"
                value={view?.content || ''}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                multiline
                rows={2}
                variant="outlined"
                name="excerpt"
                label="Excerpt"
                margin="normal"
                value={view?.excerpt}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="category"
                label="Category"
                margin="normal"
                value={view?.category?.categoryName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Gallery
                title="Thumbnail"
                images={[{ url: view?.thumbnailImage }]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Gallery title="Banner" images={[{ url: view?.bannerImage }]} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                name="publishedDate"
                label="Published Date"
                margin="normal"
                value={moment(view?.publishedDate).format('DD/MM/YYYY')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

export default View;
