import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, TextField } from '@material-ui/core';
import FormikPhoneInput from 'components/FormikPhoneInput';

import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from 'redux/actions';

const ViewParticipant = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { participant } = useSelector((state) => state.module.view);
  const id = useParams().id;
  useEffect(() => {
    dispatch(
      load({
        name: 'participant',
        id: id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const view = participant?.data;
  const removeData = () => {
    dispatch(
      remove({
        name: 'participant',
        id,
        noLink: true,
        onSuccess: (res) => history.push('/participants'),
      })
    );
  };
  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="View Participant Details"
        breadcrumb={[
          { link: '/participants', label: 'Participants' },
          {
            link: `/participants/view/${id}`,
            label: 'View Participant Details',
          },
        ]}
      />

      <Board
        // disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Delete Participant',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Delete',
              style: {
                background: '#E14040',
                color: '#fff',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Participant',
            confirmMessage:
              'This action can not be undone. Are you sure you want to delete the participant?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => removeData(),
          },
        ]}
      >
        <div className="section">
          <Typography className="title" style={{ marginBottom: 10 }}>
            Detail Participants
          </Typography>

          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="fullname"
                label="Full Name"
                margin="normal"
                value={view?.fullName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyEmail"
                label="Email Address"
                margin="normal"
                value={view?.email}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormikPhoneInput
                disabled
                name="picPhone"
                inputProps={{ name: 'picPhone' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                // values="62123126969"
                // value="6234234234"
                values={view?.phoneNumber || ''}
                value={view?.phoneNumber || ''}
                countryCodeEditable={false}
                specialLabel="Phone Number"
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
          </Grid>
          <br />
          <div className="section">
            <Grid container spacing={3}>
              <Grid sm={6} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="companyName"
                  label="Company/Institution name"
                  margin="normal"
                  value={view?.institutionName}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid sm={6} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="yourCountry*"
                  label="Your Country"
                  margin="normal"
                  value={view?.country}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid sm={6} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="businessSector"
                  label="Business Sector"
                  margin="normal"
                  value={view?.businessSector?.toString()}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="time"
                label="Time"
                margin="normal"
                value={`${view?.event?.startTime.slice(
                  0,
                  5
                )} - ${view?.event.endTime.slice(0, 5)}`}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={12} item>
              <TextField
                label="Message"
                multiline
                row={5}
                rowsMax={5}
                disabled
                variant="outlined"
                required
                fullWidth
                value={view?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

export default ViewParticipant;
