import React from "react";
import { getUser } from "utils/localStorage";
import HomeAdmin from "./HomeAdmin";
import HomeExhibitor from "./HomeExhibitor";
import HomeBusiness from "./HomeBusiness";
import ProcessLogin from "./ProcessLogin";

const Index = (props) => {
	const { search } = props.location;
	const token = search.replace(/\?token=/, "");

	if (
		getUser()?.user?.roles.includes("administrator") ||
		getUser()?.user?.roles.includes("operator") 
	) {
		return <HomeAdmin />;
	} else if (getUser()?.user?.roles.includes("exhibitor")) {
		return <HomeExhibitor />;
	} else if (getUser()?.user?.roles.includes("perwakilan")) {
		return <HomeBusiness />;
	}
	 else if (token) {
		return <ProcessLogin />;
	}
};

export default Index;
