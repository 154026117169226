import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const FormikPhoneInput = (props) => {
  const [errorState, setStateError] = useState('');
  const {
    name,
    className,
    helperText,
    errorText,
    country,
    containerClass,
    error,
    onChange,
    // formik props
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    isValid,
    dirty,

    // other props
    ...others
  } = props;

  // useEffect(()=>{
  //   if(helperText){

  //   }
  // },[helperText])
  const onValueChange = (phoneNumber) => {
    setFieldValue(name, phoneNumber);

    if (onChange !== null) {
      onChange(phoneNumber);
    }
  };

  return (
    <div className={`${className}`}>
      <PhoneInput
        name={name}
        value={values.phoneNumber}
        onChange={onValueChange}
        country={country}
        containerClass={
          containerClass + (error ? ' signUpVisitor-phoneInput-errorStyle' : '')
        }
        isValid={(value, country) => {
          // console.log(country, 'country');
          if (value.length === country?.countryCode?.length) {
            setStateError(errorText);
            return true;
          } else {
            setStateError('');
            return true;
          }
        }}
        {...others}
      />
      <p
        style={{
          fontSize: 12,
          color: '#f44336',
          marginLeft: 16,
        }}
      >
        {(error && helperText) || errorState}
      </p>
    </div>
  );
};

export default FormikPhoneInput;
