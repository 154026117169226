import React, { useEffect } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory, useParams } from 'react-router-dom';

import { sector } from 'data';

import withFormik from 'utils/withFormik';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { load, remove } from 'redux/actions';
import { useSelector, useDispatch, connect } from 'react-redux';
import moment from 'moment';
import { asMutable } from 'seamless-immutable';

const initialValues = {
  sector: [],
};

const mapPropsToValue = ({ data }) => {
  const { visitor } = data;
  if (visitor) {
    let mutableData = asMutable(visitor, { deep: true });
    return {
      sector:
        mutableData &&
        mutableData?.data?.businessSector?.map((val) => ({
          id: val,
          name: val,
        })),
    };
  }
  return initialValues;
};

const View = (props) => {
  const history = useHistory();
  const slug = useParams().visitorId;
  const dispatch = useDispatch();
  const { visitor, businessSectorList } = useSelector(
    (state) => state.module.view
  );

  const { values } = props;
  useEffect(() => {
    dispatch(
      load({
        name: 'visitor',
        id: slug,
      })
    );
    dispatch(
      load({
        name: 'sector/getlist',
        customName: 'businessSectorList',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const view = visitor?.data;
  let businessSectorOptions = businessSectorList?.data.map((val) => ({
    id: val.name,
    name: val.name,
  }));
  const adminActions = [
    {
      label: 'Edit Visitor',
      onClick: () => {
        history.push(`/visitors/${slug}/edit`);
      },
      style: {
        color: '#fff',
        border: '1px solid #ED901A',
        background: '#ED901A',
        padding: '8px 12px',
      },
    },
    {
      label: 'Delete Visitor',
      confirm: true,
      confirmButton: {
        title: 'Delete',
        style: {
          background: '#E45858',
          color: '#fff',
          border: '1px solid #E45858',
        },
      },
      confirmTitle: 'Delete Visitor',
      confirmMessage: `This action can not be undone. Are you sure \n \nwant to delete Visitor?`,

      onClick: () => removeVisitor(),
      style: {
        color: '#E45858',
        border: '1px solid #E45858',
        background: '#FFF',
        padding: '8px 12px',
      },
    },
  ];
  const removeVisitor = () => {
    dispatch(
      remove({
        name: '/visitor',
        id: `${slug}/`,
        customName: 'visitors',
      })
    );
  };
  return (
    <div className="app-wrapper visitor-page">
      <ContainerHeader
        title="View Visitor"
        breadcrumb={[
          { link: '/visitors', label: 'Visitors' },
          { link: '', label: 'View Visitor' },
        ]}
      />

      <Board actions={adminActions}>
        <div className="section">
          <p style={{ marginTop: 40 }}>{`Updated at ${moment(
            view?.modifiedAt
          ).format('D MMMM YYYY')}`}</p>
          <p>{`Submited at ${moment(view?.createdAt).format(
            'D MMMM YYYY'
          )}`}</p>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="status"
                label="Status"
                margin="normal"
                value={view?.status}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Visitor Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="fullName"
                label="Full Name"
                margin="normal"
                value={view?.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="email"
                label="Email Address"
                margin="normal"
                value={view?.email}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <PhoneInput
                containerClass="phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: 10,
                }}
                disabled
                value={view?.phoneNumber}
                specialLabel="Phone Number"
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="visitorCountry"
                label="Visitor Country"
                margin="normal"
                value={view?.country}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="company"
                label="Company/Institution Name"
                margin="normal"
                disabled
                value={view?.companyName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyCountry"
                label="Company Country"
                margin="normal"
                value={view?.companyCountry}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                multiple
                disabled
                name="businessSector"
                options={
                  businessSectorList
                    ? businessSectorOptions && businessSectorOptions
                    : sector
                }
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                value={values.sector || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Business Sector"
                    name="businessSector"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: state.module.view,
});
const FormikView = withFormik(View, null, mapPropsToValue, null);
export default connect(mapStateToProps, null)(FormikView);
