import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import TextEditor from 'components/Form/TextEditor';
import { load, remove } from 'redux/actions';
import {useDispatch, useSelector } from 'react-redux';
import Gallery from 'components/Gallery';
import moment from 'moment';



const View= () => {
  const history = useHistory();
  const id = useParams().id;
  const { business } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'business',
        id: id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = business?.data;

  return (
       <div className="app-wrapper business-page">
      <ContainerHeader
        title="Add Business Inquiries"
        breadcrumb={[
          { link: '/business-inquiry', label: 'Business Inquiries' },
          { link: '', label: 'Add Business Inquiries' },
        ]}
      />
      <Board
      //disabled={!(dirty && isValid)}
      actions={[
        {
          label: 'Edit Business Inquiries',
          noDisabled: true,
          onClick: () => history.push(`/Business-inquiry/${id}/edit`),
          style: {
            color: '#fff',
            border: '1px solid #ED901A',
            background: '#ED901A',
          },
        },
        {
          label: 'Delete Business Inquiries',
          noDisabled: true,
          onClick: () => {
            dispatch(
              remove({
                name: 'business',
                id: id,
                customName: 'business-inquiry',
              })
            );
          },
          confirm: true,
          confirmButton: {
            title: 'Delete',
            style: {
              color: '#fff',
              background: '#E14040',
              border: '1px solid #E14040',
            },
          },
          confirmTitle: 'Delete Business Inquiries',
          confirmMessage:
            'Are you sure you want to Delete The Business Inquiries?',
          style: {
            border: '1px solid #E45858',
            color: '#E45858',
          },
        },
      ]}
      >
           <div className="section">
          <p className="date">
            Updated at {moment(view?.modifiedAt).format('DD MMMM YYYY')}
          </p>
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMMM YYYY')}
          </p>
       
        </div>

        <div className="section">
          <Typography className="title">Business Inquiries Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
            <TextField
                fullWidth
                required
                variant="outlined"
                name="status"
                label="Status"
                margin="normal"
                value={view?.status}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
            <TextField
                fullWidth
                required
                variant="outlined"
                name="Title"
                label="Status"
                margin="normal"
                value={view?.title}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
            <TextEditor
                name="description"
                label="Description"
                value={view?.content || ''}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
            <TextEditor
                name="Catatan Progress"
                label="Catatan Progress"
                value={view?.catatan_progress || ''}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
            <TextField
                fullWidth
                required
                variant="outlined"
                name="Negara"
                label="Status"
                margin="normal"
                value={view?.country?.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
          </Grid>
          <Grid container spacing={3}>
          <Grid sm={6} item>
          <TextField
                fullWidth
                required
                variant="outlined"
                name="Title"
                label="Jumlah Permintaan"
                margin="normal"
                value={view?.jumlah_permintaan}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
            <TextField
                fullWidth
                required
                variant="outlined"
                name="Sector"
                label="Sector"
                margin="normal"
                value={view?.sector.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        
          <Grid container spacing={3}>
          <Grid sm={6} item>
          <TextField
                fullWidth
                required
                variant="outlined"
                name="Perwakilan RI"
                label="Perwakilan RI"
                margin="normal"
                value={view?.user.companyName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
           
            <Grid sm={6} item>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                name="publishedDate"
                label="Published Date"
                margin="normal"
                value={moment(view?.publishedDate).format('DD/MM/YYYY')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </Grid>
          </Grid>
        </div>
     

        <div className="section">
          <Grid container spacing={3}>
           <Grid sm={12} item>

              <Typography
                className="title"
                style={{ fontSize: 16, fontWeight: 500, marginBottom: 16 }}
              >
                Foto
              </Typography>
 </Grid>
	  <Grid sm={3} item>
    <Gallery
                title="Foto 1"
                images={[{ url: view?.foto_1 }]}
              />
 </Grid>
  <Grid sm={3} item>
  <Gallery
                title="Foto 1"
                images={[{ url: view?.foto_2 }]}
              />
 </Grid>
  

  <Grid sm={3} item>
  <Gallery
                title="Foto 1"
                images={[{ url: view?.foto_3 }]}
              />
 </Grid>
    <Grid sm={3} item>
    <Gallery
                title="Foto 1"
                images={[{ url: view?.foto_4 }]}
              />
 </Grid>

  

  
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                name="deadlinedDate"
                label="Deadlined Date"
                margin="normal"
                value={moment(view?.deadlinedDate).format('DD/MM/YYYY')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

export default View;
