import axios from 'axios';
import { BASE_URL } from './url';
import b64toBlob from 'b64-to-blob'

export async function uploadBase64Image(imgBase64, type) {
  const parts = imgBase64.split(';base64,');
  const contenType = parts[0].split(':')[1];
  const file = b64toBlob(parts[1], contenType)
  const fd = new FormData();
  fd.append('media', file);
  fd.append('type', type);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const res = await axios.post(`${BASE_URL}/api/upload/media`, fd, config)
  
  return res
}