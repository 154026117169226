import React, { useEffect } from 'react';
import { useParams,useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Board from 'components/Board';
import ContainerHeader from 'components/BreadCrumbTitle';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';
import { edit,load } from 'redux/actions/Module';
import UploadImage from 'components/Upload/UploadImage';
import TextEditor from 'components/Form/TextEditor';
import _ from 'lodash';
import { uploadBase64Image } from 'utils/uploadMedia';

const initialValueForm = {
  name: '',
  companyEmail: '',
  password: '',
  country:'',
  companyName:'',
  phoneNumber:'',
};

const mapPropsToValues = ({ users }) => {
  if (users && users.data) {
    const data = users.data;
    return {
      name: data?.name,
      companyEmail: data?.companyEmail,
      password: data?.password,
      country:data?.country.name,
      companyName: data?.companyName,
      phoneNumber:data?.phoneNumber,
    };
  }
  return initialValueForm;
};

const handleFormSubmit = async (payload, ctx) => {
const  {iduser} = ctx.props.match.params;
 let data = payload;
  ctx.props.edit({
    name: 'users',
    id: iduser,
    data: {
      name : data.name,
      companyEmail : data.companyEmail,
      password: data.password,
      country: data.country,
      companyName : data.companyName,
      phoneNumber : data.phoneNumber,
    },
    noLink: true,
    onSuccess: (res) => ctx.props.handleOpen(res),
    });
 };

const Edit = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;
 
  const history = useHistory();
  const {iduser} = useParams();
  const { users,country} = useSelector((state) => state.module.view);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(
      load({
        name:'countrycms?keyword=&skip=0&limit=1000&order=&orderMethod=desc',
        customName: 'country',
      })
    );
  
    dispatch(
      load({
        name: 'users',
        id: iduser,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const listcountrycms = country?.data?.map((n) => ({
    value: n.name,
    title: n.name,
  }));
  
  const view = users?.data;
    return (
      
      <div className="app-wrapper">
       <ContainerHeader
        title="Edit Business Inquiries"
        breadcrumb={[
          { link: '/users', label: 'User Perwakiilan' },
          { link: '', label: 'Edit User Perwakiilan' },
        ]}
      />
        <Board
        disabled={!(dirty && isValid)}
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save',
              style: {
                background: '#1AB759',
                border: '1px solid #1AB759',
                color: '#fff',
                width: 100,
              },
            },
            confirmTitle: 'Save Changes',
            confirmMessage: 'Do you wish to save your changes?',
            onClick: () => handleSubmit(),
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
           
          },
          {
            label: 'Cancel',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            onClick: () => history.goBack(),
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            
          },
        ]}
      >
      
       <div className="section">
       
       
         <TextField
            fullWidth
            required
            variant="outlined"
            name="name"
            label="Username"
            margin="normal"
            
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            required
            variant="outlined"
            name="phoneNumber"
            label="phone Number"
            margin="normal"
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
            value={values.phoneNumber}
            onBlur={handleBlur}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField 
            fullWidth
            required
            variant="outlined"
            name="companyName"
            label="Perwakilan"
            margin="normal"
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
            value={values.companyName}
            onBlur={handleBlur}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
	   <Autocomplete
                name="country"
                options={listcountrycms}
                value={{ title: view?.country.name, value: view?.country.name }}
                onChange={(e, val) =>
                    setFieldValue('country', val !== null ? val : '')
                }
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    name="country"
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    value={{ title: view?.country.name, value: view?.country.name }}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                />
          <TextField 
            fullWidth
            required
            type="email"
            name="companyEmail"
            label="Email"
            margin="normal"
            variant="outlined"
            size="small"
            value={values.companyEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            required
            name="password"
            label="Masukan kembali Password kalau tidak ingat masukan password baru"
            type="password"
            margin="normal"
            variant="outlined"
            size="small"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
         </div>
        </Board>
      </div>
    );
  };

  const mapStateToProps = (state) => ({
    users: state.module.view.users,
  });
  
  const mapDispatchToProps = {
    edit,
  };
  
  
const FormikEdit = withFormik(
  Edit,
  schemeValidations,
  mapPropsToValues,
  handleFormSubmit
);

export default connect(mapStateToProps, mapDispatchToProps)(FormikEdit);

