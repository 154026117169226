import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Chip, Grid, Button } from '@material-ui/core';

import { BASE_URL } from 'utils/url';

Image.propTypes = {
  value: PropTypes.array,
  onClose: PropTypes.func,
};

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      antrian: [],
      maxSize: '',
    };
    this.fileInput = React.createRef();
  }

  handleChange = async (event) => {
    event.preventDefault();
    this.setState({ maxSize: '' });
    const { files } = event.target;
    // const typeValidation = (this.props.typeFile =
    //   event.target.files &&
    //   event.target.files[0].type !== 'image/jpeg' &&
    //   event.target.files &&
    //   event.target.files[0].type !== 'application/pdf' &&
    //   event.target.files &&
    //   event.target.files[0].type !== 'image/jpg');
    // if (typeValidation) {
    //   this.setState({
    //     maxSize: 'The document you uploaded is in the wrong format.',
    //   });
    // } else
    const maxFileSize = this.props.maxSize || 500;
    // console.log(maxFileSize);

    if (this.props.type === 'video_banner') {
      // INA-134
      const duration = await this.readUploadedFileAsText(files[0]); // INA-134
      console.log(duration);
      if (duration > 0.502712) {
        this.setState({
          maxSize: `The video you uploaded exceeded the max. duration of 30 seconds.`,
        });
      } else if (
        Array.from(files)
          .map((n) => n.size / 10024 > maxFileSize)
          .includes(true)
      ) {
        this.setState({
          maxSize: `The document you uploaded exceeded the max. size of ${maxFileSize /
            100}MB`,
        });
      } else {
        // console.log(files[0]);
        this.handleUpload(files[0]);
      }
    } else {
      if (
        Array.from(files)
          .map((n) => n.size / 10024 > maxFileSize)
          .includes(true)
      ) {
        this.setState({
          maxSize: `The document you uploaded exceeded the max. size of ${maxFileSize /
            100}MB`,
        });
      } else {
        this.handleUpload(event.target.files[0]);

        // this.setState({ antrian: Array.from(files) }, () => this.handleRead());
      }
    }

    // event.target.value = null;
  };
  // INA-134
  readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        let media = new Audio(temporaryFileReader.result);
        media.onloadedmetadata = function() {
          resolve(media.duration / 60);
        };
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };
  handleRead = () => {
    const { antrian } = this.state;
    const reader = new FileReader();
    reader.onloadend = () => {
      this.handleUpload({
        base64: reader.result,
        name: antrian[0].name,
        size: antrian[0].size,
        type: antrian[0].type,
      });
    };
    if (antrian[0]) {
      reader.readAsDataURL(antrian[0]);
    }
  };

  handleUpload = (data) => {
    this.setState({ loading: true });
    let fd = new FormData();
    fd.append('media', data);
    fd.append('type', this.props.type);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    axios
      .post(`${BASE_URL}/api/upload/media`, fd, config)
      .then((res) => {
        const { value, onImageChange, multiple, title } = this.props;
        const { antrian } = this.state;
        this.setState({ antrian: antrian.slice(1), loading: false }, () => {
          if (!multiple) {
            // this.props.state(data.name);
            if (title) {
              onImageChange(title, data?.name);
            }
            onImageChange(this.props.name, res.data.originalUrl);
          } else if (value) {
            onImageChange(this.props.name, [...value, res.data.originalUrl]);
          } else {
            onImageChange([res.data]);
          }
        });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {});
      });
  };

  handleClose = (id) => {
    const { value, onImageChange } = this.props;
    const newData = value.filter((n, nid) => nid !== id);
    onImageChange(newData);
  };

  render() {
    const {
      // title,
      name,
      error,
      label,
      value,
      multiple,
      required,
      onBlur,
      isEditCompanyProfile,
      isBanner,
      isMedia,
    } = this.props;
    const { maxSize } = this.state;
    const accept =
      isEditCompanyProfile || isMedia
        ? 'application/pdf,image/jpg,image/jpeg,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        : isBanner
        ? 'video/mp4'
        : 'application/pdf,image/jpg,image/jpeg,image/png';
    return (
      <div>
        {/* <div className="section"> */}
        <Typography
          style={{ marginBottom: 20, fontWeight: 'bold', fontSize: 16 }}
        >
          {label}
        </Typography>
        {/* </div> */}
        <Chip
          variant="outlined"
          label={
            value?.split('/')[value?.split('/').length - 1].slice(0, 30) ||
            'No File'
          }
          onClick={() => (window.location.href = value)}
          disabled={[undefined, '', null, []].includes(value)}
          style={{
            width:
              !value?.split('/')[value?.split('/').length - 1].slice(0, 30) &&
              '30%',
          }}
        />

        <Grid container>
          <Grid sm={2}>
            <div style={{ marginTop: 20 }}>Edit File</div>
          </Grid>
          <Grid>
            <div>
              <label htmlFor={name || 'image'}>
                <Button
                  onClick={() => this.fileInput.current.click()}
                  style={{
                    background: '#ED901A',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                    marginTop: 13,
                    width: 159,
                    height: 40,
                  }}
                >
                  Select File
                </Button>
              </label>
              <input
                ref={this.fileInput}
                accept={accept}
                id={name || 'image'}
                type="file"
                name={name || 'image'}
                onChange={this.handleChange}
                multiple={multiple}
                required={required}
                onBlur={onBlur}
                style={{
                  display: 'none',
                }}
              />
            </div>
          </Grid>
        </Grid>

        {error && (
          <Typography
            color="error"
            style={{ marginBottom: 16, fontSize: '0.75rem' }}
          >
            {error}
          </Typography>
        )}
        {!maxSize && (
          <div>
            {isEditCompanyProfile
              ? 'Files: .pdf (Max size 5MB)'
              : isBanner
              ? ''
              : isMedia
              ? ''
              : 'Files: .pdf .png .jpg (Max size 5MB)'}
          </div>
        )}
        {maxSize && (
          <Typography style={{ fontSize: '0.75rem' }} color="error">
            {maxSize}
          </Typography>
        )}
      </div>
    );
  }
}

UploadImage.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  notif: PropTypes.func,
  error: PropTypes.string,
  onImageChange: PropTypes.func,
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(UploadImage);
