import React, { Component } from 'react';
import axios from 'axios';
import { CircularProgress, IconButton, Avatar } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import { connect } from 'react-redux';
import { BASE_URL } from 'utils/url';
import { getUser } from 'utils/localStorage';
import { notif } from 'redux/actions/Global';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      antrian: [],
      maxSize: '',
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ maxSize: '' });
    const { files } = event.target;
    if (
      Array.from(files)
        .map((n) => n.size > 2097152)
        .includes(true)
    ) {
      this.setState({ maxSize: 'Maximum upload file size: 2mb' });
    } else {
      this.setState({ antrian: Array.from(files) }, () => this.handleRead());
    }
    event.target.value = null;
  };

  handleRead = () => {
    const { antrian } = this.state;
    const reader = new FileReader();
    reader.onloadend = () => {
      this.handleUpload({
        base64: reader.result,
        name: antrian[0].name,
        size: antrian[0].size,
        type: antrian[0].type,
      });
    };
    if (antrian[0]) {
      reader.readAsDataURL(antrian[0]);
    }
  };

  handleUpload = (data) => {
    this.setState({ loading: true });

    axios({
      method: 'post',
      url: `${BASE_URL}/api/attachments`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${getUser().token}`,
      },
      data: {
        files: data,
        category: 'upload',
      },
    })
      .then((res) => {
        const { value, onImageChange, multiple } = this.props;
        const { antrian } = this.state;
        this.setState({ antrian: antrian.slice(1), loading: false }, () => {
          if (!multiple) {
            onImageChange([res.data]);
          } else if (value) {
            onImageChange([...value, res.data]);
          } else {
            onImageChange([res.data]);
          }
          this.handleRead();
          this.props.notif({
            open: true,
            variant: 'success',
            message: res.response
              ? res.response.data.message
              : 'Success Uploading Image',
          });
        });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          this.props.notif({
            open: true,
            variant: 'error',
            message: err.response
              ? err.response.data.message
              : 'Error Uploading Image',
          });
        });
      });
  };

  handleDelete = (id) => {
    const { value, onImageChange } = this.props;
    const newData = value.filter((n, nid) => nid !== id);
    onImageChange(newData);
  };

  render() {
    const { required, onBlur, value, name } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <input
          style={{ display: 'none' }}
          accept="image/*"
          id={name || 'image'}
          type="file"
          name={name || 'image'}
          onChange={this.handleChange}
          required={required}
          onBlur={onBlur}
        />
        <div style={{ position: 'relative' }}>
          <label htmlFor={name || 'image'}>
            <Avatar
              src={
                value && value[0] ? value[0].image || value[0].fileOriginal : ''
              }
              style={{
                borderRadius: '50%',
                width: 234,
                height: 234,
                boxShadow:
                  ' 0px 0px 2px 1px rgba(145, 158, 171, 0.02), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
              }}
            />
            <IconButton
              component="span"
              style={{
                backgroundColor: 'white',
                boxShadow: '0px 2px 8px 1px rgba(0, 0, 0, 0.08)',
                borderRadius: '50%',
                position: 'absolute',
                bottom: 10,
                right: 10,
              }}
            >
              <CameraAlt />
            </IconButton>
            {Boolean(loading) && (
              <CircularProgress
                size={60}
                style={{ position: 'absolute', top: 90, left: 90, zIndex: 100 }}
              />
            )}
          </label>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  notif,
};

class FormikUploadImage extends Component {
  onImageChange = (val) => {
    this.props.onImageChange(this.props.name, val);
  };

  render() {
    const { onImageChange, ...otherProps } = this.props;
    return <UploadImage onImageChange={this.onImageChange} {...otherProps} />;
  }
}

export default connect(null, mapDispatchToProps)(FormikUploadImage);
