import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import withFormik from 'utils/withFormik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { sendEmail } from 'redux/actions';

const schemeValidations = () =>
  Yup.object().shape({
    to: Yup.string()
      .required("Field can't be blank")
      .email('Email address uses an invalid email format.'),
    subject: Yup.string().required("Field can't be blank"),
    message: Yup.string().required("Field can't be blank"),
  });

const initialValueForm = {
  to: '',
  subject: '',
  message: '',
};

const handleFormSubmit = (payload, ctx) => {
  const { to, subject, message } = payload;
  const id = ctx.props.id;
  ctx.props.sendEmail({
    name: `appointment/${id}/mail`,
    data: {
      to,
      subject,
      message,
    },
    onSuccess: () => ctx.props.handleOpen(false),
  });
};

const SendEmailModal = ({ open, handleOpen, ...props }) => {
  const {
    data,
    values,
    touched,
    errors,
    isValid,
    dirty,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  useEffect(() => {
    if (data?.picEmail) {
      const email = data?.picEmail;
      setFieldValue('to', email);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent style={{ padding: 40 }}>
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Send Email
        </Typography>
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={6}>
            <TextField
              disabled
              fullWidth
              required
              variant="outlined"
              name="to"
              label="To"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.to && errors.to)}
              helperText={touched.to && errors.to}
              value={values.to}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={6}>
            <TextField
              fullWidth
              required
              variant="outlined"
              name="subject"
              label="Subject"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.subject && errors.subject)}
              helperText={touched.subject && errors.subject}
              value={values.subject}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={12}>
            <TextField
              fullWidth
              required
              multiline
              rows={6}
              variant="outlined"
              name="message"
              label="Message"
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.message && errors.message)}
              helperText={touched.message && errors.message}
              value={values.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!(dirty && isValid)}
          onClick={handleSubmit}
          style={{
            background: !(dirty && isValid) ? '#E7E7E7' : '#1AB759',
            border: `1px solid ${!(dirty && isValid) ? '#E7E7E7' : '#1AB759'}`,
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  sendEmail,
};

const FormikSendEmail = withFormik(
  SendEmailModal,
  schemeValidations,
  initialValueForm,
  handleFormSubmit
);

export default connect(null, mapDispatchToProps)(FormikSendEmail);
