import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField } from '@material-ui/core';
import Board from 'components/Board';
import ContainerHeader from 'components/BreadCrumbTitle';
import UploadImage from './components/UploadImage';

const Edit = () => {
  const history = useHistory();

  const handleSubmit = () => {};

  return (
    <div className="app-wrapper">
      <ContainerHeader
        title="Edit Profile"
        breadcrumb={[
          { link: '/profiles', label: 'View Profile' },
          { link: '/profiles', label: 'Edit Profile' },
        ]}
      />

      <Board
        actions={[
          {
            label: 'Save Changes',
            confirm: true,
            confirmButton: {
              title: 'Save Change',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Save Changes',
            confirmMessage: 'Do you wish to save your changes?',
            style: {
              background: '#1AB759',
              color: '#fff',
              border: '1px solid #1AB759',
              padding: '8px 17px',
            },
            onClick: () => handleSubmit(),
          },
          {
            label: 'Cancel',
            confirm: true,
            confirmButton: {
              title: 'Ok',
              style: {
                background: '#1AB759',
                color: '#fff',
                border: '1px solid #1AB759',
              },
            },
            confirmTitle: 'Cancel change',
            confirmMessage: 'Do you want to cancel your changes?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => history.goBack(),
          },
        ]}
      >
        <Grid container justify="center" spacing={5}>
          <Grid item>
            <UploadImage
              name="companyLogoUrl"
              value=""
              // onImageChange={setFieldValue}
              // error={errors.companyLogoUrl}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextField
              name="username"
              label="Username"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              disabled
              required
              name="email"
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextField
              name="password"
              label="Password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextField
              name="firstName"
              label="First Name"
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              name="lastName"
              label="Last Name"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Board>
    </div>
  );
};

export default Edit;
