import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import { EmailOutlined as EmailIcon } from '@material-ui/icons';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { load, remove } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SendEmail from './SendEmail';

const View = () => {
  const { appointmentId } = useParams();
  const [openSendMail, setOpenSendMail] = useState(false);

  const { appointment } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'appointment',
        id: appointmentId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = appointment && appointment.data;

  return (
    <div className="app-wrapper appointment-page">
      <ContainerHeader
        title="View Appointment"
        breadcrumb={[
          { link: '/appointments', label: 'Appointment' },
          { link: '', label: 'View Appointment' },
        ]}
      />

      <Board
        actions={[
          {
            icon: <EmailIcon />,
            onClick: () => setOpenSendMail(true),
            style: {
              color: '#3C91D6',
              border: '1px solid #3C91D6',
              fontSize: 8,
            },
          },
          {
            label: 'Delete Appointment',
            onClick: () => {
              dispatch(
                remove({
                  name: 'appointment',
                  id: appointmentId,
                  customName: 'appointments',
                })
              );
            },
            confirm: true,
            confirmButton: {
              title: 'Delete',
              style: {
                color: '#fff',
                background: '#E14040',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Appointment',
            confirmMessage: 'Are you sure you want to delete the appointment?',
            style: {
              color: '#E45858',
              border: '1px solid #E45858',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Submitted at{' '}
            {moment(value && value.createdAt).format('DD MMM YYYY')}
          </p>
          <Typography className="sub-title">Recipient Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyName"
                label="Company Name"
                margin="normal"
                disabled
                value={value?.exhibitor?.user?.companyName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyEmail"
                label="Company Email Address"
                margin="normal"
                disabled
                value={value?.exhibitor?.email}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Appointment Details</Typography>
          <Typography className="sub-title">Events</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="event"
                label="INA Business Forum"
                margin="normal"
                value={value?.event?.title}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="sub-title">Sender Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyName"
                label="Company Name"
                margin="normal"
                value={value?.user?.companyName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PICName"
                label="PIC Name"
                margin="normal"
                value={value?.picName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PICEmail"
                label="PIC Email Adress"
                margin="normal"
                value={value?.picEmail}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PICPhoneNumber"
                label="PIC Phone Number"
                margin="normal"
                value={value?.picPhone}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="sub-title">Meeting Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="date"
                label="Date"
                margin="normal"
                value={value?.meetDate}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="time"
                label="Time"
                margin="normal"
                value={value?.meetTime}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="haveLink"
                label="Do you have a meeting link?"
                margin="normal"
                value={value?.isMeetLink ? 'Yes' : 'No'}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="link"
                label="Link"
                margin="normal"
                value={value?.meetLink}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="password"
                label="Password"
                margin="normal"
                value={value?.meetPassword}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="sub-title">Meeting Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="subject"
                label="Message subject"
                margin="normal"
                value={value && value.subject}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                multiline
                rows={4}
                variant="outlined"
                name="message"
                label="Message"
                margin="normal"
                value={value && value.message}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Board>

      {openSendMail && (
        <SendEmail
          id={appointmentId}
          data={value}
          open={openSendMail}
          handleOpen={(val) => setOpenSendMail(val)}
        />
      )}
    </div>
  );
};

export default View;
