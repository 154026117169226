import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signInV2 } from 'redux/actions';
import history from 'utils/history';

const ProcessLogin = (props) => {
  const { search } = history.location;
  const token = search.replace(/\?token=/, '');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signInV2({ token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default ProcessLogin;
