import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { load } from 'redux/actions/Module';
import { Grid, Typography } from '@material-ui/core';
import ImageIl from 'assets/illustration/home-image.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'utils/localStorage';
import _ from 'lodash';
import OverviewCard from './components/OverviewCard';

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { view } = useSelector((state) => state.module);

  useEffect(() => {
    dispatch(
      load({
        name: 'statistic',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statistic = view?.statistic?.data;

  return (
    <div className="app-wrapper">
      <Grid container spacing={3}>
        <Grid item container>
          <Grid item lg>
            <div className="container-content">
              <div className="text-content">
                <h3 className="title">
                  Welcome, {_.toUpper(getUser()?.user?.name)}
                </h3>
                {/* <div>
                  <h4>Current Active Business Forum:</h4>
                  <button className="btn">INA-CEE</button>
                </div> */}
              </div>
              <img src={ImageIl} alt="welcome img" />
            </div>
          </Grid>
        </Grid>

        <Grid item container direction="row" justify="space-between">
          <Typography style={{ fontSize: 32 }}>Overview</Typography>
        </Grid>

        <Grid item container spacing={5}>
          <Grid item lg={6}>
            <OverviewCard
              onClick={() => history.push('/products')}
              title="My Products"
              total={statistic?.product}
            />
          </Grid>
          <Grid item lg={6}>
            <OverviewCard
              onClick={() => history.push('/appointments')}
              title="My Appointments"
              total={statistic?.appointment}
            />
          </Grid>
{/*
          <Grid item lg={4}>
            <OverviewCard
              onClick={() => history.push('/inbox')}
              title="My Inbox"
              total={statistic?.inbox}
            />
          </Grid>
*/}

        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
