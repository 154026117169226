import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Grid, Typography } from '@material-ui/core';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from 'redux/actions';
import moment from 'moment';
import Gallery from 'components/Gallery';
import { REACT_APP_MAIN_WEB_URL } from 'utils/url';
import _ from 'lodash';
import TextEditor from 'components/Form/TextEditor';

const View = () => {
  const history = useHistory();
  const { productId } = useParams();
  const { products } = useSelector((state) => state.module.view);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      load({
        name: 'products',
        id: productId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = products?.data;
  return (
    <div className="app-wrapper product-page">
      <ContainerHeader
        title="View Products"
        breadcrumb={[
          { link: '/products', label: 'All Products' },
          { link: '', label: 'View Product' },
        ]}
      />

      <Board
        disabled={view?.status === 'draft'}
        actions={[
          {
            label: 'Edit Product',
            noDisabled: true,
            onClick: () => history.push(`/products/${productId}/edit`),
            style: {
              color: '#fff',
              border: '1px solid #ED901A',
              background: '#ED901A',
            },
          },
          {
            label: 'Delete Product',
            noDisabled: true,
            onClick: () => {
              dispatch(
                remove({
                  name: 'products',
                  id: productId,
                })
              );
            },
            confirm: true,
            confirmButton: {
              title: 'Delete',
              style: {
                color: '#fff',
                background: '#E14040',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Product',
            confirmMessage: 'Are you sure you want to Delete the product?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
            },
          },
          {
            label: 'Preview',
            disabled: true,
            onClick: () =>
              window.open(
                `${REACT_APP_MAIN_WEB_URL}/companies/${view?.exhibitor?.slug}?product=${productId}`
              ),
            style: {
              color: '#fff',
              border: '1px solid #0A5379',
              background: '#0A5379',
            },
          },
        ]}
      >
        <div className="section">
          <p className="date">
            Updated at {moment(view?.modifiedAt).format('DD MMM YYYY')}
          </p>
          <p className="date">
            Submitted at {moment(view?.createdAt).format('DD MMM YYYY')}
          </p>
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="status"
                label="Status"
                margin="normal"
                value={_.capitalize(view?.status)}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Product Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="productName"
                label="Product Name"
                margin="normal"
                value={view?.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="category"
                label="Category"
                margin="normal"
                value={view?.sector?.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="description"
                label="Product Description"
                value={view?.description || ''}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="exhibitor"
                label="Exhibitor"
                margin="normal"
                value={view?.exhibitor?.user?.companyName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="sku"
                label="HS Code"
                margin="normal"
                value={view?.SKU}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="dimension"
                label="Dimension"
                margin="normal"
                value={view?.dimension}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Gallery title="Image" images={view?.images} />
            </Grid>
          </Grid>
        </div>
      </Board>
    </div>
  );
};

export default View;
