import * as Yup from 'yup';

const schemeValidations = Yup.object().shape({
  id: Yup.string().required('ID can not be left blank'),
  region: Yup.string().required('Region can not be left blank'),
  order: Yup.string().required(
    'Select in which order this category will be shown.'
  ),
  status: Yup.string().required('Status must be selected'),
});

export default schemeValidations;
