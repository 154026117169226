import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress, Typography, IconButton } from '@material-ui/core';
import { CameraAlt, Close as CloseIcon } from '@material-ui/icons';
import { BASE_URL } from 'utils/url';
import { notif } from 'redux/actions/Global';
import Upload from 'assets/images/upload.svg';
class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queue: [],
      maxSize: '',
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ maxSize: '' });
    const { files } = event.target;
    const { maxFileSize } = this.props;
    const size = maxFileSize || 1000;
    if (
      Array.from(files)
        .map((n) => n.size / 10024 > size)
        .includes(true)
    ) {
      this.setState({
        maxSize: `The image you uploaded exceeded the max. size of ${size /
          100}mb.`,
      });
    } else {
      this.handleUpload(event.target.files[0]);
    }
    event.target.value = null;
  };

  handleRead = () => {
    const { queue } = this.state;
    const reader = new FileReader();
    reader.onloadend = () => {
      this.handleUpload({
        base64: reader.result,
        name: queue[0].name,
        size: queue[0].size,
        type: queue[0].type,
      });
    };
    if (queue[0]) {
      reader.readAsDataURL(queue[0]);
    }
  };

  handleUpload = (data) => {
    this.setState({ loading: true });
    const { type } = this.props;
    let typeFormData = type || 'product_image';
    let fd = new FormData();
    fd.append('media', data);
    fd.append('type', typeFormData);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    axios
      .post(`${BASE_URL}/api/upload/media`, fd, config)
      .then((res) => {
        const { value, onImageChange, multiple } = this.props;
        const { queue } = this.state;
        this.setState({ queue: queue.slice(1), loading: false }, () => {
          if (!multiple) {
            onImageChange([res.data.originalUrl]);
          } else if (value) {
            onImageChange([...value, res.data.originalUrl]);
          } else {
            onImageChange([res.data]);
          }
          this.props.notif({
            open: true,
            variant: 'success',
            message: res.response
              ? res.response.data.message
              : 'Success Uploading Image',
          });
        });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          this.props.notif({
            open: true,
            variant: 'error',
            message: err.response
              ? err.response.data.message
              : 'Error Uploading Image',
          });
        });
      });
  };

  handleClose = (id) => {
    const { value, onImageChange } = this.props;
    const newData = value.filter((n, nid) => nid !== id);
    // console.log(newData);
    onImageChange(newData);
  };

  render() {
    const {
      required,
      onBlur,
      multiple,
      value,
      name,
      error,
      disableDelete,
      max,
    } = this.props;
    const { maxSize, loading } = this.state;
    const maxFile = max || 5;
    // console.log(error);
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {value &&
            value.length > 0 &&
            value.map((n, idn) => (
              <div
                style={{
                  width: '160px',
                  height: '170px',
                  borderRadius: 10,
                  border: '1px solid #BDBDBD',
                  marginRight: 16,
                  marginBottom: 16,
                  position: 'relative',
                }}
                key={idn}
              >
                <img
                  src={n}
                  alt="item"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
                {!disableDelete && (
                  <IconButton
                    onClick={() => this.handleClose(idn)}
                    component="span"
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid red',
                      width: 16,
                      height: 16,
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  >
                    <CloseIcon style={{ width: 12, color: 'red' }} />
                  </IconButton>
                )}
              </div>
            ))}

          {value.length < maxFile && (
            <div>
              <label htmlFor={name || 'image'}>
                <img src={Upload} alt="upload" />

                <IconButton
                  component="span"
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 2px 8px 1px rgba(0, 0, 0, 0.08)',
                    borderRadius: '50%',
                    top: 70,
                    right: 20,
                  }}
                >
                  <CameraAlt />
                </IconButton>
              </label>
              {Boolean(loading) && <CircularProgress />}
            </div>
          )}

          <input
            accept="image/*"
            id={name || 'image'}
            type="file"
            name={name || 'image'}
            onChange={this.handleChange}
            multiple={multiple}
            required={required}
            onBlur={onBlur}
            style={{
              display: 'none',
            }}
          />
        </div>

        {error && (
          <Typography color="error" style={{ fontSize: 14 }}>
            {error}
          </Typography>
        )}

        {maxSize && (
          <Typography color="error" style={{ fontSize: 14 }}>
            {maxSize}
          </Typography>
        )}
      </div>
    );
  }
}

UploadImage.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  notif: PropTypes.func,
  error: PropTypes.string,
  onImageChange: PropTypes.func,
};

const mapDispatchToProps = {
  notif,
};

export default connect(null, mapDispatchToProps)(UploadImage);
