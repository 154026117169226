import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'querystringify';
import { list, table } from 'redux/actions/Module';
import { tableFilter } from 'redux/actions/Global';
import { getUser } from 'utils/localStorage';
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TableHead from './TableHead';
import TableToolbar from './TableToolbar';
import TableFooter from './TableFooter';
import ErrorIl from 'assets/illustration/404-error.svg';

class Tables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      selected: [],
      orderBy: props.columnHead[props.columnHead.length - 1].id,
      order: 'desc',
      isFilter: false,
      filterForm: {},
      keyword: props.keyword || '',
      orderByTerm: props.orderByTerm ? props.orderByTerm : 'order',
      orderTerm: props.orderTerm ? props.orderTerm : 'orderMethod'
    };
    this.user = getUser();
  }

  componentDidMount() {
    if(this.props.addQueryString !== undefined){
      this.loadModules({ addQueryString: this.props.addQueryString });

    }else{
      this.loadModules({});

    }
    this.user = getUser();
  }

  componentDidUpdate(prevProps) {
    if (this.props.withFilterModal) {
      if (this.props.addQueryString !== prevProps.addQueryString) {
        this.loadModules({ addQueryString: this.props.addQueryString });
      }
    }
    if (this.props.isSinglePage) {
      if (this.props.reload !== prevProps.reload) {
        this.props.list({
          name: this.props.name,
        });
      }
    }
  }

  loadModules = ({
    page,
    pageSize,
    order,
    orderBy,
    keyword,
    data,
    addQueryString,
  }) => {
    const st = this.state;
    const param = {
      keyword: keyword || st.keyword,
      skip: ((page || st.page) - 1) * (pageSize || st.pageSize),
      limit: pageSize || st.pageSize,
      // advanceSearch: true,
      [st.orderByTerm]: orderBy || st.orderBy || '',
      [st.orderTerm]: order || st.order,
      ...(data || st.filterForm),
      ...this.props.addQuery,
    };
    if(this.props.setKeyword) this.props.setKeyword(keyword)
    let query = queryString.stringify(param);
    let mergeQuery = query;
    if (addQueryString) {
      mergeQuery = query + `&${addQueryString}`;
    }
    this.props.list({
      name: this.props.name,
      query: mergeQuery,
    });
  };

  handleSelectAllClick = (event, checked) => {
    const data = this.props.data[this.props.name];
    if (data && data.data && checked) {
      this.setState({ selected: data.data.map((n) => n._id) });
      return;
    }
    this.setState({
      selected: [],
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy }, () => {
      this.loadModules({ order, orderBy });
    });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (page) => {
    this.setState({ page }, () =>
      this.loadModules({
        page,
        addQueryString: this.props.addQueryString,
      })
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ pageSize: event.target.value }, () =>
      this.loadModules({
        pageSize: event.target.value,
        addQueryString: this.props.addQueryString,
      })
    );
  };

  handleChangeForm = (e) => {
    if (e.target.type && e.target.type !== 'checkbox') {
      this.setState({
        filterForm: {
          ...this.state.filterForm,
          [e.target.name]: e.target.value,
        },
        page: 1,
        selected: [],
      });
      setTimeout(() => {
        this.loadModules({ data: this.state.filterForm });
      }, 1000);
    }
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  number = (row, page, index) => {
    const headNumber = row * (page - 1);
    const number = headNumber + (index + 1);
    return number;
  };

  render() {
    const {
      columnHead,
      columnBody,
      title,
      data,
      name,
      // viewDetail,
      // history,
      loading,
      minWidth,
      actions,
      withFilter,
      withFilterModal,
      withSearch,
      isBulkDelete,
      filterTable,
      tableFilter,
      disableFooter,
    } = this.props;

    const {
      selected,
      order,
      orderBy,
      pageSize,
      page,
      isFilter,
      filterForm,
    } = this.state;

    if (orderBy === null && columnHead) {
      this.setState({ orderBy: columnHead[columnHead.length - 1].id }, () => {
        this.loadModules({});
      });
    }
    const disablePagination = disableFooter || false;
    return (
      <Paper className="table-custom">
        <TableToolbar
          numSelected={selected.length}
          title={title}
          selected={selected}
          name={name}
          actions={actions}
          withSearch={withSearch}
          withFilter={withFilter}
          withFilterModal={withFilterModal}
          isFilter={isFilter}
          removeSelected={() => this.setState({ selected: [] })}
          changeIsFilter={(val) => {
            if (!val) {
              this.setState({ filterForm: {} }, () => {
                document.forms.panuForm.reset();
                this.loadModules({});
              });
              tableFilter({
                ...filterTable,
                [name]: null,
              });
            } else {
              tableFilter({
                ...filterTable,
                [name]: {},
              });
            }
            this.setState({ isFilter: val });
          }}
          searchKeyword={(val) => {
            this.setState(
              {
                keyword: val,
                page: 1,
                pageSize: 10,
              },
              () => {
                this.loadModules({ keyword: val, page: 1, pageSize: 10 });
              }
            );
          }}
        />

        <div className="tableWrapper">
          <form id="panuForm" onChange={this.handleChangeForm}>
            <Table
              className="table-parent"
              style={{ minWidth: minWidth || 800 }}
            >
              <TableHead
                columnHead={columnHead}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                order={order}
                orderBy={orderBy}
                rowCount={
                  data[name] && data[name].data && data[name].data.length
                }
                numSelected={selected.length}
                isBulkDelete={isBulkDelete}
              />

              <TableBody className="tableBody">
                {isFilter && (
                  <TableRow style={{ height: 'unset' }}>
                    {isBulkDelete && (
                      <TableCell padding="none" className="cellCheckbox" />
                    )}

                    {columnHead.map((n, idn) => (
                      <TableCell padding="none" className="cellBody" key={idn}>
                        {Boolean(filterForm[n.id]) &&
                          Boolean(filterForm[n.id].length) && (
                            <CloseIcon
                              className="closeIcon"
                              onClick={() => {
                                const accessDomInput =
                                  document && document.forms.panuForm[n.id];
                                accessDomInput.value =
                                  accessDomInput.defaultValue;
                                this.setState(
                                  { filterForm: { ...filterForm, [n.id]: '' } },
                                  () => {
                                    this.loadModules({});
                                  }
                                );
                              }}
                            />
                          )}

                        {n.date ? (
                          <input
                            className="input-parent date-filter"
                            format="dd/mm/yyyy"
                            type="date"
                            name={n.id}
                            value={
                              filterForm[n.id] || filterTable[n.id] || null
                            }
                            onChange={(e) => {
                              this.setState(
                                { filterForm: { ...filterForm, [n.id]: e } },
                                () => {
                                  if (
                                    filterTable &&
                                    typeof filterTable[name] === 'object'
                                  ) {
                                    tableFilter({
                                      ...filterTable,
                                      [name]: {
                                        ...filterTable[name],
                                        [n.id]: e,
                                      },
                                    });
                                  } else {
                                    tableFilter({
                                      ...filterTable,
                                      [name]: {
                                        [n.id]: e,
                                      },
                                    });
                                  }
                                  this.loadModules({});
                                }
                              );
                            }}
                          />
                        ) : (
                          <input
                            className={`input-parent ${!n.canFilter &&
                              'disableInput'}`}
                            name={n.id}
                            disabled={!n.canFilter}
                            defaultValue={!n.canFilter ? "Can't fill" : ''}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )}

                {loading && (
                  <tr>
                    <TableCell
                      colSpan={columnHead ? columnHead.length + 1 : 1}
                      style={{ padding: 'unset' }}
                    >
                      <LinearProgress />
                    </TableCell>
                  </tr>
                )}

                {data[name] && data[name].data && data[name].data.length <= 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={columnHead ? columnHead.length + 1 : 1}
                      style={{
                        textAlign: 'center',
                        fontSize: '1rem',
                        padding: '14px 0px',
                      }}
                    >
                      {this.state.keyword ? (
                        <div
                          style={{
                            padding: '40px 0',
                          }}
                        >
                          <img
                            src={ErrorIl}
                            alt="error-404"
                            style={{
                              marginBottom: 40,
                            }}
                          />
                          <div
                            style={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: '#696969',
                            }}
                          >
                            Sorry! We couldn’t find any results based on your
                            search
                          </div>
                        </div>
                      ) : (
                        'There is no data'
                      )}
                    </TableCell>
                  </TableRow>
                )}

                {data[name] &&
                  data[name].data &&
                  data[name].data.map((n, index) => {
                    const isSelected = this.isSelected(n._id);
                    return (
                      <TableRow
                        role="checkbox"
                        aria-checked={isSelected}
                        key={n.id}
                        selected={isSelected}
                        className="rowBody"
                      >
                        {isBulkDelete && (
                          <TableCell padding="none" className="cellCheckbox">
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(event, n._id)
                              }
                              checked={isSelected}
                              className="checkBoxIcon"
                            />
                          </TableCell>
                        )}

                        {columnBody.map((a, i) => (
                          <TableCell
                            key={i}
                            padding={a.disablePadding ? 'none' : 'default'}
                            // onClick={() =>
                            //   history.push(
                            //     `/${viewDetail.name ? viewDetail.name : name}/${
                            //       viewDetail ? n[viewDetail.by] : n.id
                            //     }`
                            //   )
                            // }
                            className="cellBody"
                          >
                            {(typeof a.parent === 'function'
                              ? a.parent(n)
                              : a.parent === 'number'
                              ? this.number(pageSize, page, index)
                              : n[a.parent] || a.parent) ||
                              (typeof a.parent === 'function' &&
                              a.parent(n) === 0
                                ? '0'
                                : '-')}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </form>
        </div>
        {!disablePagination && (
          <TableFooter
            count={data[name] && data[name].count}
            page={page}
            rowsPerPage={pageSize}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.module.list,
  loading: state.global.loadingTable,
  filterTable: state.global.tableFilter,
});

const mapDispatchToProps = {
  list,
  tableFilter,
  handleTable: table,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tables));
