import React from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { TextField } from "@material-ui/core";
import AutoCompleteMui from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { withStyles } from "@material-ui/styles";
import NoSsr from "@material-ui/core/NoSsr";
import styles from "./style";
import { BASE_URL } from "utils/url";
import { getUser } from "utils/localStorage";

class AutoComplete extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocus: props.value ? true : false,
			optionFirst: [],
			optionAsync: [],
			loading: false,
		};
		this.handleLoad = debounce(this.handleLoad, 800);
	}

	handleChange = (name) => (value) => {
		this.setState({
			[name]: value,
		});
	};

	componentDidMount() {
		if (this.props.url && !this.props.noPreload) {
			this.handleLoad("", true);
		}
	}

	UNSAFE_componentWillReceiveProps() {
		if (this.props.isFormOpen) {
			this.setState({ isFocus: this.props.isFormOpen });
		}
	}

	handleLoad = (e, firstTime) => {
		const { url, limit, urlName, oneFetch, addQuery, noPreload } = this.props;
		if (oneFetch ? firstTime : e || firstTime) {
			this.setState({ loading: true });
			if (noPreload) {
				this.setState({ optionAsync: [], optionFirst: [] });
			}
			axios
				.get(`${BASE_URL}/api${url}`, {
					params: {
						// advanceSearch: true,
						limit: limit || 10,
						[urlName]: e,
						...addQuery,
					},
					headers: {
						Authorization: `Token ${getUser().token}`,
					},
				})
				.then((res) => {
					const option = res.data.data.map((n) => this.props.dataStructure(n));
					this.setState({ loading: false });
					if (firstTime || noPreload) {
						this.setState({ optionFirst: option });
					} else {
						this.setState({ optionAsync: option });
					}
					this.forceUpdate();
				})
				.catch(() => {
					this.setState({ optionAsync: [], loading: false });
					this.forceUpdate();
				});
		} else {
			this.setState({ optionAsync: [] });
		}
	};

	render() {
		const { classes, onChange, name, label, url, error } = this.props;

		const { optionFirst, optionAsync } = this.state;

		return (
			<div className={classes.root}>
				<NoSsr>
					<AutoCompleteMui
						name={name}
						style={{ marginTop: 15 }}
						options={optionAsync.length !== 0 ? optionAsync : optionFirst}
						autoHighlight
						onChange={(e, val) =>
							val === null || val === "" || val === undefined
								? onChange({ id: 0, name: "", label: "", value: "" })
								: onChange(val)
						}
						onInputChange={(e, val) => url && this.handleLoad(val)}
						getOptionLabel={(option) => option.label}
						renderInput={(params) => (
							<TextField
								{...params}
								label={label}
								variant="outlined"
								name="status"
								error={error}
								helperText={error}
								inputProps={{
									...params.inputProps,
								}}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</NoSsr>
			</div>
		);
	}
}

AutoComplete.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AutoComplete);
