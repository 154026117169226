import * as Yup from 'yup';

const schemeValidations = Yup.object().shape({
  name: Yup.string().required('Product name can not be left blank.'),
  description: Yup.string().required(
    'Product description can not be left blank.'
  ),
  sector: Yup.string().required('Category can not be left blank.'),
  SKU: Yup.string()
    // .required('SKU can not be left blank.')
    .nullable(),
  dimension: Yup.string()
    // .required('Dimension can not be left blank.')
    .nullable(),
  images: Yup.array().min(
    1,
    'Image is required. Please upload a minimum of 1 image, up to 5 maximum.'
  ),
});

export default schemeValidations;
