import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { remove } from 'redux/actions/Module';

const ModalRemove = ({ open, handleOpen, name, id, customName }) => {
  const dispatch = useDispatch();
  const onDelete = () => {
    dispatch(
      remove({
        name,
        id,
        customName,
        noLink: true,
        onSuccess: (res) => handleOpen(res),
      })
    );
  };
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: '40px 40px 0 40px' }}>
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Delete Category
        </Typography>
        <Typography>
          This action can not be undone. Are you sure you want to delete the
          category?
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onDelete()}
          style={{
            background: '#E14040',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalRemove;
