import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import ModalRemove from 'components/ModalRemove';
import withFormik from 'utils/withFormik';
import { edit } from 'redux/actions/Module';
import * as Yup from 'yup';
import Confirm from 'components/Board/Confirm';

const schemeValidation = Yup.object().shape({
  name: Yup.string().required('Category name can not be left blank.'),
  order: Yup.string().required(
    'Select in which order this category will be shown.'
  ),
  status: Yup.string().required('Status can not be left blank'),
});

const initialValueForm = {
  id: '',
  name: '',
  order: 1,
  status: 'active',
};

const handleSubmitForm = (payload, ctx) => {
  const { id, name, order, status } = payload;
  ctx.props.edit({
    name: `news/category/${id}`,
    data: {
      categoryName: name,
      order,
      status,
    },
    noLink: true,
    onSuccess: (res) => ctx.props.handleOpen(res),
  });
};

const EditCategory = ({ open, handleOpen, dataEdit, ...props }) => {
  const [draftModal, setDraftModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  useEffect(() => {
    // eslint-disable-next-line
    setFieldValue('id', dataEdit.id);
    setFieldValue('order', dataEdit.order);
    setFieldValue('name', dataEdit.categoryName);
    setFieldValue('status', dataEdit.status);
    setFieldValue('isUsed', dataEdit.isUsed);

    // eslint-disable-next-line
  }, [dataEdit]);

  const handleCloseRemove = (res) => {
    setModalRemove(false);
    handleOpen(res);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: '40px 40px 0 40px' }}>
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Edit Category
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              name="name"
              label="Category Name"
              margin="normal"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextField
              fullWidth
              required
              type="number"
              variant="outlined"
              placeholder="1"
              name="order"
              label="Order"
              margin="normal"
              value={values.order}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.order && errors.order)}
              helperText={touched.order && errors.order}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <FormControl
              variant="outlined"
              fullWidth
              size="medium"
              margin="normal"
            >
              <InputLabel>Status</InputLabel>
              <Select
                value={values.status}
                name="status"
                label="Status"
                onChange={(event) =>
                  setFieldValue('status', event.target.value)
                }
                onBlur={handleBlur}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            values.isUsed ? setDeleteModal(true) : setModalRemove(true)
          }
          style={{
            background: '#E14040',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() =>
            values.status === 'inactive' && values.isUsed
              ? setDraftModal(true)
              : handleSubmit()
          }
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Save
        </Button>
      </DialogActions>
      {modalRemove && (
        <ModalRemove
          open={modalRemove}
          handleOpen={handleCloseRemove}
          name="news/category"
          id={values.id}
        />
      )}
      <Confirm
        open={draftModal}
        handleClose={() => setDraftModal(false)}
        handleAction={(e) => {}}
        handleContent={{
          button: {
            title: 'Save',
            style: {
              background: '#1AB759',
              border: '1px solid #1AB759',
              color: '#fff',
              width: 100,
              display: 'none',
            },
          },
          title: 'Draft Category',
          message:
            'Category can not be drafted when there are news associated under it. Please remove all news from this category before drafting it.',
        }}
      />
      <Confirm
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleAction={(e) => {}}
        handleContent={{
          button: {
            title: 'Delete',
            style: {
              background: !values.isUsed ? '#E45858' : '#BFBFBF',
              color: '#fff',
              border: !values.isUsed
                ? '1px solid #E45858'
                : '1px solid #BFBFBF',
              cursor: !values.isUsed ? 'pointer' : 'default',
            },
          },
          title: 'Delete Category',
          message: !values.isUsed
            ? 'This action can not be undone. Are you sure you want to delete the category?'
            : 'Category can not be deleted when there are news associated under it. Please remove all news from this category to before deleting it.',
        }}
      />
    </Dialog>
  );
};

const FormikEdit = withFormik(
  EditCategory,
  schemeValidation,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
});

export default connect(mapStateToProps, { edit })(FormikEdit);
