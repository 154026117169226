import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Grid, Typography, Chip } from '@material-ui/core';
import moment from 'moment';
import Board from 'components/Board';
import { load, add } from 'redux/actions';
import { connect, useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormikPhoneInput from 'components/FormikPhoneInput';
import { sector } from 'data';
import { asMutable } from 'seamless-immutable';
import withFormik from 'utils/withFormik';
import _ from 'lodash';
import { getUser } from 'utils/localStorage';

const initialValues = {
  sector: [],
};

const mapPropsToValue = ({ data }) => {
  const { exhibitor } = data;
  if (exhibitor) {
    let mutableData = asMutable(exhibitor, { deep: true });
    return {
      sector:
        mutableData &&
        mutableData.data.sector.map((val) => ({
          id: val,
          name: val,
        })),
      picPhone: exhibitor.data.user.phoneNumber,
      companyPhoneNumber: exhibitor.data.companyPhoneNumber,
    };
  }
  return initialValues;
};

const CompanyDetail = (props) => {
  const { values } = props;
  const history = useHistory();
  const { exhibitor, businessSectorList } = useSelector(
    (state) => state.module.view
  );
  const slug = useParams().exhibitorId;

  const dispatch = useDispatch();

  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : slug;

  useEffect(() => {
    dispatch(
      load({
        name: 'exhibitor',
        id: exhibitorId,
      })
    );

    dispatch(
      load({
        name: 'sector/getlist',
        customName: 'businessSectorList',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = exhibitor?.data;

  let businessSectorOptions = businessSectorList?.data.map((val) => ({
    id: val.name,
    name: val.name,
  }));

  const removeExhibitor = () => {
    dispatch(
      add({
        name: '/exhibitor',
        id: `${exhibitorId}/delete`,
        customRedirect: 'exhibitors',
      })
    );
  };

  const adminActions = [
    {
      label: 'Edit Exhibitor',
      onClick: () => {
        if (getUser()?.user?.roles.includes('administrator')) {
          history.push(`/exhibitors/${exhibitorId}/edit`);
        } else if (getUser()?.user?.roles.includes('exhibitor')) {
          history.push(`/my-company/edit`);
        }
      },
      style: {
        color: '#fff',
        border: '1px solid #ED901A',
        background: '#ED901A',
        padding: '8px 12px',
      },
    },
    {
      label: 'Delete Exhibitor',
      confirm: true,
      confirmButton: {
        title: 'Delete',
        style: {
          background: '#E45858',
          color: '#fff',
          border: '1px solid #E45858',
        },
      },
      confirmTitle: 'Delete Exhibitor',
      confirmMessage: `This action can not be undone. Are you sure \n \nwant to delete exhibitor?`,

      onClick: () => removeExhibitor(),
      style: {
        color: '#E45858',
        border: '1px solid #E45858',
        background: '#FFF',
        padding: '8px 12px',
      },
    },
  ];

  const exhibitorActions = [
    {
      label: 'Edit Exhibitor',
      onClick: () => {
        if (getUser()?.user?.roles.includes('administrator')) {
          history.push(`/exhibitors/${exhibitorId}/edit`);
        } else if (getUser()?.user?.roles.includes('exhibitor')) {
          history.push(`/my-company/edit`);
        }
      },
      style: {
        color: '#fff',
        border: '1px solid #ED901A',
        background: '#ED901A',
        padding: '8px 12px',
      },
    },
  ];

  const actions = getUser()?.user?.roles.includes('administrator')
    ? adminActions
    : getUser()?.user?.roles.includes('exhibitor')
    ? exhibitorActions
    : [];

  return (
    <div className="mt-4 exhibitor-page">
      <Board actions={actions}>
        <div>
          <p style={{ marginTop: 40 }}>{`Updated at ${moment(
            view?.modifiedAt
          ).format('D MMMM YYYY')}`}</p>
          <p>{`Submited at ${moment(view?.createdAt).format(
            'D MMMM YYYY'
          )}`}</p>
        </div>

        {!getUser()?.user?.roles.includes('exhibitor') && (
          <div className="section">
            <Grid container spacing={3}>
              <Grid sm={4} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="status"
                  label="Status"
                  margin="normal"
                  value={view?.status}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}

        <div className="section">
          <Typography className="title">Company Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyName"
                label="Company/Institution Name"
                margin="normal"
                value={view?.companyName}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="picCountry"
                label="PIC Country"
                margin="normal"
                value={view?.user?.country?.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <Autocomplete
                multiple
                disabled
                name="businessSector"
                options={
                  businessSectorList
                    ? businessSectorOptions && businessSectorOptions
                    : sector
                }
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                value={values.sector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Business Sector"
                    name="businessSector"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <FormikPhoneInput
                disabled
                name="companyPhoneNumber"
                inputProps={{ name: 'companyPhoneNumber' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                value={values.companyPhoneNumber}
                countryCodeEditable={false}
                specialLabel="Company Phone Number"
                {...props}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyEmail"
                label="Company Email"
                margin="normal"
                value={view?.user?.companyEmail}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyWebsite"
                label="Company Website"
                margin="normal"
                value={view?.companyWebsite}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyAddress"
                label="Company Address"
                margin="normal"
                value={view?.companyAddress}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="province"
                label="Province"
                margin="normal"
                value={view?.companyAddressProvince}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="district"
                label="District"
                margin="normal"
                value={view?.companyAddressDistrict}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="postalCode"
                label="Postal Code"
                margin="normal"
                value={view?.companyAddressPostalCode}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="companyCountry"
                label="Company Country"
                margin="normal"
                value={view?.companyCountry}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">PIC Details</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PICName"
                label="PIC Name"
                margin="normal"
                value={view?.user?.name}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <FormikPhoneInput
                disabled
                name="picPhone"
                inputProps={{ name: 'picPhone' }}
                containerClass="signUpVisitor-phoneInput"
                type="number"
                variant="outlined"
                inputStyle={{
                  minWidth: '100%',
                  minHeight: '56px',
                  marginTop: '15px',
                }}
                value={values.picPhone}
                countryCodeEditable={false}
                specialLabel="PIC Phone Number"
                isValid={(value, country) => {
                  if (value.match(/00000/)) {
                    return 'Phone number is not valid';
                  } else if (value.length === 0) {
                    return 'Phone number cannot be blank';
                  } else {
                    return true;
                  }
                }}
                {...props}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PICEmail"
                label="PIC Email"
                margin="normal"
                value={view?.email}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="PIC Position"
                label="PICPosition"
                margin="normal"
                value={view?.user?.position}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Documents</Typography>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="Applying As"
                label="Applying As"
                margin="normal"
                value={`Exhibitor for ${_.capitalize(view?.as)}`}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {['Indonesia', 'ID', ''].includes(view?.user?.country?.name) ? (
              <Grid sm={6} item>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  name="npwp"
                  label="NPWP"
                  margin="normal"
                  value={view?.noNpwp}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
          {['Indonesia', 'ID', ''].includes(view?.user?.country?.name) ? (
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    NPWP
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="npwp"
                    label={
                      view?.npwp?.split('/')[
                        view?.npwp?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.npwp}
                    style={{ width: '50%' }}
                    onClick={() => (window.location.href = view?.npwp)}
                    disabled={[undefined, '', null, []].includes(view?.npwp)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    NIB (Nomor Induk Berusaha)
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="nib"
                    label={
                      view?.nib?.split('/')[view?.nib?.split('/').length - 1] ||
                      'No File'
                    }
                    margin="normal"
                    value={view?.nib}
                    onClick={() => (window.location.href = view?.nib)}
                    disabled={[undefined, '', null, []].includes(view?.nib)}
                    style={{ width: '50%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    SIUP
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="siup"
                    label={
                      view?.siup?.split('/')[
                        view?.siup?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.siup}
                    style={{ width: '50%' }}
                    onClick={() => (window.location.href = view?.siup)}
                    disabled={[undefined, '', null, []].includes(view?.siup)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Surat Izin Ekspor
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="suratIzinEkspor"
                    label={
                      view?.suratIzinEkspor?.split('/')[
                        view?.suratIzinEkspor?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.suratIzinEkspor}
                    onClick={() =>
                      (window.location.href = view?.suratIzinEkspor)
                    }
                    style={{ width: '50%' }}
                    disabled={[undefined, '', null, []].includes(
                      view?.suratIzinEkspor
                    )}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Other Documents
                  </Typography>
                  {/* </div> */}
                  {view?.others?.map((n) => (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={
                        n?.file?.split('/')[n?.file?.split('/').length - 1] ||
                        'No File'
                      }
                      margin="normal"
                      value={n?.file}
                      style={{ width: '50%' }}
                      onClick={() => (window.location.href = n?.file)}
                      disabled={[undefined, '', null, []].includes(n?.file)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ))}
                  {view?.others?.length === 0 ? (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={'No File'}
                      margin="normal"
                      style={{ width: '50%' }}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Tax Documents
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="npwp"
                    label={
                      view?.npwp?.split('/')[
                        view?.npwp?.split('/').length - 1
                      ] || 'No File'
                    }
                    margin="normal"
                    value={view?.npwp}
                    style={{ width: '50%' }}
                    onClick={() => (window.location.href = view?.npwp)}
                    disabled={[undefined, '', null].includes(view?.npwp)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Proof Of Business Ownership
                  </Typography>
                  {/* </div> */}
                  <Chip
                    fullWidth
                    required
                    variant="outlined"
                    name="nib"
                    label={
                      view?.nib?.split('/')[view?.nib?.split('/').length - 1] ||
                      'No File'
                    }
                    margin="normal"
                    value={view?.nib}
                    onClick={() => (window.location.href = view?.nib)}
                    style={{ width: '50%' }}
                    disabled={[undefined, '', null].includes(view?.nib)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} item>
                  {/* <div className="section"> */}
                  <Typography
                    style={{
                      marginBottom: 20,
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {' '}
                    Other Documents
                  </Typography>
                  {/* </div> */}
                  {view?.others?.map((n) => (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={
                        n?.file?.split('/')[n?.file?.split('/').length - 1] ||
                        'No File'
                      }
                      margin="normal"
                      value={n?.file}
                      style={{ width: '50%' }}
                      onClick={() => (window.location.href = n?.file)}
                      disabled={[undefined, '', null, []].includes(n?.file)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ))}
                  {view?.others?.length === 0 ? (
                    <Chip
                      fullWidth
                      required
                      variant="outlined"
                      name="others"
                      label={'No File'}
                      margin="normal"
                      style={{ width: '50%' }}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </Board>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.module.view,
});

const FormikCompanyDetail = withFormik(
  CompanyDetail,
  null,
  mapPropsToValue,
  null
);

export default connect(mapStateToProps)(FormikCompanyDetail);
