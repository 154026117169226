import React from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ContainerHeader from 'components/BreadCrumbTitle';
import CompanyDetail from './CompanyDetail';
import CompanyProfile from './CompanyProfile';
import { getUser } from 'utils/localStorage';

const CustomTab = withStyles({
  root: {
    textTransform: 'capitalize',
    color: '#696969',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#ED901A',
  },
})(Tabs);

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div role="tabpanel">{value === index && <Box>{children}</Box>}</div>;
};

const LinkTab = (props) => {
  return <Tab component="div" {...props} />;
};

const NavTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <CustomTab value={value} onChange={handleChange}>
        <LinkTab label="Company Details" />
        <LinkTab label="Company Profile" />
      </CustomTab>
      <TabPanel value={value} index={0}>
        <CompanyDetail />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CompanyProfile />
      </TabPanel>
    </div>
  );
};

const View = () => {
  const breadcrumbAdmin = [
    { link: '/exhibitors', label: 'All Exhibitors' },
    { link: '', label: 'View Exhibitor' },
  ];
  const breadcrumbExhibitor = [{ link: '', label: 'My Company' }];
  const breadcrumb = getUser()?.user?.roles.includes('administrator')
    ? breadcrumbAdmin
    : getUser()?.user?.roles.includes('exhibitor')
    ? breadcrumbExhibitor
    : [];

  const title = getUser()?.user?.roles.includes('administrator')
    ? 'View Exhibitor'
    : 'My Company';

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader title={title} breadcrumb={breadcrumb} />
      {NavTabs()}
    </div>
  );
};

export default View;
