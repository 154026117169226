import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContainerHeader from 'components/BreadCrumbTitle';
import Board from 'components/Board';
import Gallery from 'components/Gallery';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from 'redux/actions';
// import { add, list as listRedux } from 'redux/actions/Module';
// import { getUser } from 'utils/localStorage';
import TextEditor from 'components/Form/TextEditor';

const ViewPlenarySessions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.module.view);
  const id = useParams().id;
  useEffect(() => {
    dispatch(
      load({
        name: 'events',
        id: id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const view = events?.data;

  const removeData = () => {
    dispatch(
      remove({
        name: 'events',
        id: id,
        noLink: true,
        onSuccess: (res) => {
          history.push(`/plenary-sessions`);
        },
      })
    );
  };
  // console.log(view);
  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="View Event"
        breadcrumb={[
          { link: '/plenary-sessions', label: ' Sessions' },
          { link: `/plenary-sessions/${id}`, label: 'View Event' },
        ]}
      />

      <Board
        actions={[
          {
            label: 'Edit Event',

            style: {
              background: '#ED901A',
              color: '#fff',
              border: '1px solid #ED901A',
              padding: '8px 17px',
            },
            onClick: () => history.push(`/plenary-sessions/${id}/edit`),
          },
          {
            label: 'Delete Event',
            confirm: true,
            noDisabled: true,
            confirmButton: {
              title: 'Delete',
              style: {
                background: '#E14040',
                color: '#fff',
                border: '1px solid #E14040',
              },
            },
            confirmTitle: 'Delete Event',
            confirmMessage:
              'This action can not be undone. Are you sure you want to delete the event?',
            style: {
              border: '1px solid #E45858',
              color: '#E45858',
              padding: '8px 17px',
            },
            onClick: () => removeData(),
          },
        ]}
      >
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={4} item>
              <Autocomplete
                name="status"
                style={{ marginTop: 15 }}
                options={[
                  { value: 'published', title: 'Published' },
                  { value: 'draft', title: 'Draft' },
                ]}
                value={{ value: view?.status, title: view?.status }}
                disabled
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    name="status"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Typography className="title">Event Information</Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="title"
                label="Title"
                margin="normal"
                value={view?.title}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextEditor
                name="eventDetail"
                label="Event Details"
                value={view?.description || ''}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                label="Excerpt"
                multiline
                row={5}
                variant="outlined"
                required
                fullWidth
                value={view?.excerpt}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="contact"
                label="Contact"
                margin="normal"
                value={view?.contact}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <FormControl
                variant="outlined"
                fullWidth
                size="medium"
                margin="normal"
              >
                <InputLabel>Category</InputLabel>
                <Select
                  name="category"
                  label="Category"
                  value={view?.category || ''}
                  // value="plenary"
                  disabled
                >
                  {[
                    { value: 'event', label: 'Event' },
                    { value: 'plenary', label: 'Plenary' },
                    { value: 'panel', label: 'Panel' },
                    { value: 'business_matching', label: 'Business Matching' }
                  ].map((value, index) => (
                    <MenuItem value={value.value}>{value.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="meetingLink"
                label="Meeting Link"
                margin="normal"
                value={view?.meetingLink}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="date"
                variant="outlined"
                name="eventDate"
                label="Event Date"
                margin="normal"
                value={view?.date}
                disabled
                inputProps={{
                  min: moment(new Date()).format('YYYY-MM-DD'),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="time"
                variant="outlined"
                name="startTime"
                label="Start Time"
                margin="normal"
                value={view?.startTime}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={6} item>
              <TextField
                fullWidth
                required
                type="time"
                variant="outlined"
                name="endTime"
                label="End Time"
                margin="normal"
                value={view?.endTime}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Gallery
                title="Thumbnail"
                images={[{ url: view?.thumbnailImage }]}
              />
            </Grid>
          </Grid>
        </div>
        <div className="section">
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <Gallery title="Banner" images={[{ url: view?.bannerImage }]} />
            </Grid>
          </Grid>
        </div>
        <Grid sm={6} item>
          {/* <div className="section"> */}
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 'bold',
              fontSize: 16,
            }}
          >
            {' '}
            Agenda
          </Typography>
          {/* </div> */}
          <Chip
            fullWidth
            required
            variant="outlined"
            name="suratIzinEkspor"
            label={
              view?.agenda?.split('/')[view?.agenda?.split('/').length - 1] ||
              'No File'
            }
            margin="normal"
            value={view?.agenda}
            onClick={() => (window.location.href = view?.agenda)}
            style={{ width: '50%' }}
            disabled={[undefined, '', null, []].includes(view?.agenda)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Board>
    </div>
  );
};

export default ViewPlenarySessions;
