import React, {
  useEffect,

  //  useState
} from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //  FormControl,
  // InputLabel,
  //  Select,
  //  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import UploadDocument from 'components/Upload/UploadDocument';
import { aboutExhibitor } from '../schemeValidation';
import withFormik from 'utils/withFormik';
import { edit } from 'redux/actions/Module';
import TextEditor from 'components/Form/TextEditor';

const initialValueForm = {
  id: '',
  about: '',
  video: '',
  file: '',
  founding: '',
};

const handleSubmitForm = (payload, ctx) => {
  const { id, about, video, file, founding } = payload;
  ctx.props.edit({
    name: `exhibitor/${id}`,
    data: {
      companyVideoEmbed: video,
      companyProfile: about,
      companyProfileFile: file,
      establishment: founding,
    },
    noLink: true,
    onSuccess: (res) => ctx.props.handleOpen(res),
  });
};

const EditCategory = ({ open, handleOpen, dataEdit, exhibitor, ...props }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  useEffect(() => {
    // eslint-disable-next-line
    if (dataEdit !== null) {
      setFieldValue('id', dataEdit?.slug);
      setFieldValue('video', dataEdit?.companyVideoEmbed || '');
      setFieldValue('file', dataEdit?.companyProfileFile || '');
      setFieldValue('about', dataEdit?.companyProfile || '');
      setFieldValue('founding', dataEdit?.establishment || '');
    }

    // eslint-disable-next-line
  }, dataEdit);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: '40px 40px 0 40px' }}>
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Edit Overview
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextEditor
              name="about"
              label="About Exhibitor"
              value={values.about}
              onChange={(val) =>
                setFieldValue('about', val === '<p><br></p>' ? '' : val)
              }
              onBlur={(e) => handleBlur({ target: { name: 'about' } })}
              error={Boolean(touched.about && errors.about)}
              helperText={touched.about && errors.about}
            />
            <TextField
              fullWidth
              required
              variant="outlined"
              name="founding"
              label="Year of Founding"
              margin="normal"
              value={values.founding}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.founding && errors.founding)}
              helperText={touched.founding && errors.founding}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextField
              fullWidth
              // required
              variant="outlined"
              name="video"
              label="Embed Code"
              margin="normal"
              value={values.video}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.video && errors.video)}
              helperText={touched.video && errors.video}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <UploadDocument
              label="File"
              name="file"
              value={values?.file}
              onImageChange={setFieldValue}
              error={errors.file}
              max={1}
              isEditCompanyProfile
              type="company_profile"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikEdit = withFormik(
  EditCategory,
  aboutExhibitor,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
  userRoles: state.module.view.userRoles,
});

export default connect(mapStateToProps, { edit })(FormikEdit);
