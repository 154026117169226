import * as Yup from 'yup';
const number = /^[0-9]*$/;

export const createBanner = Yup.object().shape({
  order: Yup.string()
    .matches(number, 'Order  can only contain numbers')
    .required('Select in which order this category will be shown.'),
  media: Yup.string().required('Select the media type you want to upload.'),
  image: Yup.string().when('media', {
    is: 'image',
    then: Yup.string().required('Image is required.'),
  }),
  mobile: Yup.string().when('media', {
    is: 'image',
    then: Yup.string().required('Image is required.'),
  }),
  file: Yup.string().when('media', {
    is: 'video',
    then: Yup.string().required('Video is required.'),
  }),
});
