import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Chip, Grid, Button } from '@material-ui/core';

import { BASE_URL } from 'utils/url';

Image.propTypes = {
  value: PropTypes.array,
  onClose: PropTypes.func,
};

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      antrian: [],
      maxSize: '',
    };
    this.fileInput = React.createRef();
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ maxSize: '' });
    const { files } = event.target;
    // const typeValidation =
    //   this.props.typeFile === 'application/pdf'
    //     ? Array.from(files)
    //         .map((n) => n.type !== this.props.typeFile)
    //         .includes(true)
    //     : event.target.files &&
    //       event.target.files[0].type !== 'image/jpeg' &&
    //       event.target.files &&
    //       event.target.files[0].type !== 'image/png';

    // if (typeValidation) {
    //   this.setState({
    //     maxSize: 'The document you uploaded is in the wrong format.',
    //   });
    // } else
    if (
      Array.from(files)
        .map((n) => n.size / 10024 > 500)
        .includes(true)
    ) {
      this.setState({
        maxSize: 'The document you uploaded exceeded the max. size of 5MB',
      });
    } else {
      this.handleUpload(files);

      // this.setState({ antrian: Array.from(files) }, () => this.handleRead());
    }
    // event.target.value = null;
  };

  handleRead = () => {
    const { antrian } = this.state;
    const reader = new FileReader();
    reader.onloadend = () => {
      this.handleUpload({
        // base64: reader.result,
        name: antrian[0].name,
        size: antrian[0].size,
        type: antrian[0].type,
      });
    };
    if (antrian[0]) {
      reader.readAsDataURL(antrian[0]);
    }
  };

  handleUpload = (data) => {
    this.setState({ loading: true });
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let fd = new FormData();
      fd.append('media', element);
      fd.append('type', this.props.type);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      axios
        .post(`${BASE_URL}/api/upload/media`, fd, config)
        .then((res) => {
          const { value, onImageChange, multiple, isEdit } = this.props;
          const { antrian } = this.state;
          this.setState({ antrian: antrian.slice(1), loading: false }, () => {
            if (!multiple) {
              // console.log('not multiple');
              // this.props.state(data.name);
              onImageChange(this.props.name, res.data.originalUrl);
            } else if (value) {
              // console.log(value);
              const obj = {};
              obj['file'] = res.data.originalUrl;
              isEdit
                ? onImageChange(this.props.name, [...value, obj])
                : onImageChange(this.props.name, [
                    ...value,
                    res.data.originalUrl,
                  ]);
            } else {
              // console.log('else');
              onImageChange([res.data]);
            }
          });
        })
        .catch((err) => {
          this.setState({ loading: false }, () => {});
        });
    }
  };

  handleClose = (id) => {
    const { value, onImageChange, name } = this.props;
    const newData = value.filter((n, nid) => nid !== id);
    onImageChange(name, newData);
  };

  render() {
    const {
      // title,
      name,
      error,
      label,
      value,
      multiple,
      required,
      onBlur,
      isEdit,
    } = this.props;
    const edit = isEdit || false;
    const { maxSize } = this.state;
    return (
      <div>
        {/* <div className="section"> */}
        <Typography
          style={{ marginBottom: 20, fontWeight: 'bold', fontSize: 16 }}
        >
          {label}
        </Typography>
        {/* </div> */}
        {edit ? (
          <div>
            {value?.map((n, idn) => (
              <Chip
                variant="outlined"
                label={n?.file
                  ?.split('/')
                  [n?.file?.split('/').length - 1].slice(0, 30)}
                // style={{ width: '50%' }}
                onClick={() => (window.location.href = n?.file)}
                onDelete={() => this.handleClose(idn)}
              />
            ))}
          </div>
        ) : (
          <div>
            {value?.map((n, idn) => (
              <Chip
                variant="outlined"
                label={n?.split('/')[n?.split('/').length - 1].slice(0, 30)}
                // style={{ width: '50%' }}
                onClick={() => (window.location.href = n)}
                onDelete={() => this.handleClose(idn)}
              />
            ))}
          </div>
        )}

        {value?.length !== 0 ? null : (
          <Chip variant="outlined" label={'No File'} style={{ width: '50%' }} />
        )}

        <Grid container>
          <Grid sm={2}>
            <div style={{ marginTop: 20 }}>Edit File</div>
          </Grid>
          <Grid>
            <div>
              <label htmlFor={name || 'image'}>
                <Button
                  onClick={() => this.fileInput.current.click()}
                  style={{
                    background: '#ED901A',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                    marginTop: 13,
                    width: 159,
                    height: 40,
                  }}
                >
                  Select File
                </Button>
              </label>
              <input
                ref={this.fileInput}
                accept="application/pdf,image/jpg,image/jpeg,image/png"
                id={name || 'image'}
                type="file"
                name={name || 'image'}
                onChange={this.handleChange}
                multiple={multiple}
                required={required}
                onBlur={onBlur}
                style={{
                  display: 'none',
                }}
              />
            </div>
          </Grid>
        </Grid>

        {error && (
          <Typography
            color="error"
            style={{ marginBottom: 16, fontSize: '0.75rem' }}
          >
            {error}
          </Typography>
        )}
        {!maxSize && <div>{'Files: .pdf .png .jpg (Max size 5MB)'}</div>}
        {maxSize && (
          <Typography color="error" style={{ fontSize: '0.75rem' }}>
            {maxSize}
          </Typography>
        )}
      </div>
    );
  }
}

UploadImage.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  notif: PropTypes.func,
  error: PropTypes.string,
  onImageChange: PropTypes.func,
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(UploadImage);
