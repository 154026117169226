import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { edit, remove } from 'redux/actions/Module';
import Confirm from 'components/Board/Confirm';
import { connect, useDispatch } from 'react-redux';
import schemeValidations from './schemeValidations';
import withFormik from 'utils/withFormik';

const initialValueForm = {
  idEdit: '',
  id: '',
  region: '',
  status: '',
  order: '',
};
const handleSubmit = (payload, ctx) => {
  const { id, region, status, idEdit, order } = payload;
  ctx.props.edit({
    name: 'region',
    id: idEdit,
    data: {
      title: region,
      name: id,
      status,
      order,
    },
    noLink: true,
    onSuccess: (res) => {
      ctx.props.handleOpen(false);
    },
  });
};
const Edit = ({ open, handleOpen, dataEdit, ...props }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    // data,
  } = props;
  const dispatch = useDispatch();
  const [draftModal, setDraftModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  useEffect(() => {
    setFieldValue('idEdit', dataEdit?.id);

    setFieldValue('id', dataEdit?.name);
    setFieldValue('region', dataEdit?.title);
    setFieldValue('status', dataEdit?.status);
    setFieldValue('isUsed', dataEdit?.isUsed);
    setFieldValue('order', dataEdit?.order || '');

    // eslint-disable-next-line
  }, [dataEdit]);
  const handleChangeStatus = (event) => {
    setFieldValue('status', event.target.value);
  };

  const removeData = () => {
    dispatch(
      remove({
        name: 'region',
        id: values.idEdit,
        noLink: true,
        onSuccess: (res) => handleOpen(res),
      })
    );
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Edit Region
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="id"
                label="ID"
                margin="normal"
                value={values.id}
                error={Boolean(touched.id && errors.id)}
                helperText={touched.id && errors.id}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="region"
                label="Region"
                margin="normal"
                value={values.region}
                error={Boolean(touched.region && errors.region)}
                helperText={touched.region && errors.region}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                variant="outlined"
                name="order"
                label="Order"
                margin="normal"
                value={values.order}
                error={Boolean(touched.order && errors.order)}
                helperText={touched.order && errors.order}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  onChange={handleChangeStatus}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                  onBlur={handleBlur}
                  name="status"
                  label="Status"
                >
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setDeleteModal(true)}
          // onClick={() => removeData()}
          style={{
            background: '#E14040',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() =>
            values.status === 'inactive' && values.isUsed
              ? setDraftModal(true)
              : handleSubmit()
          }
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Save
        </Button>
      </DialogActions>
      <Confirm
        open={draftModal}
        handleClose={() => setDraftModal(false)}
        handleAction={(e) => {}}
        handleContent={{
          button: {
            title: 'Save',
            style: {
              background: '#1AB759',
              border: '1px solid #1AB759',
              color: '#fff',
              width: 100,
              display: 'none',
            },
          },
          title: 'Draft Region',
          message:
            'Region can not be drafted when there are countries associated under it. Please remove all countries from this sector before drafting it.',
        }}
      />
      <Confirm
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleAction={
          !values.isUsed
            ? (e) => {
                setDeleteModal(false);
                removeData();
              }
            : null
        }
        handleContent={{
          button: {
            title: 'Delete',
            style: {
              background: !values.isUsed ? '#E45858' : '#BFBFBF',
              color: '#fff',
              border: !values.isUsed
                ? '1px solid #E45858'
                : '1px solid #BFBFBF',
              cursor: !values.isUsed ? 'pointer' : 'default',
            },
          },
          title: 'Delete Region',
          message: !values.isUsed
            ? 'This action can not be undone. Are you sure you want to delete the region?'
            : 'Region can not be deleted when there are countries associated under it. Please remove all countries from this region before deleting it.',
        }}
      />
    </Dialog>
  );
};

const EditFormik = withFormik(
  Edit,
  schemeValidations,
  initialValueForm,
  handleSubmit
);

export default connect(null, { edit, remove })(EditFormik);
