import React from 'react';
import { getUser } from 'utils/localStorage';
import ProductAdmin from './ProductListAdmin';
import ProductExhibitor from './ProductListExhibitor';

const Index = () => {
  if (getUser()?.user?.roles.includes('administrator')) {
    return <ProductAdmin />;
  } else if (getUser()?.user?.roles.includes('exhibitor')) {
    return <ProductExhibitor />;
  }
};

export default Index;
