import React, { useState } from 'react';
import ContainerHeader from 'components/BreadCrumbTitle';
import moment from 'moment';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';
import Create from './Create';
import Edit from './Edit';

const Index = () => {
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [reload, setIsReload] = useState(false);

  const update = (value) => {
    setIsOpenEdit(true);
    setDataEdit(value);
  };

  const closeCreate = () => {
    setIsOpenCreate(false);
    setIsReload(!reload);
  };
  const closeEdit = () => {
    setIsOpenEdit(false);
    setIsReload(!reload);
  };
  return (
    <div className="app-wrapper region-page">
      <ContainerHeader
        title="Regions"
        breadcrumb={[{ link: '/regions', label: 'Regions' }]}
      />

      <div className="action-btn">
        <Button
          style={{
            background: '#ED901A',
            padding: '8px 24px',
            borderRadius: 8,
            color: '#ffffff',
            fontSize: 14,
            textTransform: 'capitalize',
            marginLeft: 16,
          }}
          onClick={() => setIsOpenCreate(true)}
        >
          Add Region
        </Button>
      </div>

      <Tables
        isSinglePage
        reload={reload}
        title="All Regions"
        name="region"
        withSearch={{
          placeholder: 'Search for a region...',
        }}
        columnHead={[
          { id: 'id', canFilter: false, label: 'ID' },
          { id: 'region', canFilter: false, label: 'Region' },
          { id: 'created_at', canFilter: false, label: 'Created At' },
          { id: 'status', canFilter: false, label: 'Status' },
          { id: '', canFilter: false, label: 'Action' },
        ]}
        columnBody={[
          { parent: 'name' },
          { parent: 'title' },
          {
            parent: (n) =>
              `${n.createdAt && moment(n.createdAt).format('DD MMM YYYY')}`,
          },
          { parent: 'status' },
          {
            parent: (n) => (
              <Button
                onClick={() => update(n)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                  fontSize: 12,
                }}
              >
                Edit
              </Button>
            ),
          },
        ]}
      />

      <Create open={isOpenCreate} handleOpen={(val) => closeCreate(val)} />

      <Edit
        open={isOpenEdit}
        handleOpen={(val) => closeEdit(val)}
        dataEdit={dataEdit}
      />
    </div>
  );
};

export default Index;
