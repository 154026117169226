import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import UploadDocument from 'components/Upload/UploadDocument';

import { connect } from 'react-redux';
import { Upload as UploadSchemeValidation } from './schemeValidation';
import withFormik from 'utils/withFormik';
import { add, edit } from 'redux/actions/Module';
// import { getUser } from 'utils/localStorage';

const initialValueForm = {
  file: '',
  title: '',
  //   slug: '',
  //   logo: '',
  //   background: '',
};

const handleSubmitForm = (payload, ctx) => {
  const { file, title } = payload;
  if (ctx?.props?.dataEdit?.isEdit) {
    ctx.props.edit({
      name: `media/${ctx?.props?.dataEdit?.id}`,
      data: {
        url: file,
        title,
      },
      noLink: true,
      onSuccess: (res) => ctx.props.handleOpen(res),
    });
  } else {
    ctx.props.add({
      name: `media`,
      data: {
        url: file,
        title,
      },
      noLink: true,
      onSuccess: (res) => ctx.props.handleOpen(res),
    });
  }
};

const UploadLogo = ({ open, handleOpen, dataEdit, ...props }) => {
  const {
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    values,
    errors,
    touched,
    setFieldError,
  } = props;
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: '40px 40px 0 40px' }}>
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Upload PDF, MS Word, JPG/JPEG, PNG
        </Typography>
        <UploadDocument
          isMedia
          name="file"
          value={values.file}
          title="title"
          onImageChange={setFieldValue}
          error={touched.file && errors.file}
          type="media"
          maxSize={10000}
          setFieldError={setFieldError}
        />
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Max file size is 100mb
        </Typography>
        <Typography fontStyle="italic" style={{ fontSize: 10 }}>
          Accepted file format: .pdf, .doc, docx, .jpg, .jpeg, .png
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikCreate = withFormik(
  UploadLogo,
  UploadSchemeValidation,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
  userRoles: state.module.view.userRoles,
});

export default connect(mapStateToProps, { add, edit })(FormikCreate);
