import React, { Fragment, useState } from 'react';
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import {
  Close,
  FilterList,
  Tune,
  Search as SearchIcon,
} from '@material-ui/icons';

const TableToolbar = ({
  numSelected,
  title,
  actions,
  withFilter,
  withFilterModal,
  withSearch,
  isFilter,
  selected,
  removeSelected,
  changeIsFilter,
  searchKeyword,
}) => {
  const [keyword, setKeyword] = useState('');
  const menuNotSelected = actions && actions.filter((n) => !n.selected);
  const menuSelected = actions && actions.filter((n) => n.selected);
  return (
    <Toolbar
      className={`table-toolbar-custom ${numSelected > 0 && 'highlight'}`}
    >
      <div className="wrap-toolbar">
        <div className="title">
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {title || ''}
            </Typography>
          )}
        </div>

        <div className="actions-toolbar">
          {numSelected > 0 ? (
            <Fragment>
              {Boolean(menuSelected && menuSelected.length) &&
                menuSelected.map((n, nid) => (
                  <Tooltip title={n.label} key={nid}>
                    <div>
                      <IconButton
                        onClick={() => {
                          n.action(selected);
                          removeSelected();
                        }}
                      >
                        {n.icon}
                      </IconButton>
                    </div>
                  </Tooltip>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              {Boolean(menuNotSelected && menuNotSelected.length) &&
                menuNotSelected.map((n, nid) => (
                  <button key={nid} className="action-btn">
                    {n.label}
                  </button>
                ))}
            </Fragment>
          )}

          {withFilterModal && (
            <Tooltip title="Filter">
              <div>
                <IconButton onClick={() => withFilterModal(true)}>
                  <Tune />
                </IconButton>
              </div>
            </Tooltip>
          )}

          {withFilter &&
            (isFilter ? (
              <Tooltip title="Close Filter">
                <div>
                  <IconButton onClick={() => changeIsFilter(false)}>
                    <Close />
                  </IconButton>
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Filter">
                <div>
                  <IconButton onClick={() => changeIsFilter(true)}>
                    <FilterList />
                  </IconButton>
                </div>
              </Tooltip>
            ))}

          {withSearch && (
            <TextField
              className="search"
              fullWidth
              variant="outlined"
              size="small"
              onChange={(val) => setKeyword(val.target.value)}
              onKeyDown={(e) => {
                e.keyCode === 13 && searchKeyword(keyword);
              }}
              placeholder={withSearch.placeholder || 'Search...'}
              InputProps={{
                endAdornment: (
                  <InputAdornment onClick={() => searchKeyword(keyword)}>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>
      </div>
    </Toolbar>
  );
};

export default TableToolbar;
