import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { categorySchemeValidations } from '../schemeValidation';
import withFormik from 'utils/withFormik';
import { add } from 'redux/actions/Module';
import { getUser } from 'utils/localStorage';

const initialValueForm = {
  slug: '',
  name: '',
  order: 1,
  status: 'active',
};

const handleSubmitForm = (payload, ctx) => {
  const { name, order, status, slug } = payload;
  ctx.props.add({
    name: `exhibitor/${slug}/category`,
    data: {
      name,
      order,
      status,
      on: 'product',
    },
    notLink: true,
    onSuccess: (res) => ctx.props.handleOpen(res),
  });
};

const CreateCategory = ({ open, handleOpen, ...props }) => {
  const slug = useParams().exhibitorId;
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  const exhibitorId = getUser()?.user?.exhibitor?.slug
    ? getUser()?.user?.exhibitor?.slug
    : slug;

  useEffect(() => {
    // eslint-disable-next-line
    setFieldValue('slug', exhibitorId);
    // eslint-disable-next-line
  }, [exhibitorId]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: '40px 40px 0 40px' }}>
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: 500,
            fontSize: 16,
            color: '#282828',
          }}
        >
          Add Category
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              name="name"
              label="Category Name"
              margin="normal"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextField
              fullWidth
              required
              type="number"
              variant="outlined"
              placeholder="1"
              name="order"
              label="Order"
              margin="normal"
              value={values.order}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.order && errors.order)}
              helperText={touched.order && errors.order}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <FormControl
              variant="outlined"
              fullWidth
              size="medium"
              margin="normal"
            >
              <InputLabel>Status</InputLabel>
              <Select
                value={values.status}
                name="status"
                label="Status"
                onChange={(event) =>
                  setFieldValue('status', event.target.value)
                }
                onBlur={handleBlur}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="deactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 40 }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!(dirty && isValid)}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
            width: 100,
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikCreate = withFormik(
  CreateCategory,
  categorySchemeValidations,
  initialValueForm,
  handleSubmitForm
);

const mapStateToProps = (state) => ({
  data: state.module.list,
  userRoles: state.module.view.userRoles,
});

export default connect(mapStateToProps, { add })(FormikCreate);
