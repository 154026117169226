import * as Yup from 'yup';

export const createSchemeValidations = () => Yup.object().shape({
  firstName: Yup.string()
    .required('First Name cannot be blank'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email cannot be blank'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password cannot be blank'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Verify Password cannot be blank'),
  roles: Yup.number()
    .required('Roles are required'),
  userCategory: Yup.string()
    .required('User Category are required'),
});

export const editSchemeValidations = () => Yup.object().shape({
  firstName: Yup.string()
    .required('First Name cannot be blank'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email cannot be blank'),
  roles: Yup.number()
    .required('Roles are required'),
  userCategory: Yup.string()
    .required('User Category are required'),
});