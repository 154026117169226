import Immutable from 'seamless-immutable';

// Name spacing with prefix `module/`
const ADD = 'module/ADD';
const ADD_SUCCESS = 'module/ADD_SUCCESS';
const APPROVE = 'module/APPROVE';
const APPROVE_SUCCESS = 'module/APPROVE_SUCCESS';
const BULK_APPROVE = 'module/BULK_APPROVE';
const BULK_APPROVE_SUCCESS = 'module/BULK_APPROVE_SUCCESS';
const LOAD = 'module/LOAD';
const LOAD_SUCCESS = 'module/LOAD_SUCCESS';
const EDIT = 'module/EDIT';
const EDIT_SUCCESS = 'module/EDIT_SUCCESS';
const REMOVE = 'module/REMOVE';
const REMOVE_SUCCESS = 'module/REMOVE_SUCCESS';
const BULK_REMOVE = 'module/BULK_REMOVE';
const BULK_REMOVE_SUCCESS = 'module/BULK_REMOVE_SUCCESS';
const LIST = 'module/LIST';
const LIST_SUCCESS = 'module/LIST_SUCCESS';
const TABLE = 'module/TABLE';
const EXPORT_EXCEL = 'module/EXPORT_EXCEL';
const IMPORT_EXCEL = 'module/IMPORT_EXCEL';
const IMPORT_EXCEL_SUCCESS = 'module/IMPORT_EXCEL_SUCCESS';
const SEND_EMAIL = 'module/SEND_EMAIL';

// Craete Immutable object
const actionTypes = Immutable({
  ADD,
  ADD_SUCCESS,
  APPROVE,
  APPROVE_SUCCESS,
  BULK_APPROVE,
  BULK_APPROVE_SUCCESS,
  LOAD,
  LOAD_SUCCESS,
  EDIT,
  EDIT_SUCCESS,
  REMOVE,
  REMOVE_SUCCESS,
  BULK_REMOVE,
  BULK_REMOVE_SUCCESS,
  LIST,
  LIST_SUCCESS,
  TABLE,
  EXPORT_EXCEL,
  IMPORT_EXCEL,
  IMPORT_EXCEL_SUCCESS,
  SEND_EMAIL,
});

export default actionTypes;

export function add(param) {
  return {
    type: actionTypes.ADD,
    ...param,
  };
}

export function addSuccess(data) {
  return {
    type: actionTypes.ADD_SUCCESS,
    data,
  };
}

export function load(param) {
  return {
    type: actionTypes.LOAD,
    ...param,
  };
}

export function loadSuccess(view) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    view,
  };
}

export function edit(param) {
  return {
    type: actionTypes.EDIT,
    ...param,
  };
}

export function editSuccess(data) {
  return {
    type: actionTypes.EDIT_SUCCESS,
    data,
  };
}

export function remove(param) {
  return {
    type: actionTypes.REMOVE,
    ...param,
  };
}

export function removeSuccess(data) {
  return {
    type: actionTypes.REMOVE_SUCCESS,
    data,
  };
}

export function bulkRemove(param) {
  return {
    type: actionTypes.BULK_REMOVE,
    ...param,
  };
}

export function bulkRemoveSuccess(data) {
  return {
    type: actionTypes.BULK_REMOVE_SUCCESS,
    data,
  };
}

export function approve(param) {
  return {
    type: actionTypes.APPROVE,
    ...param,
  };
}

export function approveSuccess(data) {
  return {
    type: actionTypes.APPROVE_SUCCESS,
    data,
  };
}

export function bulkApprove(param) {
  return {
    type: actionTypes.BULK_APPROVE,
    ...param,
  };
}

export function bulkApproveSuccess(data) {
  return {
    type: actionTypes.BULK_APPROVE_SUCCESS,
    data,
  };
}

export function list(param) {
  return {
    type: actionTypes.LIST,
    ...param,
  };
}

export function listSuccess(data) {
  return {
    type: actionTypes.LIST_SUCCESS,
    list: data,
  };
}

export function table(param) {
  return {
    type: actionTypes.TABLE,
    param,
  };
}

export function exportExcel(param) {
  return {
    type: actionTypes.EXPORT_EXCEL,
    ...param,
  };
}

export function importExcel({ param, file }) {
  return {
    type: actionTypes.IMPORT_EXCEL,
    file,
    ...param,
  };
}

export function importExcelSucess(data) {
  return {
    type: actionTypes.IMPORT_EXCEL_SUCCESS,
    data,
  };
}

export function sendEmail(param) {
  return {
    type: actionTypes.SEND_EMAIL,
    ...param,
  };
}
