import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import { Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'redux/actions';
import Header from './components/Header';
import Footer from './components/Footer';
import { ResetSchemeValidations } from './schemeValidations';
import withFormik from 'utils/withFormik';
import history from 'utils/history';

const initialValueForm = {
  password: '',
  passwordConfirmation: '',
};

const ResetPassword = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useSelector((state) => state.oauth);
  const dispatch = useDispatch();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    // handleSubmit,
    isValid,
    // dirty,
    // setFieldValue,
    // data,
  } = props;

  const handleSubmit = () => {
    const { token } = props.match.params;
    // console.log(token)
    if (isValid) {
      dispatch(
        resetPassword({
          token,
          data: {
            password: values.password,
          },
        })
      );
    }
    // setIsSuccess(true);
  };

  useEffect(() => {
    if (user && user.message === 'OK') {
      setIsSuccess(true);
    }
  }, [user]);

  const onSuccess = () => {
    history.push('/signin');
  };

  return (
    <div className="app-wrapper auth-page">
      <Header />
      {/* <img className="bg-item-left" src={BgItemLeft} alt="bg-item" /> */}
      {/* <img className="bg-item-right" src={BgItemRight} alt="bg-item" /> */}

      <Container>
        <Row>
          <Col lg={6} md={8} className="offset-lg-3 offset-md-2">
            {!isSuccess ? (
              <div className="card">
                <h3 className="title">Enter your new password</h3>
                <TextField
                  label="Password"
                  type="password"
                  margin="normal"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  onBlur={handleBlur}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  margin="normal"
                  name="passwordConfirmation"
                  fullWidth
                  onChange={handleChange}
                  value={values.passwordConfirmation}
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.passwordConfirmation && errors.passwordConfirmation
                  )}
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
                <div className="btn-wrap">
                  <Button onClick={handleSubmit} className="button">
                    Set Password
                  </Button>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="title-desc">
                  <h3>Your password has been updated</h3>
                  <p>
                    Your new passowrd has been set. Please login back to
                    INA-ACCESS with your new credentials.
                  </p>
                </div>
                <div className="btn-wrap">
                  <button onClick={onSuccess} className="button-back">
                    Back to Homepage
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

const FormikReset = withFormik(
  ResetPassword,
  ResetSchemeValidations,
  initialValueForm,
  null
);

export default FormikReset;
