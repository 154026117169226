import Immutable from 'seamless-immutable';
import actionTypes from '../actions/Module';

const INIT_STATE = Immutable({
  view: {},
  list: {},
  listParams: {},
  table: {
    name: '',
    page: 0,
    pageSize: 10,
    selected: [],
    orderBy: '',
    order: 'asc'
  }
})

const modules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD:
      return state.merge({
        view: state.view.merge({ [action.customName ? action.customName : action.name]: null })
      })

    case actionTypes.LOAD_SUCCESS:
      return state.merge({
        view: state.view.merge({ [action.view.name]: action.view.res })
      })

    case actionTypes.LIST:
      return state.merge({
        listParams: state.listParams.merge({ [action.name]: { query: action.query } })
      })

    case actionTypes.LIST_SUCCESS:
      return state.merge({
        list: state.list.merge({ [action.list.name]: action.list.res })
      })

    case actionTypes.TABLE:
      return state.merge({
        table: action.params
      })

    default:
      return state
  }
}

export default modules;
