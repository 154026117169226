import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notif } from 'redux/actions/Global';
import { remove } from 'redux/actions/Module';
import Confirm from 'components/Board/Confirm';

import ContainerHeader from 'components/BreadCrumbTitle';
import Upload from './Upload';
import { Button } from '@material-ui/core';

import Tables from 'components/Tables';
import UploadEdit from './UploadEdit';

const Media = () => {
  const dispatch = useDispatch();
  const [upload, setUploadMedia] = useState(false);
  const [editMedia, setEditMedia] = useState(false);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState({});
  const [deleteMedia, setDeleteMedia] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const handleCloseUpload = () => {
    setUploadMedia(false);
    setReload(!reload);
  };

  const handleEdit = (dataEdit) => {
    const newDataEdit = {
      isEdit: true,
      id: dataEdit?.id,
      file: dataEdit?.url,
    };
    setData(newDataEdit);
    setEditMedia(true);
  };

  const handleCloseEdit = () => {
    setEditMedia(false);
    setReload(!reload);
  };

  const handleDelete = () => {
    dispatch(
      remove({
        name: 'media',
        id: idDelete,
        noLink: true,
        onSuccess: () => setReload(!reload),
      })
    );
  };

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="Media"
        breadcrumb={[{ link: '/media', label: 'Media' }]}
      />
      <Confirm
        open={deleteMedia}
        handleClose={() => setDeleteMedia(false)}
        handleAction={() => {
          setDeleteMedia(false);
          handleDelete();
        }}
        handleContent={{
          button: {
            title: 'Delete',
            style: {
              background: '#E14040',
              color: '#fff',
              border: '1px solid #E14040',
            },
          },
          title: 'Delete Media',
          message:
            'This action can not be undone. Are you sure you want to delete the media?',
        }}
      />
      <div className="action-btn">
        <Button
          style={{
            border: '1px solid #ED901A',
            borderRadius: 8,
            color: '#FFF',
            backgroundColor: '#ED901A',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => setUploadMedia(true)}
        >
          Add Media
        </Button>
      </div>
      <Tables
        isSinglePage
        reload={reload}
        title="Media"
        name="media"
        viewDetail={{ name: 'media', by: 'id' }}
        // withSearch={{ placeholder: 'Search for a media...' }}
        columnHead={[
          { id: 'id', label: 'No', disableSort: false },
          { id: 'title', label: 'Title' },

          { id: '', label: 'Action' },
        ]}
        columnBody={[
          { parent: 'number' },
          {
            parent: (n) =>
              n?.title.length >= 38 ? `${n?.title.slice(0, 38)}...` : n?.title,
          },
          {
            parent: (n) => (
              <div>
                <Button
                  onClick={() => (window.location.href = n?.url)}
                  style={{
                    background: '#0A5379',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                    marginLeft: 10,
                  }}
                >
                  View
                </Button>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(n?.url);
                    dispatch(
                      notif({
                        open: true,
                        variant: 'success',
                        message: 'Link has been copied!',
                      })
                    );
                  }}
                  style={{
                    background: '#ED901A',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                    marginLeft: 10,
                  }}
                >
                  Copy URL
                </Button>
                <Button
                  onClick={() => handleEdit(n)}
                  style={{
                    background: '#3C91D6',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                    marginLeft: 10,
                  }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setDeleteMedia(true);
                    setIdDelete(n.id);
                  }}
                  style={{
                    background: '#E14040',
                    borderRadius: 6,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 12,
                    marginLeft: 10,
                  }}
                >
                  Delete
                </Button>
              </div>
            ),
          },
        ]}
      />
      {upload && <Upload open={upload} handleOpen={handleCloseUpload} />}
      {editMedia && (
        <UploadEdit
          open={editMedia}
          dataEdit={data}
          handleOpen={handleCloseEdit}
        />
      )}
    </div>
  );
};

export default Media;
