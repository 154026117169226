import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ContainerHeader from 'components/BreadCrumbTitle';
import Tables from 'components/Tables';
import { Button } from '@material-ui/core';
import moment from 'moment';
import ExportModal from 'components/ExportModal';

const Index = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isOpenExport, setIsOpenExport] = useState(false);

  return (
    <div className="app-wrapper exhibitor-page">
      <ContainerHeader
        title="View Event Participants"
        breadcrumb={[
          { link: `/participants/`, label: 'Participants' },

          { link: `/participants/${id}`, label: 'View Event Participant' },
        ]}
      />

      <div className="action-btn">
        <Button
          style={{
            border: '1px solid #ED901A',
            borderRadius: 8,
            color: '#ED901A',
            fontSize: 14,
            padding: '8px 24px',
            textTransform: 'capitalize',
          }}
          onClick={() => setIsOpenExport(true)}
        >
          Export
        </Button>
      </div>

      <Tables
        title="Participants"
        name={`participant`}
        addQueryString={`eventId=${id}`}
        minWidth={1200}
        viewDetail={{ name: 'banners', by: 'slug' }}
        columnHead={[
          { id: 'id', label: 'No', disableSort: false },
          { id: 'name', label: 'Full Name' },
          { id: 'created_at', label: 'Created At' },
          { id: '', label: 'Action' },
        ]}
        // disableFooter
        columnBody={[
          { parent: 'number' },
          { parent: 'fullName' },
          { parent: (n) => moment(n.createdAt).format('DD MMM YYYY') },

          // { parent: 'page' },
          {
            parent: (n) => (
              <Button
                onClick={() => history.push(`/participants/view/${n.id}`)}
                style={{
                  background: '#3C91D6',
                  borderRadius: 6,
                  color: '#fff',
                  textTransform: 'capitalize',
                  fontSize: 12,
                }}
              >
                View Details
              </Button>
            ),
          },
        ]}
      />

      {isOpenExport && (
        <ExportModal
          customName="participants"
          filter={{ eventId: id }}
          name="participant"
          open={isOpenExport}
          handleOpen={(val) => setIsOpenExport(val)}
        />
      )}
    </div>
  );
};

export default Index;
