import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
// import { DatePicker } from '@material-ui/pickers';
import { BASE_URL } from 'utils/url';
import withFormik from 'utils/withFormik';
import * as Yup from 'yup';
import { getUser } from 'utils/localStorage';
import to_qs from 'utils/queryString';
import axios from 'axios';
import FileSaver from 'file-saver';

export const schemeValidations = Yup.object().shape({
  startDate: Yup.string().required('Start Date can not be left blank.'),
  endDate: Yup.string().required('End Date can not be left blank'),
  count: Yup.string().required('Count can not be left blank'),
  format: Yup.string().required('Format can not be left blank'),
});

const initialValue = {
  startDate: moment().format('DD MMMM YYYY'),
  endDate: moment().format('DD MMMM YYYY'),
  count: '',
  format: 'excel',
  filter: {},
  keyword: '',
};

const handleSubmitForm = (payload, ctx) => {
  const { startDate, endDate, count, format, filter, keyword } = payload;
  const param = {
    ...filter,
    start_date: startDate,
    end_date: endDate,
    limit: count,
    keyword,
  };
  const query = to_qs(param);
  const token = getUser()?.token;
  axios({
    method: 'get',
    responseType: 'blob',
    url: `${BASE_URL}/api/${ctx.props.name}/export/${format}?${query}`,
    headers: { Authorization: `Token ${token}` },
  }).then((res) => {
    if (format === 'excel') {
      FileSaver.saveAs(
        res.data,
        `${ctx.props.customName || ctx.props.name}-${moment().format(
          'DD-MM-YYYY HH:mm'
        )}.xlsx`
      );
    }
    ctx.props.handleOpen(false);
  });
};

const ExportModal = ({ filter, keyword, open, handleOpen, ...props }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = props;

  useEffect(() => {
    if (filter) setFieldValue('filter', filter);
    if (keyword) setFieldValue('keyword', keyword);
    // eslint-disable-next-line
  }, [filter, keyword]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent style={{ padding: 40 }}>
        <div>
          <Typography
            style={{
              marginBottom: 20,
              fontWeight: 500,
              fontSize: 16,
              color: '#282828',
            }}
          >
            Export
          </Typography>
          <Grid container spacing={3}>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                type="date"
                variant="outlined"
                label="Start Date"
                name="startDate"
                margin="normal"
                value={values.startDate}
                error={Boolean(touched.startDate && errors.startDate)}
                helperText={touched.startDate && errors.startDate}
                onBlur={handleBlur}
                onChange={handleChange}
                // inputProps={{
                //   min: moment(new Date()).format('YYYY-MM-DD'),
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <DatePicker
                label="Start Date"
                name="startDate"
                showTodayButton
                inputFormat="DD MMMM YYYY"
                animateYearScrolling={false}
                format="DD MMMM YYYY"
                value={values.startDate}
                onChange={(val) => setFieldValue('startDate', val)}
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText={touched.startDate && errors.startDate}
                    error={Boolean(touched.startDate && errors.startDate)}
                    onBlur={handleBlur}
                  />
                )}
              /> */}
            </Grid>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                type="date"
                variant="outlined"
                label="End Date"
                name="endDate"
                margin="normal"
                value={values.endDate}
                error={Boolean(touched.endDate && errors.endDate)}
                helperText={touched.endDate && errors.endDate}
                onBlur={handleBlur}
                onChange={handleChange}
                // inputProps={{
                //   min: moment(new Date()).format('YYYY-MM-DD'),
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <DatePicker
                label="End Date"
                name="endDate"
                showTodayButton
                inputFormat="DD MMMM YYYY"
                animateYearScrolling={false}
                format="DD MMMM YYYY"
                value={values.endDate}
                onChange={(val) => setFieldValue('endDate', val)}
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText={touched.endDate && errors.endDate}
                    error={Boolean(touched.endDate && errors.endDate)}
                    onBlur={handleBlur}
                  />
                )}
              /> */}
            </Grid>
            <Grid sm={12} item>
              <TextField
                fullWidth
                required
                type="number"
                variant="outlined"
                name="count"
                label="Count"
                margin="normal"
                placeholder="1"
                value={values.count}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.count && errors.count)}
                helperText={touched.count && errors.count}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/* <Grid item sm={12}>
              <FormControl variant="outlined" fullWidth size="medium">
                <InputLabel>Format</InputLabel>
                <Select
                  value={values.format}
                  name="format"
                  label="Format"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.format && errors.format)}
                  helperText={touched.format && errors.format}
                >
                  <MenuItem value="excel">Excel</MenuItem>
                  <MenuItem value="pdf">Pdf</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px' }}>
        <Button
          onClick={() => handleOpen(false)}
          style={{
            border: '1px solid #E45858',
            borderRadius: 8,
            fontSize: 14,
            color: '#E45858',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!(dirty && isValid)}
          onClick={handleSubmit}
          style={{
            background: '#1AB759',
            borderRadius: 8,
            fontSize: 14,
            color: '#ffffff',
            textTransform: 'capitalize',
            padding: '11px 26px',
          }}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikExport = withFormik(
  ExportModal,
  schemeValidations,
  initialValue,
  handleSubmitForm
);

export default FormikExport;
