import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import EmptyImg from 'assets/images/upload.svg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Gallery = ({ title, images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <div className="gallery-component">
      <Typography className="title">{title}</Typography>
      <div className="container-images">
        {images?.length &&
          images.map((n, i) => (
            <div
              key={i}
              className="wrap-img cursor-pointer"
              onClick={() => {
                setIsOpen(true);
                setPhotoIndex(i);
              }}
            >
              {n.url ? (
                <img className="img" src={n.url} alt="gallery" />
              ) : (
                <img className="empty-img" src={EmptyImg} alt="gallery" />
              )}
            </div>
          ))}
      </div>

      {isOpen && images?.length && (
        <Lightbox
          mainSrc={images[photoIndex].url}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default Gallery;
